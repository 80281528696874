import { IconToggle } from '@/Components/IconToggle';
import { ButtonColors } from '@/Components/v2/Button';
import { Eye, LucideProps } from 'lucide-react';
import { ValueWithGrowth } from './ValueWIthGrowth';

type Props = {
  name: string;
  value: string;
  color: ButtonColors;
  hideGrowth?: boolean;
  growth: {
    growth: number | null;
    difference: number;
  };
  checked: boolean;
  icon: React.ComponentType<LucideProps>;
  onChange: (value: boolean) => void;
};

export const StatItem = ({
  name,
  value,
  hideGrowth,
  color,
  growth,
  checked,
  onChange,
  icon,
}: Props) => {
  return (
    <div className="flex gap-3">
      <div>
        <IconToggle
          icon={icon}
          color={color}
          value={checked}
          dense
          onClick={() => onChange(!checked)}
        />
      </div>
      <div className="flex flex-col">
        <div
          className={`-ml-1 mt-[1px] text-2xl font-bold leading-5 text-primary`}
        >
          <ValueWithGrowth
            responsive={false}
            growth={growth}
            value={value}
            hideGrowth={hideGrowth}
          />
        </div>
        <div className="text-sm text-primary">{name}</div>
      </div>
    </div>
  );
};
