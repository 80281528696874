import * as React from 'react';
import { IconButton } from './v2/IconButton/IconButton';
import { ArrowLeft } from 'lucide-react';
import { useRouter } from '@tanstack/react-router';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  actions?: React.ReactNode;
  titleActions?: React.ReactNode;
  isSubContainer?: boolean;
  canGoBack?: boolean;
  canScroll?: boolean;
  slim?: boolean;
};

export default function PageContainer({
  children,
  title,
  actions,
  isSubContainer,
  canGoBack,
  titleActions,
  canScroll = true,
  slim,
}: Props) {
  const router = useRouter();

  return (
    <div
      className={`h-full w-full ${canScroll ? 'overflow-y-auto' : 'overflow-hidden'}`}
    >
      <div
        className={`mx-auto flex min-h-full ${slim ? 'max-w-screen-md' : 'max-w-screen-4xl'} flex-col ${isSubContainer ? '' : 'px-6'} pb-6 pt-6`}
      >
        {(title || actions) && (
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-1">
              {canGoBack && (
                <IconButton
                  icon={ArrowLeft}
                  onClick={() => router.history.back()}
                />
              )}
              {title && (
                <h1 className="text-2xl font-black text-primary">{title}</h1>
              )}
              {titleActions}
            </div>
            {actions ? actions : <div />}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
