import * as React from 'react';
import { BrandVoiceCard } from './components';
import { Dialog } from '@/Components/Dialog';
import { Plus, X } from 'lucide-react';
import { BrandVoiceControllerState } from '@/types';
import {
  useProjectsUpdate,
  useBrandVoiceIndex,
  useProjectsBrandVoicesUpdate,
  useProjectsBrandVoicesStore,
  useProjectsBrandVoicesDestroy,
} from '@/api/openapiComponents';
import { useAppStore } from '../../AppLoader/stores';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useTrackCustomBrandVoices } from './hooks/useTrackCustomBrandVoices';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Subheading } from '../components/Subheading';
import { CardSection } from '../../components/CardSection';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { TextArea } from '@/Components/v2/TextArea/TextArea.tsx';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';
import { BrandVoiceResource } from '@/api/openapiSchemas.ts';
import { useShallow } from 'zustand/react/shallow';

const initialState: BrandVoiceControllerState = {
  id: -1,
  nameInput: '',
  isOpen: false,
  urls: [''],
  mode: 'ADD',
  activeTab: 0,
  promptInput: '',
  disabledTab: undefined,
  error: undefined,
};
const BrandVoice = () => {
  const { setCurrentProject, currentProject } = useAppStore(
    useShallow((state) => ({
      setCurrentProject: state.setCurrentProject,
      currentProject: state.currentProject,
    })),
  );

  const { mutate: updateProject } = useProjectsUpdate({});

  const client = useQueryClient();
  const { data: defaultBrandVoices, isLoading: isLoadingDefaultBrandVoices } =
    useBrandVoiceIndex({
      queryParams: { sort_direction: 'ASC', sort_by: 'id' },
    });
  const placeholder = useGetRandomPlaceHolder();

  const {
    data: customBrandVoices,
    isLoading: isLoadingCustomBrandVoices,
    refetch: refetchCustomBrandVoices,
  } = useTrackCustomBrandVoices();

  const [brandVoiceController, setBrandVoiceController] =
    React.useState<BrandVoiceControllerState>(initialState);

  const handleChangeDefaultBrandVoice = (brandVoice: BrandVoiceResource) => {
    updateProject(
      {
        pathParams: { project: currentProject!.id },
        body: { brand_voice_id: brandVoice.id },
      },
      {
        onSuccess: () => {
          setCurrentProject({
            ...currentProject!,
            default_brand_voice: brandVoice,
          });
        },
      },
    );
  };

  const handleOpenDialog = (
    mode: 'ADD' | 'EDIT' | 'EDIT_NAME',
    body?: {
      nameInput: string;
      urls: string[];
      id: number;
      promptInput: string;
      activeTab: number;
      disabledTab: number;
    },
  ) =>
    setBrandVoiceController((prev) => ({
      ...prev,
      isOpen: true,
      mode,
      ...body,
    }));

  const {
    mutate: addBrandVoice,
    isPending: isAddingNewBrandVoice,
    error: addBrandVoiceError,
    reset: resetAddBrandVoiceMutation,
  } = useProjectsBrandVoicesStore({
    onSuccess: (data) => {
      refetchCustomBrandVoices();
      handleCloseDialog();
    },
  });

  const { mutate: deleteBrandVoice } = useProjectsBrandVoicesDestroy({
    onSuccess: (data, variables, context) => {
      refetchCustomBrandVoices();
    },
  });

  const {
    mutate: editBrandVoice,
    isPending: isEditingNewBrandVoice,
    error: updateBrandVoiceError,
    reset: resetUpdateBrandVoiceMutation,
  } = useProjectsBrandVoicesUpdate({
    onSuccess: (data) => {
      refetchCustomBrandVoices();
      handleCloseDialog();
    },
  });

  const handleDeleteVoice = (BrandVoice: BrandVoiceResource) => {
    deleteBrandVoice({
      pathParams: { project: currentProject!.id, brandVoice: BrandVoice.id },
    });
  };

  const handleClickAddURL = () => {
    setBrandVoiceController((prev) => ({ ...prev, urls: [...prev.urls, ''] }));
  };

  const handleChangeNameInput = (value: string) =>
    setBrandVoiceController((prev) => ({
      ...prev,
      nameInput: value,
    }));

  const handleChangePromptInput = (value: string) => {
    setBrandVoiceController((prev) => ({
      ...prev,
      promptInput: value,
    }));
  };

  const handleChangeTab = (index: number) => {
    setBrandVoiceController((prev) => ({
      ...prev,
      activeTab: index,
    }));
  };

  const handleChangeSpecificURL = (v: string, i: number) => {
    setBrandVoiceController((prev) => ({
      ...prev,
      urls: prev.urls.map((value, index) => (index === i ? v : value)),
    }));
  };

  const handleRemoveSpecificURL = (i: number) => {
    setBrandVoiceController((prev) => ({
      ...prev,
      urls: prev.urls.filter((_, index) => index !== i),
    }));
  };

  const customBrandVoicesLength = customBrandVoices?.data?.length ?? 0;

  const addErrorHandler = new ErrorHelper(addBrandVoiceError);
  const updateErrorHandler = new ErrorHelper(updateBrandVoiceError);

  const isTrainingMode = brandVoiceController.mode !== 'EDIT_NAME';

  const handleCloseDialog = () => {
    setBrandVoiceController(initialState);
    resetUpdateBrandVoiceMutation();
    resetAddBrandVoiceMutation();
  };

  return (
    <>
      <Dialog
        key={'brand-voice-dialog'}
        isOpen={brandVoiceController.isOpen}
        handleClose={handleCloseDialog}
        title={
          brandVoiceController.mode === 'ADD'
            ? 'Train a brand voice'
            : `Adjust brand voice`
        }
        size={'sm'}
      >
        <div>
          <InputDecoration label="Name" required>
            <Input
              error={
                addErrorHandler.has('name') || updateErrorHandler.has('name')
              }
              value={brandVoiceController.nameInput}
              onChange={handleChangeNameInput}
              placeholder="Professional, friendly, casual, etc."
            />
          </InputDecoration>
          {isTrainingMode && (
            <>
              <div className="mt-4">
                <Tabs
                  type={'grow'}
                  tabs={[
                    {
                      text: 'URLs',
                      disabled: brandVoiceController?.disabledTab === 1,
                      ...(brandVoiceController.disabledTab === 1
                        ? {
                            tooltip: {
                              text: 'You cannot provide URLs for this brand voice when a prompt is provided',
                            },
                          }
                        : {}),
                    },
                    {
                      text: 'Prompt',
                      disabled: brandVoiceController?.disabledTab === 0,
                      ...(brandVoiceController.disabledTab === 0
                        ? {
                            tooltip: {
                              text: 'You cannot provide a prompt for this brand voice when URLs are provided',
                            },
                          }
                        : {}),
                    },
                  ]}
                  onChange={handleChangeTab}
                  value={brandVoiceController.activeTab}
                />
              </div>
              {brandVoiceController.activeTab === 0 && (
                <>
                  <div className="mt-4">
                    <InputDecoration
                      label=""
                      description="Provide up to 3 URLs for the AI to learn style and tone of voice."
                    >
                      <div className="flex flex-col gap-2">
                        {brandVoiceController.urls.map((url, i) => (
                          <div
                            className="flex flex-row items-center gap-2"
                            key={i}
                          >
                            <div className={'grow'}>
                              <Input
                                value={url}
                                placeholder={`e.g. ${placeholder.url}`}
                                onChange={(value) =>
                                  handleChangeSpecificURL(value, i)
                                }
                              />
                            </div>
                            {i !== 0 && (
                              <div className="justify-center">
                                <IconButton
                                  icon={X}
                                  size={'sm'}
                                  onClick={() => handleRemoveSpecificURL(i)}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </InputDecoration>
                    <div className="mt-4 flex justify-center">
                      <Button
                        variant={'outline'}
                        fullWidth={true}
                        text="Add Another URL"
                        prependIcon={Plus}
                        disabled={brandVoiceController.urls.length >= 3}
                        onClick={handleClickAddURL}
                      />
                    </div>
                  </div>
                </>
              )}
              {brandVoiceController.activeTab === 1 && (
                <div className="mt-4">
                  <InputDecoration
                    label="Prompt"
                    description="Provide a prompt for the AI to follow for style and tone of voice."
                  >
                    <TextArea
                      resize
                      error={
                        addErrorHandler.has('prompt') ??
                        updateErrorHandler.has('prompt')
                      }
                      name="prompt"
                      rows={8}
                      value={brandVoiceController.promptInput}
                      onChange={handleChangePromptInput}
                      placeholder="E.g Write in a professional tone, use formal language, etc."
                    />
                  </InputDecoration>
                </div>
              )}
            </>
          )}
          {addErrorHandler.isError() && (
            <div className="my-2">
              <ErrorAlert title={addErrorHandler.message()} />
            </div>
          )}
          {updateErrorHandler.isError() && (
            <div className="my-2">
              <ErrorAlert title={updateErrorHandler.message()} />
            </div>
          )}
          <div
            className={`${
              isTrainingMode ? 'mt-12' : 'mt-6'
            } flex justify-center`}
          >
            <Button
              isLoading={isAddingNewBrandVoice || isEditingNewBrandVoice}
              onClick={() => {
                const body = {
                  name: brandVoiceController.nameInput,
                  urls:
                    brandVoiceController.activeTab === 0
                      ? brandVoiceController.urls
                      : undefined,
                  prompt:
                    brandVoiceController.activeTab === 1
                      ? brandVoiceController.promptInput
                      : undefined,
                };

                if (brandVoiceController.mode === 'ADD') {
                  addBrandVoice({
                    pathParams: { project: currentProject!.id },
                    body,
                  });
                }
                if (brandVoiceController.mode === 'EDIT') {
                  editBrandVoice({
                    pathParams: {
                      brandVoice: brandVoiceController.id,
                      project: currentProject!.id,
                    },
                    body: {
                      ...body,
                      train:
                        brandVoiceController.urls.filter(
                          (n) => n.trim().length > 0,
                        ).length > 0,
                    },
                  });
                }
                if (brandVoiceController.mode === 'EDIT_NAME') {
                  editBrandVoice({
                    pathParams: {
                      brandVoice: brandVoiceController.id,
                      project: currentProject!.id,
                    },
                    body: {
                      ...body,
                      train: false,
                    },
                  });
                }
              }}
              text={
                isTrainingMode && brandVoiceController.activeTab === 0
                  ? 'Start training'
                  : 'Save'
              }
              color="secondary"
            />
          </div>
          {isTrainingMode && brandVoiceController.activeTab == 0 && (
            <p className="mt-3 text-center text-sm text-gray-500">
              Training takes around 45-60 seconds
            </p>
          )}
        </div>
      </Dialog>
      <>
        <Subheading
          text="The brand voice controls how the AI writes. You can train the AI to
          write in a specific style and tone of voice. Or you can provide a trained prompt for the AI to follow."
          link={{ href: 'https://docs.seo.ai/brand-voice', text: 'Read more' }}
        />
        <CardSection
          gap={4}
          isEmptyProps={{
            isEmpty: customBrandVoicesLength === 0,
            onClick: () => handleOpenDialog('ADD'),
            text: 'No custom brand voices trained yet',
            buttonText: 'ADD NEW',
          }}
          title="Custom"
          buttonProps={{
            toolTip: {
              description: 'Delete a custom brand voice to create a new one',
              title: 'Maximum reached',
            },
            text: 'ADD NEW',
            onClick: () => handleOpenDialog('ADD'),
            disabled:
              !isLoadingCustomBrandVoices && customBrandVoicesLength >= 3,
          }}
          isLoading={isLoadingCustomBrandVoices}
        >
          {customBrandVoices?.data.map((brandVoice) => (
            <BrandVoiceCard
              key={brandVoice.id.toString()}
              handleOpenDialog={handleOpenDialog}
              onDelete={handleDeleteVoice}
              brandVoice={brandVoice}
              isCustomBrandVoice={true}
              onDefault={handleChangeDefaultBrandVoice}
              bgColor="bg-white"
            />
          ))}
        </CardSection>
        <CardSection
          gap={4}
          title="Pre-defined"
          isLoading={isLoadingDefaultBrandVoices}
        >
          {defaultBrandVoices?.data.map((brandVoice) => (
            <BrandVoiceCard
              key={brandVoice.id.toString()}
              brandVoice={brandVoice}
              description={brandVoice.description}
              onDefault={handleChangeDefaultBrandVoice}
              isCustomBrandVoice={false}
            />
          ))}
        </CardSection>
      </>
    </>
  );
};

BrandVoice.displayName = 'BrandVoice';

export { BrandVoice };
