import { MARK_BOLD, MARK_ITALIC } from '@udecode/plate-basic-marks';
import {
  collapseSelection,
  focusEditor,
  toggleNodeType,
  useEditorReadOnly,
  useEditorState,
} from '@udecode/plate-common';
import { Icons } from './icons';
import {
  MarkToolbarButton,
  TurnIntoDropdownMenu,
  LinkToolbarButton,
  ToolbarButton,
  ToolbarGroup,
} from '../components';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { isInsideTableCell } from '../lib/utils';
import { insertTable } from '@udecode/plate-table';
import { useState } from 'react';
import { AddImageDialog } from './add-image-dialog';
import { useQuery } from '@tanstack/react-query';
import { Generating } from '@/types';

export function FloatingToolbarButtons() {
  const readOnly = useEditorReadOnly();

  const editor = useEditorState();
  const isV2 = localStorage.getItem('v2_editor') === 'true';

  const [showAddImageDialog, setShowAddImageDialog] = useState(false);
  const { data: isGenerating } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });

  const toggleBlockQuote = () => {
    toggleNodeType(editor, { activeType: ELEMENT_BLOCKQUOTE });

    collapseSelection(editor);
    focusEditor(editor);
  };

  return (
    <>
      <AddImageDialog
        isOpen={showAddImageDialog}
        onClose={() => setShowAddImageDialog(false)}
      />
      {!readOnly && (
        <>
          <TurnIntoDropdownMenu />

          <MarkToolbarButton nodeType={MARK_BOLD} tooltip="Bold (⌘+B)">
            <Icons.bold />
          </MarkToolbarButton>
          <MarkToolbarButton nodeType={MARK_ITALIC} tooltip="Italic (⌘+I)">
            <Icons.italic />
          </MarkToolbarButton>

          <ToolbarButton
            disabled={isInsideTableCell(editor)}
            tooltip="Blockquote (⌘+shift+.)"
            onClick={toggleBlockQuote}
          >
            <Icons.blockquote />
          </ToolbarButton>
          {/*
          <MarkToolbarButton nodeType={MARK_CODE} tooltip='Code (⌘+E)'>
            <Icons.code />
          </MarkToolbarButton>
          */}
        </>
      )}
      <LinkToolbarButton />
      {isV2 && (
        <ToolbarGroup>
          <LinkToolbarButton disabled={!!isGenerating} />
          <ToolbarButton
            disabled={!!isGenerating || isInsideTableCell(editor)}
            tooltip="Table"
            onClick={() => {
              insertTable(editor, {
                rowCount: 2,
              });
            }}
          >
            <Icons.table />
          </ToolbarButton>
          <ToolbarButton
            tooltip="Insert image"
            onClick={() => setShowAddImageDialog(true)}
            disabled={!!isGenerating || isInsideTableCell(editor)}
          >
            <Icons.image />
          </ToolbarButton>
        </ToolbarGroup>
      )}
      {/*
        <MoreDropdownMenu />
      */}
    </>
  );
}
