import { InputLabel } from '@/Components/v2/Input/InputLabel';
import { AiWriterFormState } from '../AiDraft';
import { useEffect, useState } from 'react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ArrowLeftRight } from 'lucide-react';

type Props = {
  formState: AiWriterFormState;
};

export const CsvPreview = ({ formState }: Props) => {
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    handleChooseRow();
  }, [formState.bulk.csvData]);

  const handleChooseRow = () => {
    if (!formState.bulk.csvData) return;
    setRowIndex(Math.floor(Math.random() * formState.bulk.csvData.length));
  };

  if (!formState.bulk.csvData) return null;

  return (
    <div className="relative flex min-h-12 flex-col gap-4 rounded-md border border-dashed border-primary-300 bg-primary-25 p-4">
      <InputLabel
        label={`Example based on row ${rowIndex}`}
        variant="overlay"
      />

      <div className="absolute right-2 top-2">
        <IconButton icon={ArrowLeftRight} onClick={handleChooseRow} />
      </div>

      {formState.bulk.csvMainKeywordColumnIndex !== undefined && (
        <div>
          <p className="mb-2 text-xs uppercase text-primary-400">
            Main keyword
          </p>
          <p className="text-primary">
            {
              formState.bulk.csvData?.[rowIndex]?.[
                formState.bulk.csvMainKeywordColumnIndex!
              ]
            }
          </p>
        </div>
      )}

      {formState.bulk.csvContextColumnIndexes?.length > 0 && (
        <div>
          <p className="mb-2 text-xs uppercase text-primary-400">
            Context for AI
          </p>
          {formState.bulk.csvContextColumnIndexes?.map((index) => (
            <p key={index} className="mb-2 text-primary">
              {formState.bulk.csvData?.[0][index]}:{' '}
              {formState.bulk.csvData?.[rowIndex]?.[index]}
            </p>
          ))}
        </div>
      )}

      {formState.bulk.csvUrlColumnIndex !== undefined && (
        <div>
          <p className="mb-2 text-xs uppercase text-primary-400">URL</p>
          <p className="text-primary">
            {
              formState.bulk.csvData?.[rowIndex]?.[
                formState.bulk.csvUrlColumnIndex!
              ]
            }
          </p>
        </div>
      )}
    </div>
  );
};
