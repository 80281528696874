import {
  useGetGoogleSearchConsole,
  useSetupGoogleSearchConsole,
  useSelectSite,
  useProjectsConnectionsDestroy,
  useCreateShopifyConnection,
} from '@/api/openapiComponents';
import { queryKeyFn } from '@/api/openapiContext';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useAppStore } from '../AppLoader/stores';

const initialState = {
  isOpen: false,
  sites: [],
  selectedSite: undefined,
};

export const useConnectToGoogleSearchConsole = (projectId: number) => {
  const appState = useAppStore();
  const [googleDialog, setGoogleDialog] = useState<{
    isOpen: boolean;
    sites: string[];
    selectedSite: string | undefined;
  }>(initialState);

  const client = useQueryClient();
  const variables = {
    pathParams: {
      project: projectId,
    },
  };

  const {
    data: googleSearchConsoleData,
    isLoading: isGettingGoogleSearchConsoleData,
  } = useGetGoogleSearchConsole(variables, { retry: false });

  const {
    mutate: setupGoogleConsole,
    isPending: isSettingUpGoogleSearchConsole,
  } = useSetupGoogleSearchConsole({
    onSuccess: (data) => {
      setGoogleDialog({
        isOpen: true,
        sites: data.data.settings.available_sites,
        selectedSite: data.data.settings.site,
      });
    },
  });

  const {
    mutate: selectSiteForGoogleSearchConsole,
    isPending: isSelectingSiteForGoogleSearchConsole,
  } = useSelectSite({
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: queryKeyFn({
          path: '/projects/{project}/connection/google-search-console',
          operationId: 'getGoogleSearchConsole',
          variables,
        }),
      });
    },
  });

  const { mutate: disconnect } = useProjectsConnectionsDestroy({
    onSuccess: () => {
      client.resetQueries({
        queryKey: queryKeyFn({
          path: '/projects/{project}/connection/google-search-console',
          operationId: 'getGoogleSearchConsole',
          variables,
        }),
      });
    },
  });

  return {
    googleDialog,
    setGoogleDialog,
    isGettingGoogleSearchConsoleData,
    setupGoogleConsole,
    isSettingUpGoogleSearchConsole,
    selectSiteForGoogleSearchConsole,
    isSelectingSiteForGoogleSearchConsole,
    googleSearchConsoleData,
    disconnect,
    initialState,
    hasNoAvailableSites:
      googleSearchConsoleData?.data.settings.available_sites.length === 0,
  };
};
