import { HelpCircle } from 'lucide-react';
import React from 'react';
import { Tooltip } from '../Tooltip';
import { InputLabel } from './InputLabel';

type Props = {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  description?: string;
  hint?: string;
  tooltip?: string;
  variant?: 'default' | 'overlay';
  readMoreLink?: string;
};

export const InputDecoration = ({
  children,
  label,
  description,
  required,
  tooltip,
  hint,
  variant = 'default',
  readMoreLink,
}: Props) => {
  return (
    <div className={`relative w-full ${variant === 'overlay' ? 'mt-2' : ''}`}>
      <InputLabel
        label={label}
        hint={hint}
        required={required}
        tooltip={tooltip}
        variant={variant}
      />
      {description ? (
        <p className=" mb-3 text-primary-600">
          {description}{' '}
          {readMoreLink ? (
            <a
              className="underline hover:opacity-60"
              href={readMoreLink}
              target="_blank"
              rel="noreferrer"
            >
              Read more.
            </a>
          ) : undefined}
        </p>
      ) : undefined}
      {children}
    </div>
  );
};
