import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { useAppStore } from '../AppLoader/stores';
import { TemplateSelect } from '@/TemplateSelect';
import {
  useAutoGenerateFromKeywords,
  useCreateDocument,
  useListDocumentTemplates,
  useProjectGenerateDocument,
} from '@/api/openapiComponents';
import { useState } from 'react';
import { AiGeneratedFormState } from '../CreateNewContent/pages/AiGenerated/AiGenerated';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import ToggleButton from '@/Components/ToggleButton';
import { SingleContentForm } from '../CreateNewContent/pages/AiGenerated/components/SingleContentForm';
import { SingleForm } from './components/SingleForm';
import { useNavigate } from '@tanstack/react-router';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { BulkForm } from './components/BulkForm';
import {
  AutoGenerateFromKeywordsRequest,
  OrganisationTagResource,
} from '@/api/openapiSchemas';
import { ConfirmDialog } from '@/Components/ConfirmDialog';

export type AiWriterFormState = {
  templateId?: number;
  mode: 'single' | 'bulk';
  single: {
    keyword: string;
    contentTypeId?: number;
    title: string;
    brief: string;
    briefFromUrl: string;
    optionLiveGenerate: boolean;
    optionReviewSubheadings: boolean;
    optionReviewTitle: boolean;
    selectedBriefType: 'from-url' | 'manual';
    importedUrl: string;
  };
  bulk: {
    type: 'keyword-list' | 'csv-import';
    csvData?: string[][];
    csvMainKeywordColumnIndex?: number;
    csvContextColumnIndexes?: number[];
    csvUrlColumnIndex?: number;
    keywords: string;
    contentTypeId?: number;
    tags: OrganisationTagResource[];
  };
};

export const AiWriter = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const [templateError, setTemplateError] = useState(false);

  const [formState, setFormState] = useState<AiWriterFormState>({
    templateId: -1,
    mode: 'single',
    single: {
      keyword: '',
      importedUrl: '',
      contentTypeId: undefined,
      title: '',
      brief: '',
      briefFromUrl: '',
      selectedBriefType: 'manual',
      optionLiveGenerate: false,
      optionReviewTitle: true,
      optionReviewSubheadings: true,
    },
    bulk: {
      type: 'keyword-list',
      keywords: '',
      contentTypeId: undefined,
      tags: [],
    },
  });

  const bulkGenerateMutation = useAutoGenerateFromKeywords();
  const generateDocumentMutation = useProjectGenerateDocument();
  const customTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      filters: {
        project_id: appState.currentProject!.id,
      },
      limit: 100,
    },
  });

  const customTemplates = customTemplatesQuery.data?.data ?? [];

  const predefinedTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      limit: 100,
    },
  });

  const predefinedTemplates = predefinedTemplatesQuery.data?.data ?? [];

  const handleSubmit = () => {
    if (!formState.templateId) {
      setTemplateError(true);
      return;
    }
    setTemplateError(false);
    if (formState.mode === 'single') {
      const brief =
        formState.single.selectedBriefType === 'manual'
          ? formState.single.brief
          : formState.single.briefFromUrl;

      generateDocumentMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body: {
            keyword: formState.single.keyword,
            title:
              formState.single.title.length === 0
                ? undefined
                : formState.single.title,
            brief: formState.single.brief,
            autostart: true,
            document_template_id:
              formState.templateId >= 0 ? formState.templateId! : undefined,
            ...(formState.single.brief.length > 0 ||
            formState.single.briefFromUrl.length > 0
              ? {
                  settings: {
                    context: {
                      value: brief.slice(
                        0,
                        appState.currentProject?.language?.brief_length ?? 0,
                      ),
                      type:
                        formState.single.selectedBriefType === 'from-url'
                          ? 'url'
                          : 'none',
                      type_value:
                        formState.single.selectedBriefType === 'from-url'
                          ? formState.single.importedUrl
                          : undefined,
                    },
                  },
                }
              : {}),
          },
        },
        {
          onSuccess: () => {
            navigate({ to: '/created-content' });
          },
        },
      );
    }
    if (formState.mode === 'bulk') {
      const body: AutoGenerateFromKeywordsRequest =
        formState.bulk.type === 'keyword-list'
          ? {
              documents: formState.bulk.keywords
                .split('\n')
                .filter(Boolean)
                .map((keyword) => ({ keyword })),
              document_template_id:
                formState.templateId >= 0 ? formState.templateId! : undefined,
              tags: formState.bulk.tags.map((tag) => tag.id),
            }
          : {
              documents: formState.bulk.csvData?.map((data) => ({
                keyword:
                  formState.bulk.csvMainKeywordColumnIndex !== undefined
                    ? data[formState.bulk.csvMainKeywordColumnIndex]
                    : undefined,
                context: formState.bulk.csvContextColumnIndexes
                  ?.map(
                    (rowIndex, index) =>
                      formState.bulk.csvData?.[0][index] +
                      ':' +
                      formState.bulk.csvData?.[rowIndex]?.[index] +
                      '\n',
                  )
                  .join(''),
                url:
                  formState.bulk.csvUrlColumnIndex !== undefined
                    ? data[formState.bulk.csvUrlColumnIndex]
                    : undefined,
              })),
              document_template_id:
                formState.templateId >= 0 ? formState.templateId! : undefined,
              tags: formState.bulk.tags.map((tag) => tag.id),
            };
      bulkGenerateMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body: body,
        },
        {
          onSuccess: () => {
            navigate({ to: '/created-content' });
          },
        },
      );
    }
  };

  const errorHelper = new ErrorHelper(
    generateDocumentMutation.error ?? bulkGenerateMutation.error,
  );

  appState.pageTitle('Ai writer');

  return (
    <Menus>
      <div className="border-b border-gray-200 bg-gray-50">
        <PageContainer slim>
          <div className="flex w-full flex-col items-center justify-center gap-4">
            <img src={'/svg/ai-draft-header.svg'} />
            <p className="text-xl font-bold text-primary">
              AI Writer for Content Drafts
            </p>
            <p className="px-6 text-center text-primary">
              Quickly generate SEO-friendly content by selecting a template,
              choosing a keyword or uploading a file with keywords and context.
            </p>
          </div>
        </PageContainer>
      </div>
      <div>
        <PageContainer slim>
          <div className="flex flex-col gap-10">
            <TemplateSelect
              required
              error={templateError ? 'Please select a template' : undefined}
              customTemplates={customTemplates}
              predefinedTemplates={[
                {
                  id: -1,
                  name: 'Blog post',
                  context: 'A blog post template',
                  description: 'A blog post template',
                },
                ...predefinedTemplates,
              ]}
              onChange={(values) =>
                setFormState({
                  ...formState,
                  templateId: values.templateId,
                })
              }
              value={formState.templateId!}
              disabled={
                predefinedTemplatesQuery.isLoading ||
                customTemplatesQuery.isLoading
              }
              featureLocked={
                !appState.subscription?.permissions.custom_document_templates
              }
            />

            <InputDecoration
              label="Generation mode"
              description="Do you want to generate a single or many in one go?"
            >
              <ToggleButton
                options={[
                  { displayName: 'Single', value: 'single' },
                  { displayName: 'Bulk', value: 'bulk' },
                ]}
                value={formState.mode}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    mode: value as AiWriterFormState['mode'],
                  })
                }
              />
            </InputDecoration>

            {formState.mode === 'single' && (
              <SingleForm
                onSubmit={handleSubmit}
                isLoading={generateDocumentMutation.isPending}
                formState={formState}
                setFormState={setFormState}
                errorHelper={errorHelper}
              />
            )}

            {formState.mode === 'bulk' && (
              <BulkForm
                onSubmit={handleSubmit}
                errorHelper={errorHelper}
                formState={formState}
                setFormState={setFormState}
                isLoading={bulkGenerateMutation.isPending}
              />
            )}
          </div>
        </PageContainer>
      </div>
    </Menus>
  );
};
