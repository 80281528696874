import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { FormEvent, useEffect, useState } from 'react';
import { AiGeneratedFormState } from '../AiGenerated';
import {
  ContentTypeResource,
  DocumentTemplateResource,
} from '@/api/openapiSchemas';
import { SettingsToggle } from '../../../components/SettingsSwitch';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useAnalyzePage } from '@/api/openapiComponents';
import { useSearch } from '@tanstack/react-router';
import { ErrorAlert } from '@/Components/v2/Alert';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';
import { AdditionalContextForm } from '@/Components/AdditionalContextForm';
import { TemplateSelect } from '@/TemplateSelect';

type Props = {
  isLoading?: boolean;
  formState: AiGeneratedFormState;
  contentTypes: ContentTypeResource[];
  briefLimit: number;
  onSubmit: () => void;
  setFormState: (state: AiGeneratedFormState) => void;
  hasNewTemplatesFeature: () => boolean;
  customTemplates?: DocumentTemplateResource[];
  predefinedTemplates?: DocumentTemplateResource[];
};

export const SingleContentForm = ({
  formState,
  isLoading,
  briefLimit,
  setFormState,
  onSubmit,
  contentTypes,
  hasNewTemplatesFeature,
  customTemplates,
  predefinedTemplates,
}: Props) => {
  const placeholder = useGetRandomPlaceHolder();
  const appState = useAppStore();
  const search = useSearch({ from: '/create-content/ai-generated' });

  const [keywordError, setKeywordError] = useState<string>();
  const [keywordFieldDisabled, setKeywordFieldDisabled] = useState(false);

  const setSingleFormValues = (
    values: Partial<AiGeneratedFormState['single']>,
  ) => {
    setFormState({
      ...formState,
      single: {
        ...formState.single,
        ...values,
      },
    });
  };

  const analyseUrlMutation = useAnalyzePage({
    onSuccess: (data) => {
      setSingleFormValues({ briefFromUrl: data.data.content });
    },
  });

  useEffect(() => {
    if (search.url) {
      setSingleFormValues({
        importedUrl: search.url,
        selectedBriefType: 'from-url',
      });
      handleImportBrief(search.url);
    }

    setKeywordFieldDisabled(
      search.documentId !== undefined &&
        formState.single.keyword !== undefined &&
        formState.single.keyword !== '',
    );
  }, []);

  const handleImportBrief = (url?: string) => {
    analyseUrlMutation.mutate({
      body: {
        url: url ?? formState.single.importedUrl,
        content_format: 'text',
        page_type: 'analyze',
      },
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (formState.single.keyword === '') {
      setKeywordError('Keyword is required');
      return;
    }
    setKeywordError(undefined);

    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <InputDecoration
        label="Target keyword"
        required
        hint="The keyword you would like to rank on"
      >
        <Input
          clearable={
            keywordFieldDisabled === false &&
            formState.single.keyword !== undefined &&
            formState.single.keyword !== ''
          }
          disabled={keywordFieldDisabled || isLoading}
          error={keywordError}
          value={formState.single.keyword}
          onChange={(value) => setSingleFormValues({ keyword: value })}
          placeholder={placeholder.keyword}
        />
      </InputDecoration>
      <InputDecoration label="H1" hint="Leave blank to let ai create">
        <Input
          clearable
          disabled={isLoading}
          value={formState.single.title}
          onChange={(value) => setSingleFormValues({ title: value })}
          placeholder={placeholder.title}
        />
      </InputDecoration>
      <TemplateSelect
        customTemplates={customTemplates}
        predefinedTemplates={predefinedTemplates}
        onChange={(values) =>
          setSingleFormValues({
            contentTypeId: values.contentTypeId,
            templateId: values.templateId ? values.templateId : null,
          })
        }
        value={formState.single.templateId!}
        disabled={isLoading}
        featureLocked={
          !appState.subscription?.permissions.custom_document_templates
        }
      />
      <ContentTypeSelect
        contentTypes={contentTypes}
        isLoading={isLoading}
        onChange={(value) =>
          setSingleFormValues({
            contentTypeId: value,
          })
        }
        value={formState.single.contentTypeId}
      />

      <InputDecoration label="Additional context">
        <AdditionalContextForm
          briefLimit={briefLimit}
          disabled={isLoading}
          isImportSuccess={analyseUrlMutation.isSuccess}
          isLoading={analyseUrlMutation.isPending}
          brief={formState.single.brief}
          briefFromUrl={formState.single.briefFromUrl}
          url={formState.single.importedUrl}
          onBriefChange={(value) => setSingleFormValues({ brief: value })}
          onBriefFromUrlChange={(value) =>
            setSingleFormValues({ briefFromUrl: value })
          }
          onUrlChange={(value) => setSingleFormValues({ importedUrl: value })}
          onTabChange={(value) =>
            setSingleFormValues({
              selectedBriefType: value as 'from-url' | 'manual' | undefined,
            })
          }
          selectedTab={formState.single.selectedBriefType}
          onImport={handleImportBrief}
          placeholder={placeholder}
        />
      </InputDecoration>
      <InputDecoration label="Generation settings">
        <div className="mt-3 flex flex-col gap-2">
          <SettingsToggle
            disabled={formState.single.templateId !== null || isLoading}
            text="View writing in real-time"
            onChange={(value) =>
              setSingleFormValues({ optionLiveGenerate: value })
            }
            value={
              formState.single.templateId
                ? false
                : formState.single.optionLiveGenerate
            }
          />
          <SettingsToggle
            disabled={formState.single.templateId !== null || isLoading}
            text="Review Title suggestions"
            onChange={(value) =>
              setSingleFormValues({ optionReviewTitle: value })
            }
            value={
              formState.single.templateId
                ? false
                : formState.single.optionReviewTitle
            }
          />
          <SettingsToggle
            disabled={formState.single.templateId !== null || isLoading}
            text="Review Subheadings suggestions"
            onChange={(value) =>
              setSingleFormValues({ optionReviewSubheadings: value })
            }
            value={
              formState.single.templateId
                ? false
                : formState.single.optionReviewSubheadings
            }
          />
        </div>
      </InputDecoration>
      {(!appState.subscription!.usage.credits.generated_article.is_allowed ||
        !appState.subscription!.usage.credits.content.is_allowed) && (
        <ErrorAlert title="You have insufficient credits to generate an AI article" />
      )}

      <div className="mx-auto w-1/2">
        <Button
          fullWidth
          text={(() => {
            if (hasNewTemplatesFeature()) {
              return `${search.documentId ? 'SAVE' : 'CREATE'} AND REVIEW`;
            }
            return 'Generate';
          })()}
          type="submit"
          color="secondary"
          disabled={
            !appState.subscription!.usage.credits.generated_article
              .is_allowed ||
            !appState.subscription!.usage.credits.content.is_allowed
          }
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};
