import { PlaceHolder } from '@/types';
import { useRef } from 'react';

const placeholders: PlaceHolder[] = [
  {
    keyword: 'e.g. red wine',
    title: 'e.g. Pouring Perfection: Exploring the Rich World of Red Wine',
    audience: 'e.g. Wine Enthusiasts',
    url: 'https://thewinepark.com/top-10-red-wines-in-2099',
    outlinePrompt: 'e.g. Give me suggestions based on locations',
    context: 'Context...',
  },
  {
    keyword: 'e.g. wedding planning tips',
    title: 'e.g. Proven Wedding Planning Tips for a Perfect Day',
    audience: 'e.g. Young Couples',
    url: 'https://www.brides.com/the-best-wedding-dresses',
    outlinePrompt: 'e.g. Give me suggestions based on the latest trends',
    context: 'Context...',
  },
  {
    keyword: 'e.g. super heroes',
    title: 'e.g. Top 10 comic book superheroes',
    audience: 'e.g. Fans of Superhero Lore',
    url: 'https://marvel.com/comics/iron-man-4',
    outlinePrompt: 'e.g. Give me suggestions based on the latest movies',
    context: 'Context...',
  },
  {
    keyword: 'e.g. video games',
    title: 'e.g. Exploring the World of Video Games',
    audience: 'e.g. Young Gamers',
    url: 'https://ign.com/category/action-adventure',
    outlinePrompt: 'e.g. Give me suggestions based on the latest releases',
    context: 'Context...',
  },
  {
    keyword: 'e.g. champions league',
    title: 'e.g. Conquering Europe: The Ultimate Guide to the Champions League',
    audience: 'e.g. Football Fans',
    url: 'https://uefa.com/blog/be-one-with-the-ball',
    outlinePrompt: 'e.g. Give me suggestions based on the latest matches',
    context: 'Context...',
  },
];

const getRandomPlaceHolders = () => {
  return placeholders[Math.floor(Math.random() * placeholders.length)];
};

export const useGetRandomPlaceHolder = (): PlaceHolder => {
  const ref = useRef<PlaceHolder>(getRandomPlaceHolders());

  return ref.current;
};
