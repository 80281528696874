import { useState } from 'react';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { KeywordResearchHeader } from './components/KeywordKesearchHeader';
import { SortParameters, useTable } from '@/Components/Table/hooks';
import { Stars } from 'lucide-react';
import { CreateKeywordButton } from './components/CreateKeywordButton';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { formatThousandSeperator } from '@/utils';
import { StringSelector } from '@/Components/Table/components/StringSelector';
import { Button } from '@/Components/v2/Button';
import { CopySelectedAction } from './components/CopySelectedAction';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { useKeywordExport } from './hooks';
import { useProjectKeywordResearchAudienceExplorer } from '@/api/openapiComponents';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';
import PageContainer from '@/Components/PageContainer';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Input } from '@/Components/v2/Input/Input';
import { KeywordResearchActions } from './components/KeywordResearchActions';

type Filters = {
  target_audience: string;
  keyword: string;
  search_volume_min: number;
  search_volume_max: number;
  difficulty_min: number;
  difficulty_max: number;
  exclude_phrase: string;
};

export default function AudienceExplorer() {
  const placeholder = useGetRandomPlaceHolder();

  const appState = useAppStore();
  const [showTable, setShowTable] = useState(false);

  const {
    tableState,
    setFilters,
    setSorting,
    selection,
    setSelection,
    resetSelection,
  } = useTable<
    'id' | 'keyword' | 'search_volume' | 'difficulty' | undefined,
    Filters,
    SlimKeywordResource
  >({
    filters: {
      target_audience: appState.currentProject!.audience,
    } as Filters,
    sortBy: 'search_volume',
    sortDirection: 'DESC',
  });

  const keywordMutation = useProjectKeywordResearchAudienceExplorer();

  const onExportCSV = useKeywordExport(
    {
      sort_by: tableState.sortBy,
      sort_direction: tableState.sortDirection,
      ids:
        keywordMutation.data?.data.map((keyword) => keyword.id.toString()) ??
        [],
    },
    'audience_keywords',
  );

  const handleSubmit = () => {
    if (!tableState.filters) return;
    resetSelection();
    keywordMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...tableState,
      },
    });
    setShowTable(true);
  };

  const handleSorting = (
    sorting: SortParameters<
      'id' | 'keyword' | 'search_volume' | 'difficulty' | undefined
    >,
  ) => {
    setSorting(sorting, { submit: true });
  };

  const isLoading = keywordMutation.isPending;

  const errorHelper = new ErrorHelper(keywordMutation.error as any);

  appState.pageTitle('Audience keywords');

  return (
    <Menus>
      <PageContainer title="Keyword research">
        <KeywordResearchHeader type="audience-keywords">
          <form
            className="w-full max-w-xl"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <InputDecoration label="Seed keyword" required>
              <Input
                value={tableState.filters?.keyword ?? ''}
                onChange={(value) => setFilters({ keyword: value })}
                placeholder={placeholder.keyword}
                hint="Write a short description for the AI to find keywords based on"
              />
            </InputDecoration>

            <InputDecoration label="Audience or problem" required>
              <Input
                value={tableState.filters?.target_audience ?? ''}
                onChange={(value) => setFilters({ target_audience: value })}
                placeholder={placeholder.audience}
                hint="Write a short description for the AI to find keywords based on"
              />
            </InputDecoration>

            <button type="submit" hidden />
          </form>
          {/* Filters */}
          <div className="mb-4 flex select-none justify-center gap-2 text-sm text-gray-600">
            {/* Exclude phrase */}
            <Table.FilterPopover
              name="Exclude phrase"
              filterName={
                tableState.filters?.exclude_phrase
                  ? `Exclude phrase: ${tableState.filters.exclude_phrase}`
                  : undefined
              }
              onRemoveFilter={() =>
                setFilters({
                  exclude_phrase: undefined,
                })
              }
            >
              <StringSelector
                name="Exclude phrase"
                value={tableState.filters?.exclude_phrase}
                onAccept={(value) => setFilters({ exclude_phrase: value })}
              />
            </Table.FilterPopover>
          </div>

          {/* Submit */}
          <Button
            color="secondary"
            onClick={handleSubmit}
            isLoading={isLoading}
            text="Find keywords"
            prependIcon={Stars}
          />
        </KeywordResearchHeader>

        {errorHelper.isError() && (
          <div className="mx-auto mt-2 w-full max-w-xl">
            <ErrorAlert title={errorHelper.message()} />
          </div>
        )}

        {/* Table */}
        {showTable && (
          <div className="mt-12 px-6">
            <Table.Root
              selection={selection}
              onSelectionChange={setSelection}
              sorting={tableState}
              onSortChange={handleSorting}
              skeletonLoaders={7}
              isLoading={isLoading}
              items={keywordMutation.data?.data}
              columns={[
                {
                  property: 'name',
                  render: (item) => <>{item.name}</>,
                  sortableHeader: true,
                  heading: 'TARGET KEYWORD',
                },
                {
                  property: 'search_volume',
                  sortableHeader: true,
                  rightAlign: true,
                  heading: 'SEARCH VOLUME',
                  render: (item) => (
                    <>
                      {item.search_volume === 0
                        ? '< 10'
                        : formatThousandSeperator(item.search_volume)}
                    </>
                  ),
                },
                {
                  property: 'difficulty',
                  sortableHeader: true,
                  rightAlign: true,
                  heading: 'RANK DIFFICULTY',
                  render: (item) => (
                    <DifficultyLabel difficulty={item.difficulty} />
                  ),
                },
                {
                  render: (item) => (
                    <div className="flex justify-end">
                      <div
                        key={item.id}
                        className="flex w-36 flex-row items-center justify-end gap-2"
                      >
                        <div>
                          <CreateKeywordButton
                            current_project={appState.currentProject!}
                            keyword={item}
                          />
                        </div>
                        <KeywordResearchActions keyword={item.name} />
                      </div>
                    </div>
                  ),
                },
              ]}
            >
              <Table.Header csvExportOptions={onExportCSV}>
                <CopySelectedAction selection={selection} />
              </Table.Header>
            </Table.Root>
          </div>
        )}
      </PageContainer>
    </Menus>
  );
}
