import * as React from 'react';
import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import * as Table from '@/Components/Table';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import {
  Copy,
  ExternalLink,
  Files,
  FileX2,
  MoreVertical,
  Plus,
  Trash2,
  Upload,
} from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
import { TagEditor } from '../Document/components/TagEditor/TagEditor';
import { hasher, useFeatureFlagging } from '../AppLoader/AppLoader';
import { MultipleItemSelector } from '@/Components/Table/components/MultipleItemSelector';
import AppLabel, { TagVariant } from '@/Components/Labels/AppLabel';
import { Loader } from '@/Components/Loader';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useAppStore } from '../AppLoader/stores';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  DocumentResource,
  OrganisationTagResource,
} from '@/api/openapiSchemas';
import ToggleButton from '@/Components/ToggleButton';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ContentCreatedRoute, ContentCreatedSearchParams } from './routes';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import {
  fetchGetDocument,
  useCreateDocument,
  useDeleteDocument,
  useExportDocuments,
  useGetCmsConnection,
  useListDocuments,
  useOrganisationsTagsIndex,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { queryKeyFn } from '@/api/openapiContext';
import { useDebounce } from '@/Hooks/useDebounce';
import { downloadCSV } from '@/utils';
import { useCopyTable, useTableSelection } from '@/Components/Table/hooks';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { DeleteDocumentsDialog } from './components/DeleteDocumentsDialog';
import { ShopifyExportDialog } from '../Document/components/ShopifyExportDialog/ShopifyExportDialog';
import { Icon } from '@/Components/v2/Icon';
import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { Button } from '@/Components/v2/Button';

export default function ContentCreated() {
  const appState = useAppStore();
  const tableState = ContentCreatedRoute.useSearch();
  const { selection, setSelection, resetSelection } =
    useTableSelection<DocumentResource>();
  const navigate = useNavigate({ from: ContentCreatedRoute.fullPath });

  const [documentsToBeDeleted, setDocumentsToBeDeleted] = React.useState<
    DocumentResource[]
  >([]);
  const [documentIdToBeDeleted, setDocumentIdToBeDeleted] =
    React.useState<number>();

  const { hasFeature } = useFeatureFlagging();
  const client = useQueryClient();
  const variables = {
    pathParams: { project: appState.currentProject!.id },
    queryParams: {
      ...tableState,
      limit: tableState?.limit ?? 25,
    },
  };

  const queryKey = queryKeyFn({
    path: '/projects/{project}/documents',
    operationId: 'listDocuments',
    variables,
  });

  const createDocumentMutation = useCreateDocument();
  const cmsConnectionQuery = useGetCmsConnection({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const {
    data: documents,
    isLoading: isLoadingDocuments,
    refetch: refetchDocuments,
  } = useListDocuments(variables);

  const exportDocumentsMutation = useExportDocuments();

  const { mutate: deleteDocument, isPending: isDeletingDocument } =
    useDeleteDocument({
      onSuccess: () => {
        client.invalidateQueries({
          queryKey,
        });
      },
    });

  const [showShopifyExportDialog, setShowShopifyExportDialog] =
    React.useState(false);
  const [tagsFilter, setTagsFilter] = React.useState('');
  const [initialTagFilter, setInitialTagFilter] = React.useState('');

  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => (item.title ? item.title : 'Untitled')).join('\n'),
  );

  useDebounce(
    () => {
      setTagsFilter(initialTagFilter);
    },
    [initialTagFilter],
    350,
  );

  const updateDocumentMutation = useUpdateDocument();
  const tagsQuery = useOrganisationsTagsIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      filters: tagsFilter === '' ? undefined : { search: tagsFilter },
    },
  });

  React.useEffect(() => {
    const url = `projects.${appState.currentProject!.id}`;
    const channel = window.Echo.private(url).listen(
      `DocumentUpdatedEvent`,
      async (doc: DocumentResource) => {
        const documentResponse = await fetchGetDocument({
          pathParams: {
            document: doc.id,
            project: appState.currentProject!.id,
          },
        });
        const document = documentResponse.data;
        if (selection.some((selected) => selected.id === document.id)) {
          setSelection([document], []);
        }
        client.setQueriesData(
          {
            predicate: (query) => query.queryKey.includes('listDocuments'),
          },
          (prev: any) => {
            return {
              ...prev,
              data: prev.data.map((item: DocumentResource) => {
                if (item.id === document.id) {
                  return document;
                }
                return item;
              }),
            };
          },
        );
      },
    );
    return () => {
      channel.stopListening(`DocumentUpdatedEvent`);
    };
  }, [selection]);

  const handleNewDocument = () => {
    createDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) =>
          navigate({
            to: '/documents/$documentId',
            params: { documentId: hasher.encode(data.data.id) },
          }),
      },
    );
  };

  const handleUpdateTags = (
    documentId: number,
    tags: OrganisationTagResource[],
  ) => {
    tags.sort((a, b) => a.name.localeCompare(b.name));

    const newTagIds = tags.map((tag) => tag.id).toString();
    const oldTagIds = (
      documents?.data
        ?.find((doc) => doc.id === documentId)
        ?.tags?.map((tag) => tag.id) ?? []
    ).toString();

    if (oldTagIds !== newTagIds) {
      updateDocumentMutation.mutate({
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
        body: {
          tags: tags.map((tag) => tag.id),
        },
      });
    }

    const updatedDocuments = documents?.data?.map((doc) => {
      if (doc.id === documentId) {
        return {
          ...doc,
          tags: tags,
        };
      }
      return doc;
    });
    client.setQueryData(queryKey, {
      data: updatedDocuments,
      meta: documents?.meta,
    });
    refetchDocuments();
  };

  appState.pageTitle('Documents');

  return (
    <Menus>
      <ShopifyExportDialog
        cmsConnection={cmsConnectionQuery.data?.data}
        documents={selection ?? []}
        isOpen={showShopifyExportDialog}
        onClose={() => {
          setShowShopifyExportDialog(false);
        }}
      />
      <DeleteDocumentsDialog
        key={
          documentsToBeDeleted.map((doc) => doc.id).join(',') +
          'deletedocuments'
        }
        documentsToBeDeleted={documentsToBeDeleted}
        onClose={() => setDocumentsToBeDeleted([])}
        onRemove={() => {
          resetSelection();
          refetchDocuments();
        }}
      />
      <ConfirmDialog
        title="Delete document"
        description={[
          'Are you sure you want to delete this document? It cannot be undone',
        ]}
        confirmColor="red"
        confirmText="delete"
        isOpen={!!documentIdToBeDeleted}
        onClose={() => setDocumentIdToBeDeleted(undefined)}
        onConfirm={() => {
          deleteDocument(
            {
              pathParams: {
                document: documentIdToBeDeleted!,
                project: appState.currentProject!.id,
              },
            },
            {
              onSuccess: () => {
                setDocumentIdToBeDeleted(undefined);
                refetchDocuments();
              },
            },
          );
        }}
        isLoading={isDeletingDocument}
      />
      <PageContainer title="Documents ">
        <Table.Root
          selection={selection}
          onSelectionChange={setSelection}
          isLoading={isLoadingDocuments}
          skeletonLoaders={10}
          meta={documents?.meta}
          sorting={tableState}
          onSortChange={({ sortBy, sortDirection }) => {
            navigate({
              search: (prev) => ({
                ...prev,
                sortBy: sortBy as ContentCreatedSearchParams,
                sortDirection,
              }),
            });
          }}
          items={documents?.data ?? []}
          columns={[
            {
              property: 'title',
              sortableHeader: true,
              heading: 'Title',
              render: (item) => (
                <div className="flex items-center">
                  <Link
                    to={`/documents/$documentId`}
                    params={{ documentId: hasher.encode(item.id) }}
                    state={(prev) => ({ ...prev, tableState })}
                    className={`pr-2 font-extrabold ${
                      item.title ? 'text-black' : 'text-gray-400'
                    } hover:opacity-60`}
                  >
                    <div className="flex items-center gap-2">
                      {item.is_generating && (
                        <Loader className="h-6 w-6 flex-shrink-0 stroke-green-500" />
                      )}
                      {item.title ? item.title : 'Untitled'}
                    </div>
                  </Link>
                </div>
              ),
            },
            {
              sortableHeader: 'keyword',
              heading: 'Target keyword',
              render: (item) => item.keyword?.name ?? '-',
            },
            {
              property: 'score',
              sortableHeader: 'score',
              heading: 'Seo score',
              rightAlign: true,
              render: (item) =>
                item.score ? (
                  <ScoreLabel
                    score={item.score}
                    color={
                      item.score >=
                      (item.document_report?.total_target_score ?? 0)
                        ? 'green'
                        : 'gray'
                    }
                  />
                ) : (
                  <div className="mr-3">-</div>
                ),
            },
            {
              property: 'word_count',
              rightAlign: true,
              sortableHeader: 'word_count',
              heading: 'Word count',
              render: (item) => <>{item.word_count}</>,
            },
            {
              heading: 'Tags',
              render: (item) => (
                <div className="w-[15vw] min-w-[8rem]">
                  <TagEditor
                    key={item.id}
                    onTagUpdated={(tags) => handleUpdateTags(item.id, tags)}
                    organisationId={appState.organisation!.id}
                    tags={item.tags ?? []}
                  />
                </div>
              ),
            },
            {
              render: (item) => (
                <PopoverMenu
                  trigger={<IconButton color="gray" icon={MoreVertical} />}
                  items={[
                    {
                      title: 'Delete',
                      onClick: () => {
                        setDocumentIdToBeDeleted(item.id);
                      },
                      prependIcon: Trash2,
                    },
                  ]}
                />
              ),
            },
            {
              render: (item) => (
                <div className="flex gap-2">
                  {item.project_url && (
                    <IconButton
                      icon={ExternalLink}
                      onClick={() => window.open(item.project_url?.url)}
                    />
                  )}
                  {hasFeature('shopify-cms-integration') &&
                    item.project_url?.cms.has_reference && (
                      <IconButton
                        icon={
                          <Icon
                            icon={faShopify}
                            className="h-[18px] w-[18px] text-primary"
                          />
                        }
                        onClick={() =>
                          window.open(item.project_url?.cms.admin_link)
                        }
                      />
                    )}
                </div>
              ),
            },
          ]}
        >
          <Table.Header
            csvExportOptions={{
              isLoading: exportDocumentsMutation.isPending,
              isSuccess: exportDocumentsMutation.isSuccess,
              onCloseDialog: () => exportDocumentsMutation.reset(),
              onClick: () => {
                exportDocumentsMutation.mutate({
                  body: {
                    filters: tableState.filters,
                    sort_by: tableState.sortBy,
                    sort_direction: tableState.sortDirection,
                  },
                  pathParams: { project: appState.currentProject!.id },
                });
              },
            }}
            search={tableState.filters?.search}
            onSearchChange={(value) =>
              navigate({
                search: (prev) => ({
                  ...prev,
                  page: 1,
                  filters: { ...prev.filters, search: value },
                }),
              })
            }
          >
            {selection.length !== 0 ? (
              <SelectionActions
                selection={selection}
                actions={[
                  {
                    size: 'sm',
                    dense: true,
                    prependIcon: Trash2,
                    text: 'Delete',
                    variant: 'ghost',
                    onClick: () => setDocumentsToBeDeleted(selection),
                  },
                  {
                    size: 'sm',
                    dense: true,
                    prependIcon: Copy,
                    text: 'Copy',
                    variant: 'ghost',
                    onClick: handleCopyToClipboard,
                  },
                  ...(cmsConnectionQuery.data?.data?.state === 'active' &&
                  hasFeature('shopify-cms-integration')
                    ? [
                        {
                          size: 'sm' as const,
                          dense: true,
                          prependIcon:
                            cmsConnectionQuery.data.data.type === 'shopify' ? (
                              <Icon
                                icon={faShopify}
                                color="primary"
                                size="xl"
                              />
                            ) : (
                              Upload
                            ),
                          text: `Export to ${cmsConnectionQuery.data.data.type ?? 'CMS'}`,
                          variant: 'ghost' as const,
                          onClick: () => setShowShopifyExportDialog(true),
                        },
                      ]
                    : []),
                ]}
              />
            ) : (
              <div className="flex gap-2">
                <Table.ResultsTotal
                  isLoading={isLoadingDocuments}
                  title="documents"
                  total={documents?.meta?.total}
                />
                <Table.FilterPopover
                  onRemoveFilter={() =>
                    navigate({
                      search: (prev) => ({
                        ...prev,
                        filters: { ...prev.filters, tags: undefined },
                      }),
                    })
                  }
                  name="Tags"
                  filterName={
                    (tableState.filters?.tags ?? []).length > 0
                      ? `Tags: ${tableState.filters
                          ?.tags!.map(
                            (tagId) =>
                              tagsQuery.data?.data.find(
                                (tag) => tag.id === tagId,
                              )?.name,
                          )
                          .map((name) =>
                            (name?.length ?? 0) > 15
                              ? name?.slice(0, 12) + '... '
                              : name,
                          )
                          .toString()}`
                      : undefined
                  }
                >
                  <MultipleItemSelector
                    filterValue={initialTagFilter}
                    onFilterValueChange={setInitialTagFilter}
                    items={(tagsQuery.data?.data ?? []).map((tag) => ({
                      id: tag.id,
                      item: (
                        <div className="max-w-[10rem] overflow-hidden">
                          <AppLabel
                            variant={
                              TagVariant[tag.color as keyof typeof TagVariant]
                            }
                          >
                            {tag.name}
                          </AppLabel>
                        </div>
                      ),
                    }))}
                    values={tableState.filters?.tags}
                    onChange={(tags) =>
                      navigate({
                        search: (prev) => ({
                          ...prev,
                          filters: { ...prev.filters, tags },
                        }),
                      })
                    }
                  >
                    <ToggleButton<'all' | 'any'>
                      options={[
                        { displayName: 'All', value: 'all' },
                        { displayName: 'Any', value: 'any' },
                      ]}
                      onChange={(value) => {
                        navigate({
                          search: (prev) => ({
                            ...prev,
                            filters: { ...prev.filters, tag_mode: value },
                          }),
                        });
                      }}
                      size="sm"
                      value={tableState.filters?.tag_mode ?? 'all'}
                      buttonClassName="py-0.5 w-full justify-center"
                    />
                  </MultipleItemSelector>
                </Table.FilterPopover>
              </div>
            )}
          </Table.Header>
          <Table.NoContent>
            <div className="flex flex-col items-center gap-4">
              <FileX2 size={128} className="text-primary-200" />
              <p className="text-lg font-bold text-primary">
                No created documents
              </p>
              <Button
                onClick={handleNewDocument}
                isLoading={
                  createDocumentMutation.isPending ||
                  createDocumentMutation.isSuccess
                }
                text="Create document"
                prependIcon={Plus}
                color="secondary"
                variant="outline"
              />
            </div>
          </Table.NoContent>
          <Table.Footer>
            <Pagination
              currentPage={tableState.page ?? 1}
              setCurrentPage={(currentPage) => {
                navigate({
                  search: (prev) => ({ ...prev, page: currentPage }),
                });
              }}
              lastPage={documents?.meta.last_page}
            />
          </Table.Footer>
        </Table.Root>
      </PageContainer>
    </Menus>
  );
}
