import { Tooltip, TooltipProps } from '@/Components/v2/Tooltip';
import { LucideProps } from 'lucide-react';

type Props = {
  title: string;
  icon: React.ComponentType<LucideProps>;
  description: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: Omit<TooltipProps, 'children'>;
};

export const ToolCard = ({
  description,
  icon,
  title,
  onClick,
  disabled,
  tooltip,
}: Props) => {
  const Icon = icon;

  return (
    <div
      className={`flex aspect-square w-full max-w-72  rounded-lg bg-white p-4 shadow ${disabled ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:opacity-60'}`}
      onClick={!disabled ? onClick : undefined}
    >
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Tooltip {...tooltip} disabled={!tooltip}>
          <div className="flex h-full w-full flex-col items-center justify-center gap-2">
            <p className=" text-center text-xl font-bold text-primary ">
              {title}
            </p>

            <Icon className={`h-[40%] w-[40%] text-primary-400`} />

            <p className="text-center text-xs text-primary">{description}</p>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
