import {
  useAutoGenerateFromProjectUrls,
  useListDocumentTemplates,
} from '@/api/openapiComponents';
import { OrganisationTagResource } from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { ErrorAlert, WarningAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { TagEditor } from '@/Pages/Document/components/TagEditor/TagEditor';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { TemplateSelect } from '@/TemplateSelect';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSucces: () => void;
  urls: [
    {
      id: number;
      has_document: boolean
      url: string;
    }
  ];
};

export const BulkGenerateDialog = ({
  isOpen,
  onClose,
  onSucces,
  urls,
}: Props) => {
  const appState = useAppStore();

  const [selectedTags, setSelectedTags] = useState<OrganisationTagResource[]>(
    [],
  );
  const [selectedTemplate, setSelectedTemplate] = useState<{
    contentTypeId: number;
    templateId: number | null;
  }>();

  const customTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      filters: {
        project_id: appState.currentProject!.id,
      },
      limit: 100,
    },
  });

  const customTemplates = customTemplatesQuery.data?.data ?? [];

  const predefinedTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      limit: 100,
    },
  });

  const predefinedTemplates = predefinedTemplatesQuery.data?.data ?? [];

  const bulkGenerateMutation = useAutoGenerateFromProjectUrls();

  const shouldShowTemplateWarning = urls.some((url) => url.has_document);
  const urlsWithWarning = urls.filter((url) => url.has_document);

  const handleGenerate = () => {
    bulkGenerateMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          document_template_id: selectedTemplate?.templateId!,
          project_urls: urls.map((url) => url.id),
          content_type_id: selectedTemplate?.contentTypeId,
          tags:
            selectedTags.length > 0
              ? selectedTags.map((tag) => tag.id)
              : undefined,
        },
      },
      {
        onSuccess: () => {
          onSucces();
          onClose();
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(bulkGenerateMutation.error);

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={onClose}
      title="Run template"
      subtitle={
        <>
          Select template for the <b>{urls.length}</b> URLs to use for bulk
          generation
        </>
      }
    >
      <div className="flex flex-col gap-4">
        {shouldShowTemplateWarning && (
          <WarningAlert
            title={`${urlsWithWarning.length} of the URLs already have a document. This will overwrite the existing documents on those URLs. Are you sure you want to continue? If not, please remove the URLs from the selected list, and try again.`}
            body={
              urlsWithWarning.map((url) => url.url)
            }
          />
        )}
        <TemplateSelect
          customTemplates={customTemplates}
          predefinedTemplates={predefinedTemplates}
          onChange={(values) =>
            setSelectedTemplate({
              contentTypeId: values.contentTypeId,
              templateId: values.templateId ? values.templateId : null,
            })
          }
          value={selectedTemplate?.templateId}
          disabled={bulkGenerateMutation.isPending}
          featureLocked={
            !appState.subscription?.permissions.custom_document_templates
          }
        />
        <InputDecoration label="Add tag to content">
          <div>
            <TagEditor
              onTagUpdated={setSelectedTags}
              organisationId={appState.organisation!.id}
              tags={selectedTags}
            />
          </div>
        </InputDecoration>
        {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}
        <div className="mt-2 flex justify-end">
          <Button
            text="Generate"
            onClick={handleGenerate}
            disabled={!selectedTemplate?.templateId}
            color="secondary"
            isLoading={bulkGenerateMutation.isPending}
          />
        </div>
      </div>
    </Dialog>
  );
};
