import { Stars, TriangleAlert } from 'lucide-react';
import { ReportSection } from '../ReportSection/components/ReportSection';
import { WorkflowCard } from './components/WorkflowCard';
import { useNavigate } from '@tanstack/react-router';
import { DocumentResource } from '@/api/openapiSchemas';
import {
  GetDocumentResponse,
  useExecuteWorkflow,
  useWorkflowsIndex,
} from '@/api/openapiComponents';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useQueryClient } from '@tanstack/react-query';
import { ScrollContainer } from '../Chat/components/ScrollContainer';

type Props = {
  document: DocumentResource;
  onSelectWorkflow: (id: number) => void;
};

export const Workflows = ({ document, onSelectWorkflow }: Props) => {
  const navigate = useNavigate();

  const workflowQuery = useWorkflowsIndex({});

  return (
    <ScrollContainer>
      <div className="flex w-full max-w-2xl flex-col gap-4 p-6">
        <ReportSection title="GENERATE NEW">
          <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] justify-items-center gap-4">
            <WorkflowCard
              title="Full content draft"
              icon={Stars}
              description="Uncover keyword gaps in your content based on Search Console data."
              onClick={() =>
                navigate({
                  to: '/create-content/ai-generated',
                  search: (prev) => ({
                    ...prev,
                    documentId: document.id,
                    keyword: document.keyword?.name,
                  }),
                })
              }
            />
            <div />
          </div>
        </ReportSection>
        <ReportSection
          title="CHANGE CURRENT"
          actions={
            <div className="flex items-center gap-1 rounded-md bg-yellow-50 p-1 text-xs italic text-yellow">
              <TriangleAlert size={14} />
              IN BETA
            </div>
          }
        >
          <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] justify-items-center gap-4">
            {workflowQuery.isLoading
              ? Array.from({ length: 4 }).map((_, i) => (
                  <div className="aspect-video" key={'loader' + i}>
                    <SkeletonLoader key={i} height="full" />
                  </div>
                ))
              : workflowQuery.data?.data.map((workflow) => (
                  <WorkflowCard
                    key={workflow.id}
                    description={workflow?.description ?? ''}
                    title={workflow.display_name}
                    onClick={() => onSelectWorkflow(workflow.id)}
                  />
                ))}
          </div>
        </ReportSection>
      </div>
    </ScrollContainer>
  );
};
