import { Collapsable } from './Collapsable';
import React from 'react';
import { TanStackSearchParams } from '@/types';
import { SidebarMenuItem } from './Sidebar/SidebarMenuItem';
import { useSidebarStore } from './Sidebar/stores';
import { useShallow } from 'zustand/react/shallow';

type Props<T> = {
  title: string;
  icon: React.ReactNode;
  items: {
    name: string;
    href: string;
    search?: TanStackSearchParams<T>;
    current: boolean;
    disabled?: boolean;
    isBeta?: boolean;
    tooltip?: {
      text: string;
      navLink?: string;
      navLinkText?: string;
    };
    icon: any;
  }[];
};
export const SidebarCollapsable = <T,>({ title, icon, items }: Props<T>) => {
  const { openMenu, navigationTitle } = useSidebarStore(
    useShallow((state) => ({
      openMenu: state.openMenu as (key: string) => void,
      navigationTitle: state[title] as boolean,
    })),
  );

  return (
    <li key={title}>
      <Collapsable
        onClick={() => openMenu(title)}
        paddingX="sm"
        defaultOpen={navigationTitle}
        fullWidth
        prependIcon={icon}
        title={
          <p
            className={`group ml-2 flex text-base ${navigationTitle ? 'font-semibold' : 'font-semibold'} leading-6`}
          >
            {title}
          </p>
        }
      >
        {items.map((item, i, array) => {
          return (
            <SidebarMenuItem
              isSubItem
              item={item}
              key={item.name}
              isLast={i + 1 === array.length}
              isBeta={item.isBeta}
            />
          );
        })}
      </Collapsable>
    </li>
  );
};
