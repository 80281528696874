import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import * as Table from '@/Components/Table';
import {
  RangeSelector,
  formatRange,
} from '@/Components/Table/components/RangeSelector';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useCopyTable, useTableSelection } from '@/Components/Table/hooks';
import { Button } from '@/Components/v2/Button';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import {
  ChevronDown,
  Copy,
  FileText,
  Globe,
  LineChart,
  Plus,
  RefreshCcw,
  Scan,
  Search,
  Settings,
  Stars,
} from 'lucide-react';
import { useAppStore } from '../AppLoader/stores';
import {
  fetchListUrlKeywords,
  useExportProjectUrls,
  useProjectImportUrls,
  useProjectsUrlsIndex,
} from '@/api/openapiComponents';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useEffect, useState } from 'react';
import { useConnectToGoogleSearchConsole } from '../Settings/hooks';
import { URLDialog } from './components/URLDialog';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { downloadCSV, formatThousandSeperator } from '@/utils';
import { GoogleConsoleConnect } from '@/Components/Table/components/GoogleConsoleConnect';
import { useGoogleLogin } from '@react-oauth/google';
import { useShallow } from 'zustand/react/shallow';
import { OutOfUrlCreditsBanner } from '@/Components/Banner/specific/OutOfUrlCreditsBanner';
import {
  ProjectUrlResource,
  ScannedContentResource,
  ScannedContentSubResource,
} from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { useAllPageActions } from './hooks';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { BulkGenerateDialog } from './components/BulkGenerateDialog';
import { WarningAlert } from '@/Components/v2/Alert';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { hasher, useFeatureFlagging } from '../AppLoader';
import { Icon } from '@/Components/v2/Icon';
import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { AnimatedEllipsis } from '@/Components/AnimatedEllipsis';
import { Tooltip } from '@/Components/v2/Tooltip';
import { RankLabel } from '@/Components/Labels/RankLabel';
import { getRankTrackingVariant } from '../RankTracking/RankTracking';

const dummyData = [
  {
    id: -1,
    url: '/seo/keyword-research/basics',
    title: 'Mastering Keyword Research: Basics',
    clicks: 150,
    top_keyword: 'Keyword Research',
    content_length: 1400,
  },
  {
    id: -2,
    url: '/seo/on-page-optimization/guide',
    title: 'On-Page Optimization: The Ultimate Guide',
    clicks: 200,
    top_keyword: 'On-Page SEO',
    content_length: 1900,
  },
  {
    id: -3,
    url: '/seo/link-building/strategies',
    title: 'Effective Link Building Strategies for 2024',
    clicks: 180,
    top_keyword: 'Link Building',
    content_length: 2200,
  },
  {
    id: -4,
    url: '/seo/local-seo/ranking-factors',
    title: 'Top Local SEO Ranking Factors',
    clicks: 95,
    top_keyword: 'Local SEO',
    content_length: 1700,
  },
  {
    id: -5,
    url: '/seo/meta-tags/importance',
    title: 'The Importance of Meta Tags in SEO',
    clicks: 110,
    top_keyword: 'Meta Tags',
    content_length: 1200,
  },
  {
    id: -6,
    url: '/seo/technical-seo/checklist',
    title: 'Technical SEO Checklist for Beginners',
    clicks: 135,
    top_keyword: 'Technical SEO',
    content_length: 1850,
  },
  {
    id: -7,
    url: '/seo/website-speed/optimization',
    title: 'How Website Speed Impacts SEO',
    clicks: 165,
    top_keyword: 'Website Speed',
    content_length: 1500,
  },
  {
    id: -8,
    url: '/seo/google-algorithm/updates',
    title: 'Navigating Google Algorithm Updates',
    clicks: 210,
    top_keyword: 'Google Algorithm',
    content_length: 2300,
  },
  {
    id: -9,
    url: '/seo/mobile-seo/best-practices',
    title: 'Best Practices for Mobile SEO',
    clicks: 175,
    top_keyword: 'Mobile SEO',
    content_length: 1600,
  },
  {
    id: -10,
    url: '/seo/content-marketing/seo-strategy',
    title: 'How Content Marketing Supports SEO Strategy',
    clicks: 145,
    top_keyword: 'Content Marketing',
    content_length: 2000,
  },
  {
    id: -11,
    url: '/seo/image-optimization/tips',
    title: 'SEO Image Optimization Tips',
    clicks: 130,
    top_keyword: 'Image Optimization',
    content_length: 1350,
  },
  {
    id: -12,
    url: '/seo/voice-search/optimization',
    title: 'Optimizing for Voice Search in 2024',
    clicks: 185,
    top_keyword: 'Voice Search',
    content_length: 1800,
  },
  {
    id: -13,
    url: '/seo/rich-snippets/guide',
    title: 'A Guide to Rich Snippets for SEO',
    clicks: 115,
    top_keyword: 'Rich Snippets',
    content_length: 1250,
  },
  {
    id: -14,
    url: '/seo/user-experience/impact',
    title: 'How User Experience (UX) Impacts SEO',
    clicks: 145,
    top_keyword: 'User Experience',
    content_length: 2100,
  },
  {
    id: -15,
    url: '/seo/e-commerce/seo-tips',
    title: 'E-commerce SEO Tips to Increase Sales',
    clicks: 195,
    top_keyword: 'E-commerce SEO',
    content_length: 1900,
  },
  {
    id: -16,
    url: '/seo/international-seo/best-practices',
    title: 'Best Practices for International SEO',
    clicks: 170,
    top_keyword: 'International SEO',
    content_length: 1750,
  },
  {
    id: -17,
    url: '/seo/backlink-analysis/tools',
    title: 'Top Tools for Backlink Analysis',
    clicks: 100,
    top_keyword: 'Backlink Analysis',
    content_length: 1650,
  },
  {
    id: -18,
    url: '/seo/featured-snippets/optimization',
    title: 'Optimizing for Featured Snippets',
    clicks: 205,
    top_keyword: 'Featured Snippets',
    content_length: 1950,
  },
  {
    id: -19,
    url: '/seo/schema-markup/basics',
    title: 'The Basics of Schema Markup for SEO',
    clicks: 160,
    top_keyword: 'Schema Markup',
    content_length: 1500,
  },
  {
    id: -20,
    url: '/seo/page-experience/update',
    title: "Understanding Google's Page Experience Update",
    clicks: 220,
    top_keyword: 'Page Experience',
    content_length: 2400,
  },
];

export const AllPages = () => {
  const appState = useAppStore();
  const client = useQueryClient();
  const [sitemapSettingsDialog, setSitemapSettingsDialog] = useState(false);
  const navigate = useNavigate();
  const { hasFeature } = useFeatureFlagging();
  const { projectId, pageTitle, refreshCurrentProject, currentProject } =
    useAppStore(
      useShallow((state) => ({
        projectId: state.currentProject!.id,
        currentProject: state.currentProject,
        pageTitle: state.pageTitle,
        refreshCurrentProject: state.refreshCurrentProject,
      })),
    );
  const search = useSearch({ from: '/all-pages' });
  const { selection, setSelection } = useTableSelection<ProjectUrlResource>();
  const [isScanning, setIsScanning] = useState(!!currentProject!.is_crawling);
  const [showBulkGenerateDialog, setShowBulkGenerateDialog] = useState(false);

  const [showSyncConfirmDialog, setShowSyncConfirmDialog] = useState(false);

  const variables = {
    pathParams: {
      project: projectId,
    },
  };

  const gscHookProps = useConnectToGoogleSearchConsole(projectId);
  const isGoogleSearchConsoleActive =
    gscHookProps.googleSearchConsoleData?.data.state === 'active';

  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => (item.url ? item.url : '')).join('\n'),
  );

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      gscHookProps.setupGoogleConsole({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const sitemapSyncMutation = useProjectImportUrls();

  const { mutate: exportProjectUrls, isPending: isLoadingProjectUrlExport } =
    useExportProjectUrls({
      onSuccess(data) {
        downloadCSV(data, 'all_pages.csv');
      },
    });

  const {
    data: urls,
    isLoading: isLoadingListUrls,
    isFetching: isFetchingListUrls,
    refetch: refetchProjectUrls,
  } = useProjectsUrlsIndex({
    ...variables,
    queryParams: {
      limit: isGoogleSearchConsoleActive ? 100 : 5,
      sort_by: search.sortBy,
      sort_direction: search.sortDirection,
      page: search.page,
      filters: { ...search.filters, search: search.filters?.search },
    },
  });

  const injectSubrowData = (id: number, data: ScannedContentSubResource) => {
    client.setQueriesData(
      {
        predicate: (query) =>
          query.queryKey.includes('listUrls') ||
          query.queryKey.includes('projectsUrlsIndex'),
      },
      (prev: any) => {
        return {
          ...prev,
          data: prev.data.map((item: ScannedContentResource) => {
            if (item.id === id) {
              return {
                ...item,
                ...(data ?? {}),
              };
            }
            return item;
          }),
        };
      },
    );
  };

  useEffect(() => {
    const singleScanChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('ProjectUrlUpdated', (projectUrl: ProjectUrlResource) => {
      if (
        urls?.data.some((item) => {
          return item.id === projectUrl.id;
        })
      ) {
        injectSubrowData(projectUrl.id, projectUrl);
      }
    });

    return () => {
      singleScanChannel.stopListening('ProjectUrlUpdated');
    };
  }, [urls?.data]);

  const isLoadingTable = () => {
    if (isGoogleSearchConsoleActive) {
      return isLoadingListUrls;
    }
    return gscHookProps.isGettingGoogleSearchConsoleData;
  };

  const handleSitemapSync = () => {
    setIsScanning(true);
    refreshCurrentProject();
    client.setQueriesData(
      { predicate: (query) => query.queryKey.includes('projectsUrlsIndex') },
      (prev: any) => {
        return {
          ...prev,
          data: [],
        };
      },
    );
    setShowSyncConfirmDialog(false);
    sitemapSyncMutation.mutate({
      pathParams: {
        project: projectId,
      },
    });
  };

  useEffect(() => {
    const callBack = () => {
      setIsScanning(false);
      refetchProjectUrls();
      refreshCurrentProject();
    };

    const channel = window.Echo.private(`projects.${projectId}`).listen(
      '.ImportCompleted',
      callBack,
    );

    return () => {
      channel.stopListening('.ImportCompleted');
    };
  }, []);

  const items: ProjectUrlResource[] = isGoogleSearchConsoleActive
    ? (urls?.data ?? [])
    : [
        ...(urls?.data ?? []),
        ...(dummyData as unknown as ProjectUrlResource[]),
      ];

  const { handleImport, isLoadingForId } = useAllPageActions();

  pageTitle('Pages');
  return (
    <>
      <ConfirmDialog
        title="Are you sure you want to sync the sitemap?"
        isOpen={showSyncConfirmDialog}
        onClose={() => setShowSyncConfirmDialog(false)}
        onConfirm={handleSitemapSync}
      />
      <URLDialog
        setSitemapSettingsDialog={setSitemapSettingsDialog}
        sitemapSettingsDialog={sitemapSettingsDialog}
        refetchProjectUrls={() => {
          refetchProjectUrls();
        }}
      />
      <BulkGenerateDialog
        urls={selection.map((item) => {
          return {
            id: item.id,
            has_document: !!item.has_document,
            url: item.path ?? item.url,
          };
        })}
        isOpen={showBulkGenerateDialog}
        onClose={() => setShowBulkGenerateDialog(false)}
        onSucces={() => {
          navigate({ to: '/created-content' });
        }}
      />
      <Menus additionalBanners={<OutOfUrlCreditsBanner />}>
        <PageContainer
          title="Pages"
          actions={
            <div className="flex justify-between">
              <div className="flex">
                <Tooltip
                  title="Sitemap is currently being synced"
                  side="bottom"
                  disabled={!isScanning}
                >
                  <Button
                    disabled={!isGoogleSearchConsoleActive || isScanning}
                    text="Sitemap sync"
                    prependIcon={RefreshCcw}
                    variant="ghost"
                    onClick={() => setShowSyncConfirmDialog(true)}
                  />
                </Tooltip>
                <Button
                  disabled={!isGoogleSearchConsoleActive}
                  text="Sitemap settings"
                  prependIcon={Settings}
                  variant="ghost"
                  onClick={() => setSitemapSettingsDialog(true)}
                />
              </div>
            </div>
          }
        >
          <p className="my-4">
            The domain’s pages and Google key metrics from the last 28 days in
            the local country.{' '}
            <a
              href="https://docs.seo.ai/a-38-all-pages"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Read more
            </a>
            .
          </p>
          {appState.subscription?.is_trialing && (
            <WarningAlert title="Only 10 URLs are imported in trial version" />
          )}
          <Table.Root
            disabled={!isGoogleSearchConsoleActive}
            isLoading={isLoadingTable()}
            selection={isGoogleSearchConsoleActive ? selection : undefined}
            items={items}
            renderRow={({ index, cells, classNames }) => (
              <tr
                className={`${
                  index > (urls?.data?.length ?? 0) - 1 &&
                  !isGoogleSearchConsoleActive
                    ? 'pointer-events-none blur'
                    : ''
                } ${classNames}`}
              >
                {cells}
              </tr>
            )}
            columns={[
              {
                heading: 'URL',
                property: 'url',
                sortableHeader: true,
                render: (item) => (
                  <div>
                    <a
                      title={item.url ?? ''}
                      href={item.url ?? ''}
                      target="_blank"
                      className="inline max-w-[350px] break-all hover:opacity-50"
                      rel="noreferrer"
                    >
                      {item.path ?? item.url}
                    </a>
                  </div>
                ),
              },
              {
                heading: 'CLICKS',
                rightAlign: true,
                property: 'clicks',
                sortableHeader: true,
                render: (item) =>
                  !isGoogleSearchConsoleActive ? (
                    <div className="relative -left-8 -top-2.5 text-primary">
                      <div className="absolute flex">
                        <p
                          className="cursor-pointer italic underline"
                          onClick={login}
                        >
                          Connect
                        </p>
                      </div>
                    </div>
                  ) : (item.is_scanning || item.is_single_scan_loading) &&
                    item.clicks !== null ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <div>
                      {item.top_keyword
                        ? formatThousandSeperator(item.clicks ?? 0)
                        : '-'}
                    </div>
                  ),
              },
              {
                heading: 'POSITION',
                property: 'average_position',
                rightAlign: true,
                sortableHeader: true,
                render: (item) =>
                  !isGoogleSearchConsoleActive ? (
                    <div className="relative -top-2.5 left-0 text-primary">
                      <div className="absolute flex w-[8rem]">
                        <p className="w-full italic">Search Console</p>
                      </div>
                    </div>
                  ) : item.is_scanning ||
                    (item.is_single_scan_loading &&
                      item.average_position !== undefined) ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <div className={item.average_position ? 'mr-2' : 'mr-5'}>
                      {item.average_position ? (
                        <RankLabel
                          variant={getRankTrackingVariant(
                            Math.floor(item.average_position),
                          )}
                          rank={Math.floor(item.average_position)}
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  ),
              },
              {
                heading: 'TOP KEYWORD',
                property: 'top_keyword',
                sortableHeader: true,
                render: (item) =>
                  !isGoogleSearchConsoleActive ? (
                    <div className="relative -top-2.5 left-0 text-primary">
                      <div className="absolute flex w-[8rem]">
                        <p className="w-full italic">Search Console</p>
                      </div>
                    </div>
                  ) : (item.is_scanning || item.is_single_scan_loading) &&
                    !item.top_keyword ? (
                    <SkeletonLoader height="2xs" />
                  ) : (
                    <div>{item.top_keyword ?? '-'}</div>
                  ),
              },
              {
                render: (item) => {
                  return (
                    <Button
                      text="Edit"
                      variant="outline"
                      dense
                      isLoading={isLoadingForId === item.id}
                      size="sm"
                      onClick={() => {
                        if (item.document) {
                          navigate({
                            to: '/documents/$documentId',
                            params: {
                              documentId: hasher.encode(item.document.id),
                            },
                          });
                        } else {
                          handleImport(
                            item,
                            item.primary_keyword?.name || item.top_keyword,
                          );
                        }
                      }}
                    />
                  );
                },
              },
              {
                render: (item) => {
                  if (!hasFeature('shopify-cms-integration')) return null;
                  return (
                    <div className="flex items-center gap-2">
                      {item.cms?.has_reference && (
                        <IconButton
                          icon={
                            <Icon
                              icon={faShopify}
                              className="h-[18px] w-[18px] text-primary"
                            />
                          }
                          onClick={() => window.open(item.cms.admin_link)}
                        />
                      )}
                      {item.document && (
                        <IconButton
                          icon={FileText}
                          onClick={() => {
                            navigate({
                              to: '/documents/$documentId',
                              params: {
                                documentId: hasher.encode(item.document!.id),
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                },
              },
            ]}
            onSelectionChange={
              isGoogleSearchConsoleActive ? setSelection : undefined
            }
            meta={urls?.meta}
            overlay={
              gscHookProps && (
                <GoogleConsoleConnect
                  {...gscHookProps}
                  page="all pages"
                  message="Get all your URLs and its content into this view."
                  onConnect={() => setIsScanning(true)}
                />
              )
            }
            sorting={search}
            disableFrontendSorting
            onSortChange={({ sortBy, sortDirection }) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  page: 1,
                  sortBy: sortBy,
                  sortDirection,
                }),
              });
            }}
          >
            <Table.Header
              disabled={!isGoogleSearchConsoleActive}
              csvExportOptions={{
                isLoading: isLoadingProjectUrlExport,
                onClick: () => {
                  exportProjectUrls({
                    pathParams: { project: projectId },
                    body: {
                      filters: {
                        search: search.filters?.search,
                        is_active: search.filters?.is_active,
                        clicks_min: search.filters?.clicks_min,
                        clicks_max: search.filters?.clicks_max,
                        content_length_min: search.filters?.content_length_min,
                        content_length_max: search.filters?.content_length_max,
                      },
                      sort_by: search.sortBy,
                      sort_direction: search.sortDirection,
                    },
                  });
                },
              }}
              onSearchChange={(value) => {
                navigate({
                  search: {
                    ...search,
                    page: 1,
                    filters: {
                      ...search.filters,
                      search: value,
                    },
                  },
                });
              }}
            >
              {selection.length > 0 ? (
                <SelectionActions
                  selection={selection}
                  actions={[
                    {
                      size: 'sm',
                      dense: true,
                      prependIcon: Copy,
                      text: 'Copy',
                      variant: 'ghost',
                      onClick: handleCopyToClipboard,
                    },
                    {
                      size: 'sm',
                      dense: true,
                      prependIcon: Stars,
                      text: 'Run template',
                      variant: 'ghost',
                      onClick: () => setShowBulkGenerateDialog(true),
                    },
                  ]}
                />
              ) : (
                <>
                  <Table.ResultsTotal
                    isLoading={isLoadingListUrls}
                    title="URLs"
                    total={urls?.meta.total}
                  />
                  <Table.FilterPopover
                    disabled={!isGoogleSearchConsoleActive}
                    name="Clicks"
                    onRemoveFilter={() => {
                      navigate({
                        search: {
                          ...search,
                          page: 1,
                          filters: {
                            ...search.filters,
                            clicks_min: undefined,
                            clicks_max: undefined,
                          },
                        },
                      });
                    }}
                    filterName={formatRange({
                      max: search.filters?.clicks_max,
                      min: search.filters?.clicks_min,
                      name: 'Clicks',
                    })}
                  >
                    <RangeSelector
                      onAccept={(values) => {
                        navigate({
                          search: {
                            ...search,
                            page: 1,
                            filters: {
                              ...search.filters,
                              clicks_min: values.min,
                              clicks_max: values.max,
                            },
                          },
                        });
                      }}
                      values={{
                        max: search.filters?.clicks_max,
                        min: search.filters?.clicks_min,
                      }}
                    />
                  </Table.FilterPopover>
                  <Table.FilterPopover
                    disabled={!isGoogleSearchConsoleActive}
                    name="Position"
                    onRemoveFilter={() => {
                      navigate({
                        search: {
                          ...search,
                          page: 1,
                          filters: {
                            ...search.filters,
                            position_min: undefined,
                            position_max: undefined,
                          },
                        },
                      });
                    }}
                    filterName={formatRange({
                      max: search.filters?.position_max,
                      min: search.filters?.position_min,
                      name: 'Position',
                    })}
                  >
                    <RangeSelector
                      onAccept={(values) => {
                        navigate({
                          search: {
                            ...search,
                            page: 1,
                            filters: {
                              ...search.filters,
                              position_min: values.min,
                              position_max: values.max,
                            },
                          },
                        });
                      }}
                      values={{
                        max: search.filters?.position_max,
                        min: search.filters?.position_min,
                      }}
                    />
                  </Table.FilterPopover>
                </>
              )}
            </Table.Header>
            <Table.NoContent>
              <div className="flex flex-col items-center gap-4">
                {isScanning ? (
                  <>
                    <Search size={128} className="text-primary-200" />
                    <p className="text-lg font-bold text-primary">
                      Scanning
                      <AnimatedEllipsis />
                    </p>
                  </>
                ) : (
                  !isFetchingListUrls && (
                    <>
                      <LineChart size={128} className="text-primary-200" />
                      <p className="text-lg font-bold text-primary">
                        No results
                      </p>
                    </>
                  )
                )}
              </div>
            </Table.NoContent>
            <Table.Footer>
              {(urls?.data.length ?? 0) > 0 && (
                <Pagination
                  disabled={!isGoogleSearchConsoleActive}
                  currentPage={search.page ?? 1}
                  setCurrentPage={(page) =>
                    navigate({ search: { ...search, page } })
                  }
                  lastPage={urls?.meta.last_page}
                />
              )}
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
