import {
  getInitialPlateJSValue,
  hasNoTextMarked,
  isMarkAllText,
} from '@/Pages/Document/utils';
import { Plate, PlateContent, PlateEditor } from '@udecode/plate-common';
import { plugins } from '../lib/plugins';
import { useMemo, useState } from 'react';
import { BaseEditor, Range, Text, Editor as EditorInstance } from 'slate';
import { DefaultLeaf, ReactEditor } from 'slate-react';
import { createUniqueIdentifier } from '../lib/transform';
import { useDebounce } from '@/Hooks/useDebounce';
import { cn } from '../lib/utils';
import { serializeToMarkdown } from '../lib/serialize';
import { editorVariants } from './editorV2';
import { FloatingToolbar, FloatingToolbarButtons } from '../components';
import { SectionHeader } from './section-header';
import { useCurrentEditorState } from '../../EditorStateProvider';

type Props = {
  title: string;
  initialValue: string;
  onChange: (value: string) => void;
};

export const CustomField = ({ initialValue, title, onChange }: Props) => {
  const { setCurrentEditor, setSelection } = useCurrentEditorState();
  const [editor, setEditor] = useState<PlateEditor | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [children, setChildren] = useState(
    getInitialPlateJSValue(initialValue),
  );

  const key = useMemo(() => {
    return createUniqueIdentifier() + 'custom-field';
  }, []);

  useDebounce(
    () => {
      onChange(serializeToMarkdown(children));
    },
    children,
    500,
  );

  const highlightText = () => {
    if (editor && editor.selection) {
      if (hasNoTextMarked(editor)) {
        setSelection(null);
        return;
      }
      const editorSelection = editor.selection;
      const currentSelection = editor.getFragment();
      if (isMarkAllText(editor)) {
        setSelection({
          highlightedSelection: editorSelection,
          selected: {
            text: serializeToMarkdown(currentSelection),
            showInChat: true,
          },
        });
        return;
      }
      setSelection({
        highlightedSelection: editorSelection,
        selected: {
          text: serializeToMarkdown(currentSelection),
          showInChat: true,
        },
      });
    }
  };

  return (
    <>
      <div className="pl-5">
        <SectionHeader>{title}</SectionHeader>
      </div>
      <Plate
        key={key}
        id={key}
        value={children}
        plugins={plugins}
        editorRef={setEditor}
        onChange={(value) => {
          setChildren(value);
          if (editor?.selection) {
            highlightText();
          }
        }}
        decorate={([node, path]) => {
          const ranges: any = [];

          if (Text.isText(node) && editor && editor.selection) {
            const intersection = Range.intersection(
              editor.selection,
              EditorInstance.range(editor as BaseEditor, path),
            );
            if (intersection === null) {
              return ranges;
            }

            const range = {
              highlighted: true,
              ...intersection,
            };
            ranges.push(range);
          }

          return ranges;
        }}
        renderLeaf={(props) => {
          if (props.leaf.highlighted) {
            return (
              <span
                {...props.attributes}
                className="bg-green-200 selection:bg-green-200"
              >
                {props.children}
              </span>
            );
          }
          return (
            <span className="selection:bg-green-200">
              <DefaultLeaf {...props} />
            </span>
          );
        }}
      >
        <PlateContent
          placeholder={isFocused ? undefined : 'Write something...'}
          renderEditable={(props) => <div>{props}</div>}
          onFocus={() => {
            setIsFocused(true);
            setCurrentEditor(editor);
          }}
          onBlur={() => setIsFocused(false)}
          className={cn(
            editorVariants({
              focused: false,
              focusRing: false,
              size: 'md',
              variant: 'ghost',
            }),
            'overflow-hidden px-0',
            'min-h-24',
          )}
          disableDefaultStyles
        />
        <FloatingToolbar>
          <FloatingToolbarButtons />
        </FloatingToolbar>
      </Plate>
      <div className="h-10" />
    </>
  );
};
