import * as React from 'react';
import AppLabel, { Variant } from '../../../../Components/Labels/AppLabel';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useShallow } from 'zustand/react/shallow';
import TextAnimation from '@/Components/TextAnimation';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { MoreVertical } from 'lucide-react';
import { BrandVoiceResource } from '@/api/openapiSchemas';

type Props = {
  bgColor?: string;
  brandVoice: BrandVoiceResource;
  description?: string;
  isCustomBrandVoice?: boolean;
  onDelete?: (brandVoice: BrandVoiceResource) => void;
  handleOpenDialog?: (
    mode: "ADD" | "EDIT" | "EDIT_NAME",
    body?: {
      nameInput: string;
      id: number;
      urls: string[];
      promptInput: string | null;
      activeTab: number;
      disabledTab: number
    },
  ) => void;
  onDefault: (BrandVoice: BrandVoiceResource) => void;
};
const BrandVoiceCard = ({
  bgColor,
  description,
  isCustomBrandVoice,
  brandVoice,
  handleOpenDialog,
  onDelete,
  onDefault
}: Props) => {
  const [active, setActive] = React.useState(false);
  const  currentProject  = useAppStore(
    useShallow((state) => state.currentProject),
  );

  const isDefault = currentProject?.default_brand_voice?.id === brandVoice.id;

  const isTraining = brandVoice.status === 'in_progress';

  const isFailed = brandVoice.status === 'failed';

  const handleAdjustTraining = () => {
    handleOpenDialog &&
      handleOpenDialog('EDIT', {
        nameInput: brandVoice.name,
        id: brandVoice.id,
        urls: brandVoice?.urls ?? [],
        promptInput: brandVoice.prompt,
        activeTab: brandVoice.urls.length > 0 ? 0 : 1,
        disabledTab: brandVoice.urls.length === 0 ? 1 : 0,
      });
  };

  const handleEditName = () => {
    handleOpenDialog &&
      handleOpenDialog('EDIT_NAME', {
        id: brandVoice.id,
        nameInput: brandVoice.name,
        promptInput: brandVoice.prompt,
        urls: brandVoice?.urls ?? [],
        activeTab: brandVoice.urls.length > 0 ? 0 : 1,
        disabledTab: brandVoice.urls.length === 0 ? 1 : 0,
      });
  };

  return (
    <div
      className={`flex flex-col rounded-lg p-4 px-6 ${
        bgColor ? bgColor : ''
      } border shadow-xl`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {isDefault && (
            <div className="mb-2">
              <AppLabel variant={Variant.green}>Default</AppLabel>
            </div>
          )}
          {isTraining && (
            <div className="mb-2">
              <AppLabel variant={Variant.yellow}>
                <div className="w-[3.6rem]">
                  <TextAnimation>Training</TextAnimation>
                </div>
              </AppLabel>
            </div>
          )}
          {isFailed && (
            <div className="mb-2">
              <AppLabel variant={Variant.red}>Failed</AppLabel>
            </div>
          )}
        </div>
        {(!isDefault || isCustomBrandVoice) && (
          <PopoverMenu
            isOpen={active}
            onClose={() => setActive(false)}

            trigger={
              <IconButton
                icon={MoreVertical}
                onClick={() => setActive(!active)}
              />
            }
            items={[
              {
                title: 'Set as default',
                onClick: () => onDefault(brandVoice),
                disabled: isFailed || isTraining || isDefault
              },
              ...(isCustomBrandVoice
                ? [
                  {
                    title: brandVoice.urls.length > 0 ? 'Adjust Training' : 'Adjust Prompt',
                    onClick: handleAdjustTraining,
                    disabled: isTraining
                  },
                  {
                    title: 'Edit name',
                    onClick: handleEditName
                  },
                  {
                    title: 'Delete voice',
                    onClick: () => {
                      if (!onDelete) {
                        return;
                      }

                      onDelete(brandVoice);
                    },
                    disabled: isDefault
                  }
                ]
                : [])
            ]}
          />
        )}
      </div>
      <h1 className="mb-2 text-lg font-extrabold">{brandVoice.name}</h1>
      <p className="pb-12 text-gray-500">
        {description && description}
        {!description && (
          brandVoice.status === 'in_progress' ? (
            <>
              Training <TextAnimation />
            </>
          ) : brandVoice.status === 'active' ? (
            <div className="flex">
              {brandVoice.prompt_meta?.word_count && (
                <>
                  Trained{' '}{brandVoice.prompt_meta?.word_count}{' '}on words
                </>
              )}
              {brandVoice.prompt?.length > 0 &&
                brandVoice.prompt_meta === null && (
                  <>
                    {brandVoice.prompt?.length > 57
                      ? brandVoice.prompt?.slice(0, 57) + '...'
                      : brandVoice.prompt}
                  </>
                )}
            </div>
          ) : brandVoice.status === 'failed' ? (
            ''
          ) : (
            ''
          )

        )
      }
      </p>
    </div>
);
};

BrandVoiceCard.displayName = 'BrandVoiceCard';

export { BrandVoiceCard };
