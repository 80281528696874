import ReactMarkdown from 'react-markdown';
import * as Diff from 'diff';
import remarkGfm from 'remark-gfm';
import remarkIns from 'remark-ins';
import { ChangePopover } from './ChangePopover';
import { handleAccept, handleReject } from '../util';

type Props = {
  oldMarkdown: string;
  newMarkdown: string;
  onChange: (values: { actualValue?: string; newValue?: string }) => void;
};

const indexToken = '[index]';
const specialStartChars = ['#', '##', '###', '####', '#####', '######', '-'];

//TODO: add editable container

export const MarkdownDiff = ({ newMarkdown, onChange, oldMarkdown }: Props) => {
  const tokenize = (str: string) => {
    const lines = str.split('\n');
    const tokens = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const words = line.split(' ');

      for (let j = 0; j < words.length; j++) {
        const word = words[j];
        tokens.push(word);
      }
      tokens.push('\n');
    }

    return tokens;
  };

  const oldTokens = tokenize(oldMarkdown);
  const newTokens = tokenize(newMarkdown);

  const diffs = Diff.diffArrays(oldTokens, newTokens, { ignoreCase: true });

  const getStartChars = (value: string) => {
    const firstToken = value.split(' ')[0];

    if (specialStartChars.includes(firstToken)) {
      return firstToken;
    }
    return '';
  };

  const markdown = diffs
    .map((part, index) => {
      if (part.removed && diffs[index + 1]?.added) return null;
      let value = part.value.join(' ');
      const newlineCount = value.split('\n').length - 1;
      const startChars = getStartChars(value);

      if (startChars.length > 0 && (part.added || part.removed)) {
        value = value.slice(startChars.length);
      }

      return part.added
        ? `${startChars} ++${value
            .replaceAll('\n', '')
            .trim()}${indexToken}${index}++ ${'\n'.repeat(newlineCount)}`
        : part.removed
          ? `${startChars} ~~${value
              .replaceAll('\n', '')
              .trim()}${indexToken}${index}~~ ${'\n'.repeat(newlineCount)}`
          : ` ${value} `;
    })
    .filter((part) => part !== null)
    .join('');

  const transformDiffs = (diffs: { value: string[] }[]) => {
    return diffs.map((diff, index) => {
      const values = diff.value.filter((value) => value !== '');
      return {
        ...diff,
        value: values
          .map((value, valueIndex) => {
            if (value === '\n') {
              return value;
            }
            if (values[valueIndex + 1] === '\n') {
              return value;
            }
            if (
              diffs[index + 1]?.value[0] === '\n' &&
              valueIndex === values.length - 1
            ) {
              return value;
            }
            return `${value} `;
          })
          .join(''),
      };
    }) as Diff.Change[];
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkIns]}
      className="prose text-base leading-tight"
      components={{
        ins: ({ children }) => {
          const child = children;

          let newText;
          let oldText;
          let index = 0;
          if (typeof child === 'string') {
            newText = child.split(indexToken)[0];
            index = Number(child.split(indexToken)[1]);
            oldText = diffs[index - 1].removed
              ? (diffs[index - 1]?.value.join(' ') ?? '')
              : undefined;
          }

          if (Array.isArray(child)) {
            const index = child.findIndex(
              (c) => typeof c === 'string' && c.includes(indexToken),
            );

            const modifiedChild = child.map((c) =>
              typeof c === 'string' ? c.replaceAll(indexToken, '') : c,
            );
            newText = modifiedChild.slice(0, index);
            oldText = modifiedChild.slice(index);
          }

          return child ? (
            <ChangePopover
              newText={newText}
              oldText={oldText}
              onAccept={() =>
                onChange(handleAccept(transformDiffs(diffs), index))
              }
              onReject={() =>
                onChange(handleReject(transformDiffs(diffs), index))
              }
            >
              {newText}
            </ChangePopover>
          ) : null;
        },
        del: ({ children }) => {
          const child = children;

          let text;
          let index = 0;
          if (typeof child === 'string') {
            text = child.split(indexToken)[0];
            index = Number(child.split(indexToken)[1]);
          }

          if (Array.isArray(child)) {
            const index = child.findIndex(
              (c) => typeof c === 'string' && c.includes(indexToken),
            );

            const modifiedChild = child.map((c) =>
              typeof c === 'string' ? c.replaceAll(indexToken, '') : c,
            );
            text = modifiedChild.slice(0, index);
          }

          return child ? (
            <ChangePopover
              oldText={text}
              onAccept={() =>
                onChange(handleAccept(transformDiffs(diffs), index))
              }
              onReject={() =>
                onChange(handleReject(transformDiffs(diffs), index))
              }
            >
              {text}
            </ChangePopover>
          ) : null;
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
};
