import {
  ArrowRightLeft,
  GripVertical,
  PlusSquare,
  RefreshCcw,
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useDraggable } from '@dnd-kit/core';
import BeatLoader from '@/Components/BeatLoader';
import remarkGfm from 'remark-gfm';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useCurrentEditorState } from '../../EditorStateProvider';
import { tracking } from '@/Services/tracking/Tracking';
import { deserializeSnippet } from '../../PlateEditor/lib/deserialize';

type AIMessageOverlayProps = {
  text: string;
  id?: number;
};
type Props = {
  hasSelection?: boolean;
  isLastAIMessage?: boolean;
  text: string;
  id: number;
  handleRefreshMessage: (id: number) => void;
};

export function AIMessageOverlayV2({ text, id }: AIMessageOverlayProps) {
  return (
    <div className="flex w-full flex-col pt-2 opacity-70">
      <p className="mb-4 px-3">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className="prose w-full max-w-none  text-lg"
        >
          {text}
        </ReactMarkdown>
        {id === 0 && (
          <BeatLoader
            className="mt-6"
            size={'0.5rem'}
            color="rgb(74 222 128 / 1)"
          />
        )}
      </p>
    </div>
  );
}

export function AIMessageV2({
  text,
  id,
  handleRefreshMessage,
  isLastAIMessage,
  hasSelection,
}: Props) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef } =
    useDraggable({
      data: {
        text,
        source: 'chat',
      },
      id,
    });

  const { currentEditor } = useCurrentEditorState();

  const handleInsertText = (markdownString: string) => {
    if (currentEditor) {
      const nodes = deserializeSnippet(markdownString);
      if (currentEditor.selection) {
        tracking.event('document_chat_inserted_content');
        const focusPath = currentEditor.selection.focus.path[0];
        const anchorPath = currentEditor.selection.anchor.path[0];
        if (focusPath >= anchorPath) {
          currentEditor.insertNodes(nodes, {
            at: [focusPath + 1],
          });
          return;
        }
        if (anchorPath > focusPath) {
          currentEditor.insertNodes(nodes, {
            at: [anchorPath + 1],
          });
          return;
        }
      }
    }
  };

  const handleReplaceText = (markdownString: string) => {
    if (currentEditor) {
      currentEditor.deleteFragment();
      const nodes = deserializeSnippet(markdownString);
      currentEditor.insertFragment(nodes);
      tracking.event('document_chat_replaced_content');
    }
  };

  return (
    <div className="relative w-full">
      <div
        className="group flex w-full flex-col rounded-xl pt-6"
        ref={setNodeRef}
      >
        <p className="mb-4 px-3">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="prose w-full max-w-none text-lg"
          >
            {text}
          </ReactMarkdown>
        </p>
        <div className="flex items-center justify-between gap-2 pb-2 pl-2">
          <div className="flex items-center gap-1">
            <div {...listeners} {...attributes}>
              <Button
                prependIcon={GripVertical}
                text="Drag"
                size="sm"
                variant="ghost"
                dense="also-horizontally"
              />
            </div>
          </div>
          <div className="flex items-center gap-6">
            {isLastAIMessage && (
              <IconButton
                icon={RefreshCcw}
                tooltip={{ title: 'Refresh message', side: 'bottom' }}
                onClick={() => {
                  handleRefreshMessage(id);
                }}
              />
            )}
            {hasSelection && (
              <Button
                prependIcon={ArrowRightLeft}
                text="Replace"
                variant="ghost"
                dense="also-horizontally"
                size="sm"
                onClick={() => handleReplaceText(text)}
              />
            )}
            <Button
              prependIcon={PlusSquare}
              text="Insert"
              variant="ghost"
              dense="also-horizontally"
              size="sm"
              onClick={() => handleInsertText(text)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
