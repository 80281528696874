import { Alert } from './Alert';

type Props = {
  title: string;
  body?: string | (string[] | string)[] | undefined;
};

export const WarningAlert = ({
  title = 'Missing information',
  body,
}: Props) => <Alert color="yellow" title={title} body={body} />;
