import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { Outlet, useNavigate, useRouterState } from '@tanstack/react-router';
import { Cable, Code, LayoutTemplate, Mic, Settings2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFeatureFlagging } from '../AppLoader';

export const Settings = () => {
  const navigate = useNavigate();
  const routerState = useRouterState();
  const { hasFeature } = useFeatureFlagging();

  const tabs = [
    {
      path: '/settings/edit-project',
      text: 'CONFIGURATION',
      prependIcon: Settings2,
    },
    {
      path: '/settings/brand-voice',
      text: 'BRAND VOICE',
      prependIcon: Mic,
    },
    {
      path: '/settings/templates',
      text: 'TEMPLATES',
      prependIcon: LayoutTemplate,
    },
    {
      path: '/settings/connections',
      text: 'CONNECTIONS',
      prependIcon: Cable,
    },
    ...(hasFeature('custom-fields')
      ? [
          {
            matchOn: 'custom-fields',
            path: '/settings/custom-fields/mapping',
            text: 'CUSTOM FIELDS',
            prependIcon: Code,
          },
        ]
      : []),
  ];

  const [tab, setTab] = useState(
    tabs.findIndex(
      (tab) =>
        tab.path === routerState.location.pathname ||
        (tab.matchOn && routerState.location.pathname.includes(tab.matchOn)),
    ),
  );

  useEffect(() => {
    setTab(
      tabs.findIndex(
        (tab) =>
          tab.path === routerState.location.pathname ||
          (tab.matchOn && routerState.location.pathname.includes(tab.matchOn)),
      ),
    );
  }, [routerState.location.pathname]);

  const handleChange = (value: number) => {
    navigate({ to: tabs[value].path });
    setTab(value);
  };

  return (
    <Menus>
      <PageContainer title="Settings">
        <div className="my-4">
          <Tabs type="grow" onChange={handleChange} value={tab} tabs={tabs} />
        </div>
        <Outlet />
      </PageContainer>
    </Menus>
  );
};
