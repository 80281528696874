import { GoogleLogo } from '@/Components/GoogleLogo';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { GSCChooseSiteDialog } from '@/Pages/Settings/Connections/components/GSCChooseSiteDialog';
import {
  GetGoogleSearchConsoleResponse,
  SelectSiteError,
  SelectSiteResponse,
  SelectSiteVariables,
  SetupGoogleSearchConsoleError,
  SetupGoogleSearchConsoleResponse,
  SetupGoogleSearchConsoleVariables,
} from '@/api/openapiComponents';
import { useGoogleLogin } from '@react-oauth/google';
import { UseMutateFunction } from '@tanstack/react-query';

export type GoogleConsoleConnectProps = {
  onConnect?: () => void;
  googleDialog: {
    isOpen: boolean;
    sites: string[];
    selectedSite: string | undefined;
  };
  setGoogleDialog: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      sites: string[];
      selectedSite: string | undefined;
    }>
  >;
  hasNoAvailableSites: boolean;
  selectSiteForGoogleSearchConsole: UseMutateFunction<
    SelectSiteResponse,
    SelectSiteError,
    SelectSiteVariables,
    unknown
  >;
  setupGoogleConsole: UseMutateFunction<
    SetupGoogleSearchConsoleResponse,
    SetupGoogleSearchConsoleError,
    SetupGoogleSearchConsoleVariables,
    unknown
  >;
  initialState: {
    isOpen: boolean;
    sites: never[];
    selectedSite: undefined;
  };
  isSelectingSiteForGoogleSearchConsole: boolean;
  googleSearchConsoleData: GetGoogleSearchConsoleResponse | undefined;
  isGettingGoogleSearchConsoleData: boolean;
  hideDialog?: boolean;
  page?: string;
  message?: string;
};
export const GoogleConsoleConnect = ({
  hideDialog,
  googleDialog,
  hasNoAvailableSites,
  selectSiteForGoogleSearchConsole,
  setGoogleDialog,
  setupGoogleConsole,
  initialState,
  isSelectingSiteForGoogleSearchConsole,
  isGettingGoogleSearchConsoleData,
  googleSearchConsoleData,
  page,
  message,
  onConnect,
}: GoogleConsoleConnectProps) => {
  const projectId = useAppStore((state) => state.currentProject!.id);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setupGoogleConsole({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const shouldShowDialog = () => {
    if (isGettingGoogleSearchConsoleData) {
      return false;
    }
    if (googleSearchConsoleData?.data.state !== 'active') {
      return true;
    }
    return false;
  };

  return shouldShowDialog() ? (
    <>
      <GSCChooseSiteDialog
        isLoading={isSelectingSiteForGoogleSearchConsole}
        isOpen={googleDialog.isOpen}
        hasNoAvailableSites={hasNoAvailableSites}
        onClose={() => setGoogleDialog(initialState)}
        onConnect={() => {
          selectSiteForGoogleSearchConsole({
            pathParams: {
              project: projectId,
            },
            body: {
              site: googleDialog.selectedSite!,
            },
          });
          setGoogleDialog(initialState);
          onConnect?.();
        }}
        setGoogleDialog={setGoogleDialog}
        googleDialog={googleDialog}
      />
      {!hideDialog && (
        <div className="mx-auto mt-[420px] w-[50%] rounded-xl bg-white px-4 py-8 shadow-base-small">
          <div className="flex flex-col justify-center gap-4 text-center text-primary">
            <h1 className="text-xl font-bold">Connect to see {page}</h1>
            {message && <p>{message}</p>}
            <div className="flex justify-center">
              <Button
                prependIcon={<GoogleLogo />}
                text="CONNECT SEARCH CONSOLE"
                color="secondary"
                onClick={login}
              />
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};
