import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './bootstrap';
import '../css/app.css';
import './polyfills';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './Components/Utils/v2/api';
import AppLoader from './Pages/AppLoader/AppLoader';
import { SnackbarProvider } from './Components/v2/Snackbar';
import { router } from './Pages/Router';
import { GoogleOAuthProvider } from '@react-oauth/google';
// Store the original console.error function
const originalError = console.error;

// Override console.error to suppress specific warnings
console.error = (message) => {
  // Check if the warning message contains "validateDOMNesting"
  if (
    typeof message === 'string' &&
    message.includes('Warning: validateDOMNesting(...):')
  ) {
    // Do nothing or handle it as you see fit
    return;
  }
  // For other warnings/errors, log to the console as usual using the original console.error
  originalError.call(console, message);
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  beforeSend: (event, hint) => {
    if (event.exception) {
      for (const exception of event.exception.values ?? []) {
        if (exception.type === 'SecurityError') {
          return null;
        }
      }
    }
    const originalException = hint.originalException as
      | { status?: number; message?: string }
      | undefined;
    if (
      originalException?.message === 'Unauthenticated.' ||
      (originalException?.status &&
        originalException.status >= 400 &&
        originalException.status < 500)
    ) {
      return null;
    }
    return event;
  },

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration(),
  ],

  environment: import.meta.env.VITE_APP_ENV,

  tracesSampleRate: 0.0,

  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,

  denyUrls: [
    // Google Tag Manager
    /googletagmanager\.com/i,
    // Facebook
    /connect\.facebook\.net/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
});

ReactDOM.createRoot(document.getElementById('app')!).render(
  <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <AppLoader />
      </SnackbarProvider>
      {import.meta.env.VITE_TANSTACK_DEV_TOOLS && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </GoogleOAuthProvider>,
);
