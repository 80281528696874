import * as Table from '@/Components/Table';
import { ValueWithGrowth } from './components/ValueWIthGrowth';
import { GrowthValueHeader } from './components/GrowthValueHeader';
import { useAnalyticsQuery } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useState } from 'react';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import {
  calculateGrowth,
  convertData,
  convertKey,
  getDateParams,
  sortByGrowth,
} from './util';
import { CellWithBar } from './components/CellWithBar';
import PageContainer from '@/Components/PageContainer';
import { removeDomain } from '@/utils';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { Menus } from '@/Components/Menus';
import { useAnalyticsTotals, useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { AnalyticsUrlActions } from './components/AnalyticsUrlActions';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ExternalLink } from 'lucide-react';

export const PageDetails = () => {
  const appState = useAppStore();

  const navigate = useNavigate({ from: '/page-analytics/page/$pageId' });
  const params = useParams({ from: '/page-analytics/page/$pageId' });
  const search = useSearch({ from: '/page-analytics/page/$pageId' });

  const [graphState, setGraphState] = useGraphState();
  const [growthSorting, setGrowthSorting] = useGrowthSorting('pagedetails');

  const totals = useAnalyticsTotals(graphState, { page: params.pageId });

  const currentQuery = useAnalyticsQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(graphState),
      dimensions: ['query'],
      dimension_filter_groups: [
        {
          filters: [
            {
              dimension: 'page',
              operator: 'equals',
              expression: params.pageId,
            },
          ],
        },
        {
          filters:
            graphState.country?.iso_3166_3 === 'xxx'
              ? []
              : [
                  {
                    dimension: 'country',
                    operator: 'equals',
                    expression: graphState.country?.iso_3166_3,
                  },
                ],
        },
      ],
    },
  });

  const previousQuery = useAnalyticsQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(graphState, true),
      dimensions: ['query'],
      dimension_filter_groups: [
        {
          filters: [
            {
              dimension: 'page',
              operator: 'equals',
              expression: params.pageId,
            },
          ],
        },
        {
          filters:
            graphState.country?.iso_3166_3 === 'xxx'
              ? []
              : [
                  {
                    dimension: 'country',
                    operator: 'equals',
                    expression: graphState.country?.iso_3166_3,
                  },
                ],
        },
      ],
    },
  });

  const data = convertData(
    {
      current: convertKey('query', currentQuery.data?.data ?? []),
      previous: convertKey('query', previousQuery.data?.data ?? []),
    },
    'query',
  );

  const mostClicks =
    data?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]?.current
      .clicks ?? 0;

  return (
    <Menus>
      <PageContainer
        title={`Page: ${removeDomain(params.pageId)}`}
        titleActions={
          <IconButton
            icon={ExternalLink}
            onClick={() => window.open(params.pageId, '_blank')}
          />
        }
        canGoBack
      >
        <AnalyticsFilters
          state={graphState}
          onStateChange={setGraphState}
          totals={totals}
        />
        <AnalyticsGraph state={graphState} page={params.pageId} />
        <Table.Root
          isLoading={currentQuery.isLoading}
          skeletonLoaders={20}
          items={(growthSorting === 'popular'
            ? data
            : sortByGrowth(
                data ?? [],
                growthSorting === 'rising' ? 'DESC' : 'ASC',
                'clicks',
              )
          )?.slice((search.page - 1) * 20, search.page * 20)}
          columns={[
            {
              heading: 'Keyword',
              expanded: true,
              render: (item) => (
                <CellWithBar
                  value={item.current.clicks}
                  topValue={mostClicks}
                  name={item.current.query}
                  actions={<AnalyticsUrlActions url={item.current.page} />}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="Clicks" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  responsive={false}
                  value={item.current.clicks}
                  growth={calculateGrowth(item, 'clicks')}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="Impressions" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  responsive={false}
                  value={item.current.impressions}
                  growth={calculateGrowth(item, 'impressions')}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="CTR" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  responsive={false}
                  value={(item.current.ctr * 100).toFixed(1)}
                  growth={calculateGrowth(item, 'ctr')}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="Position" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  responsive={false}
                  value={item.current.position.toFixed(1)}
                  growth={calculateGrowth(item, 'position')}
                />
              ),
            },
          ]}
        >
          <Table.Header>
            <div className="mt-10 flex items-center gap-4">
              <h1 className="mb-1 text-xl font-semibold">Keywords</h1>
              <ToggleButton
                value={growthSorting}
                onChange={setGrowthSorting}
                options={[
                  {
                    displayName: 'Popular',
                    value: 'popular',
                  },
                  {
                    displayName: 'Rising',
                    value: 'rising',
                  },
                  {
                    displayName: 'Falling',
                    value: 'falling',
                  },
                ]}
              />
            </div>
          </Table.Header>
          <Table.Footer>
            <Pagination
              currentPage={search.page ?? 1}
              lastPage={Math.floor((currentQuery.data?.data.length ?? 0) / 20)}
              setCurrentPage={(pageNumber) =>
                navigate({ search: { page: pageNumber }, replace: true })
              }
            />
          </Table.Footer>
        </Table.Root>
      </PageContainer>
    </Menus>
  );
};
