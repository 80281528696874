import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { CreatePageContainer } from '../../../components';
import { useEffect, useState } from 'react';
import { Card } from '@/Components/v2/Card';
import { useNavigate, useSearch } from '@tanstack/react-router';
import {
  CreateDocumentResponse,
  GetDocumentResponse,
  useAutoGenerateFromKeywords,
  useCreateDocument,
  useGetDocument,
  useListContentTypes,
  useListDocumentTemplates,
  useProjectsShow,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import {
  CreateDocumentRequest,
  DocumentResource,
  OrganisationTagResource,
} from '@/api/openapiSchemas';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { SingleContentForm } from './components/SingleContentForm';
import { ArrowRight, FileStack, Stars } from 'lucide-react';
import { useGenerateDocument } from '../../hooks';
import { DndHeading } from '@/Components/DocumentForm/hooks';
import { hasher, useFeatureFlagging } from '@/Pages/AppLoader';
import { BulkContentForm } from './components/BulkContentForm';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorAlert } from '@/Components/v2/Alert';

export type AiGeneratedFormState = {
  single: {
    keyword: string;
    contentTypeId?: number;
    title: string;
    templateId: number | null;
    brief: string;
    briefFromUrl: string;
    optionLiveGenerate: boolean;
    optionReviewSubheadings: boolean;
    optionReviewTitle: boolean;
    selectedBriefType: 'from-url' | 'manual';
    importedUrl: string;
    outline?: DndHeading[];
    document?: DocumentResource;
  };
  bulk: {
    keywords: string;
    contentTypeId?: number;
    templateId: number | null;
    tags: OrganisationTagResource[];
  };
};

export const AiGenerated = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const client = useQueryClient();
  const search = useSearch({ from: '/create-content/ai-generated' });
  const { hasFeature } = useFeatureFlagging();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [formState, setFormState] = useState<AiGeneratedFormState>({
    single: {
      keyword: search.keyword ?? '',
      importedUrl: '',
      contentTypeId: undefined,
      title: '',
      templateId: null,
      brief: '',
      briefFromUrl: '',
      selectedBriefType: 'manual',
      optionLiveGenerate: false,
      optionReviewTitle: true,
      optionReviewSubheadings: true,
    },
    bulk: {
      keywords: '',
      contentTypeId: undefined,
      templateId: null,
      tags: [],
    },
  });

  const projectQuery = useProjectsShow({
    pathParams: { project: appState.currentProject!.id },
  });
  const updateDocumentMutation = useUpdateDocument({
    onSuccess: (data) => {
      const queryKey =
        client.getQueryCache().find({
          predicate: (query) => query.queryKey.includes('getDocument'),
        })?.queryKey ?? [];
      client.setQueryData(queryKey, data);
    },
  });
  const createDocumentMutation = useCreateDocument();
  const contentTypeQuery = useListContentTypes({});
  const bulkAutoGenerate = useAutoGenerateFromKeywords();
  const documentQuery = useGetDocument(
    {
      pathParams: {
        document: Number(search.documentId),
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: search.documentId !== undefined,
      gcTime: 0,
    },
  );

  const hasNewTemplatesFeature = () => {
    return (
      !formState.single.templateId &&
      (formState.single.optionReviewTitle ||
        formState.single.optionReviewSubheadings)
    );
  };

  const customTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      filters: {
        project_id: appState.currentProject!.id,
      },
      limit: 100,
    },
  });

  const customTemplates = customTemplatesQuery.data?.data ?? [];

  const predefinedTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      limit: 100,
    },
  });

  const predefinedTemplates = predefinedTemplatesQuery.data?.data ?? [];

  useEffect(() => {
    if (documentQuery.isFetchedAfterMount && !documentQuery.isLoading) {
      const document = documentQuery.data?.data;
      if (document) {
        setFormState({
          ...formState,
          single: {
            ...formState.single,
            contentTypeId: document.content_type?.id,
            title: document.title,
            brief: document?.settings?.brief ?? '',
            keyword: document.keyword?.name ?? '',
            optionLiveGenerate: true,
          },
        });
      }
    }
  }, [documentQuery.isFetchedAfterMount, documentQuery.isLoading]);

  const {
    handleGenerateDocument,
    isLoading: isGeneratingDocument,
    error: generateError,
  } = useGenerateDocument(
    formState,
    appState.currentProject!.id,
    projectQuery.data?.data.language.brief_length ?? 0,
    search.documentId,
  );

  const getKeywords = formState.bulk.keywords
    .split('\n')
    .filter((value) => value.trim() !== '');

  const handleInfoSubmit = () => {
    // Bulk generate
    if (selectedTabIndex === 1) {
      const keywords = getKeywords;
      if (keywords.length > 100) return;

      bulkAutoGenerate
        .mutateAsync({
          pathParams: {
            project: appState.currentProject!.id,
          },
          body: {
            document_template_id: formState.bulk.templateId
              ? formState.bulk.templateId
              : undefined,
            content_type_id: formState.bulk.contentTypeId,
            documents: keywords.map((keyword) => ({
              keyword: keyword,
            })),
            tags: formState.bulk.tags.map((tag) => tag.id),
          },
        })
        .then(() => {
          navigate({ to: '/created-content' });
        });

      return;
    }

    if (hasNewTemplatesFeature()) {
      const brief =
        formState.single.selectedBriefType === 'manual'
          ? formState.single.brief
          : formState.single.briefFromUrl;

      const body: CreateDocumentRequest = {
        keyword: formState.single.keyword,
        title: formState.single.title,
        content_type_id: formState.single.contentTypeId,
        ...(formState.single.brief.length > 0 ||
        formState.single.briefFromUrl.length > 0
          ? {
              settings: {
                tone_of_voice: null,
                target_audience: null,
                type: null,
                outline_type: 'mix_of_h2_and_h3',
                brief: '',
                context: {
                  value: brief.slice(
                    0,
                    projectQuery.data?.data.language.brief_length ?? 0,
                  ),
                  type:
                    formState.single.selectedBriefType === 'from-url'
                      ? 'url'
                      : 'none',
                  type_value:
                    formState.single.selectedBriefType === 'from-url'
                      ? formState.single.importedUrl
                      : null,
                },
              },
            }
          : {}),
      };
      const onSuccessCallback = (data: CreateDocumentResponse) => {
        if (formState.single.optionReviewTitle) {
          return navigate({
            to: '/create-content/title-editor/$documentId',
            params: { documentId: '' + data.data.id },
            search: {
              live_generate: formState.single.optionLiveGenerate,
              review_subheadings: formState.single.optionReviewSubheadings,
              is_new: search.is_new,
              ...(formState.single.templateId
                ? {
                    document_template: formState.single.templateId,
                  }
                : {}),
            },
          });
        }
        if (formState.single.optionReviewSubheadings) {
          return navigate({
            to: '/create-content/outline-editor/$documentId',
            params: { documentId: '' + data.data.id },
            search: {
              live_generate: formState.single.optionLiveGenerate,
              is_new: search.is_new,
            },
          });
        }
      };

      if (search.documentId) {
        return updateDocumentMutation.mutate(
          {
            pathParams: {
              document: Number(search.documentId),
              project: appState.currentProject!.id,
            },
            body,
          },
          {
            onSuccess: onSuccessCallback,
          },
        );
      } else {
        return createDocumentMutation.mutate(
          {
            pathParams: {
              project: appState.currentProject!.id,
            },
            body,
          },
          {
            onSuccess: onSuccessCallback,
          },
        );
      }
    } else {
      handleGenerateDocument((data) => {
        const queryKey =
          client.getQueryCache().find({
            predicate: (query) => query.queryKey.includes('getDocument'),
          })?.queryKey ?? [];

        client.setQueryData<GetDocumentResponse | undefined>(
          queryKey,
          (prev) => {
            if (!prev) return prev;
            return { ...prev, data: { ...prev.data, text: '' } };
          },
        );

        if (
          formState.single.optionLiveGenerate &&
          formState.single.templateId === null
        ) {
          navigate({
            to: '/documents/$documentId',
            params: { documentId: hasher.encode(data.data.id) },
            search: {
              generate: true,
              ...(formState.single.templateId
                ? {
                    document_template: formState.single.templateId,
                  }
                : {}),
            },
          });
        } else {
          navigate({ to: '/created-content' });
        }
      });
    }
  };

  const handleClose = () => {
    navigate({
      to: '/documents/$documentId',
      params: { documentId: hasher.encode(search.documentId!) },
    });
  };

  const isLoadingData =
    documentQuery.isLoading ||
    contentTypeQuery.isLoading ||
    projectQuery.isLoading;

  const allTemplates = [
    { group: 'Predefined templates' },
    ...(predefinedTemplates.map((template) => ({
      value: template.id.toString(),
      title: template.name,
    })) || []),
    { group: 'Your own templates' },
    ...(customTemplates.map((template) => ({
      value: template.id.toString(),
      title: template.name,
    })) || []),
  ];

  return (
    <CreatePageContainer
      title="AI draft"
      canGoBack={!search.documentId}
      onClose={search.documentId ? handleClose : undefined}
      closeText="Return to editor"
      closeIcon={ArrowRight}
    >
      <div className="mt-4 w-[min(40rem,90vw)]">
        <div className="mb-2">
          <Tabs
            value={selectedTabIndex}
            disabled={isLoadingData || !!formState.single.document}
            onChange={setSelectedTabIndex}
            tabs={
              search.documentId || search.keyword
                ? []
                : [
                    {
                      prependIcon: Stars,
                      text: 'Single',
                    },
                    {
                      prependIcon: FileStack,
                      text: 'Bulk',
                      tooltip: appState.subscription?.permissions
                        .bulk_document_generate
                        ? undefined
                        : {
                            text: 'Upgrade to a higher plan to get access to this feature.',
                            navLink: '/account/subscription',
                            navLinkText: 'See pricing',
                          },
                      disabled:
                        !appState.subscription?.permissions
                          .bulk_document_generate,
                    },
                  ]
            }
          />
        </div>
        <Card>
          <CardBody>
            <div className="flex max-h-[75vh] flex-col gap-6 overflow-scroll px-10 py-4">
              {isLoadingData ? (
                <>
                  <SkeletonLoader />
                  <SkeletonLoader />
                  <SkeletonLoader />
                  <SkeletonLoader />
                  <SkeletonLoader />
                  <SkeletonLoader />
                </>
              ) : (
                <>
                  {selectedTabIndex === 0 && (
                    <>
                      <SingleContentForm
                        customTemplates={customTemplates}
                        predefinedTemplates={predefinedTemplates}
                        hasNewTemplatesFeature={hasNewTemplatesFeature}
                        briefLimit={
                          projectQuery.data?.data.language.brief_length ?? 0
                        }
                        isLoading={
                          isGeneratingDocument ||
                          createDocumentMutation.isPending
                        }
                        onSubmit={handleInfoSubmit}
                        contentTypes={contentTypeQuery.data?.data ?? []}
                        formState={formState}
                        setFormState={setFormState}
                      />
                      {(createDocumentMutation.error || generateError) && (
                        <ErrorAlert
                          title={
                            createDocumentMutation.error?.message ??
                            generateError?.message
                          }
                        />
                      )}
                    </>
                  )}
                  {selectedTabIndex === 1 && (
                    <>
                      <BulkContentForm
                        customTemplates={customTemplates}
                        predefinedTemplates={predefinedTemplates}
                        templates={allTemplates}
                        keywords={getKeywords}
                        isLoading={bulkAutoGenerate.isPending}
                        onSubmit={handleInfoSubmit}
                        contentTypes={contentTypeQuery.data?.data ?? []}
                        formState={formState}
                        setFormState={setFormState}
                      />
                      {bulkAutoGenerate.error && (
                        <ErrorAlert title={bulkAutoGenerate.error.message} />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </CreatePageContainer>
  );
};
