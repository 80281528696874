import { Link } from '@tanstack/react-router';
import { Tooltip } from '../Tooltip';
import { TanStackSearchParams } from '@/types';

type Props<T> = {
  item: {
    name: string;
    href: string;
    search?: TanStackSearchParams<T>;
    current: boolean;
    disabled?: boolean;
    tooltip?: {
      text: string;
      navLink?: string;
      navLinkText?: string;
    };
    icon: any;
  };
  isSubItem?: boolean;
  isLast?: boolean;
  isBeta?: boolean;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const SidebarMenuItem = <T,>({
  item,
  isSubItem,
  isLast,
  isBeta,
}: Props<T>) => {
  return (
    <Tooltip
      asChild
      title={item.tooltip?.text}
      disabled={!item.tooltip}
      navLink={item.tooltip?.navLink}
      navLinkText={item.tooltip?.navLinkText}
    >
      <li key={item.name}>
        <Link
          to={item.href}
          disabled={item.disabled}
          search={item.search}
          className={classNames(
            item.current ? 'bg-primary-125 text-secondary-600' : 'text-primary',
            !item.current && !item.disabled
              ? 'hover:bg-primary-125 hover:text-primary-800'
              : '',
            'group flex  gap-x-4 rounded-md p-2 font-semibold leading-6',
            item.disabled ? 'cursor-not-allowed opacity-60' : '',
            isSubItem ? 'gap-x-5 pl-[1.775rem]' : '',
          )}
        >
          {isSubItem ? (
            <div className="relative">
              <div
                className={`absolute -left-3 top-2 h-2 w-2 rounded-full  ${
                  item.current ? 'bg-secondary-600' : 'bg-gray-300'
                }`}
              />
              {!isLast && (
                <div className="absolute -left-[0.575rem] top-[1.125rem] z-10 h-7 w-0.5 bg-gray-300" />
              )}
            </div>
          ) : (
            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
          )}
          <div className="flex-grow">{item.name}</div>
          {isBeta && (
            <span className="rounded bg-yellow-100 px-1 text-xs text-yellow-700">
              BETA
            </span>
          )}
        </Link>
      </li>
    </Tooltip>
  );
};
