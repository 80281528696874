import { ReactNode } from '@tanstack/react-router';

type Props = {
  value: number;
  topValue: number;
  name: string;
  onClick?: () => void;
  actions?: ReactNode;
};

export const CellWithBar = ({
  onClick,
  value,
  topValue,
  name,
  actions,
}: Props) => {
  return (
    <div
      className={` relative ${onClick ? 'group cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <div
        style={{
          width: (value / topValue) * 100 + '%',
        }}
        className="-ml-2 rounded-lg bg-blue-50 py-1 pl-2 group-hover:bg-blue-100"
      >
        {' '}
        &nbsp;
      </div>
      {actions && (
        <div className="absolute bottom-0 right-4 top-0 flex items-center gap-1 opacity-0 group-hover:opacity-100">
          {actions}
        </div>
      )}
      <div
        className={`${onClick ? 'pointer-events-none' : ''} absolute inset-0 flex items-center truncate`}
      >
        {name}
      </div>
    </div>
  );
};
