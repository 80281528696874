import { LucideProps } from 'lucide-react';

type Props = {
  title: string;
  icon?: React.ComponentType<LucideProps>;
  description: string;
  onClick: () => void;
};

export const WorkflowCard = ({ description, icon, onClick, title }: Props) => {
  const Icon = icon;

  return (
    <div
      className="flex aspect-video w-full max-w-72 cursor-pointer flex-col justify-center gap-2 rounded-lg bg-white p-4 shadow hover:opacity-60"
      onClick={onClick}
    >
      {Icon && <Icon className={`aspect-square w-[20%] text-primary-400`} />}
      <p className="text-xl font-bold text-primary ">{title}</p>

      <p className="text-xs text-primary">{description}</p>
    </div>
  );
};
