import { tracking } from './Services/tracking/Tracking';
import { SEO_AI_AUTH } from './api/openapiFetcher';
import { TanStackSearchParams } from './types';

export const SEO_AI_AUTH_REQUIRED = 'SEO_AI_AUTH_REQUIRED';

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const formatThousandSeperator = (searchVolume: number) =>
  searchVolume.toLocaleString();

export const formatLargeNumber = (num: number) => {
  if (num > 999999) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num > 999) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return '' + num;
};

export const removeProtocol = (url?: string) => {
  return (url ?? '').replace(/(^\w+:|^)\/\//, '');
};

export const removeDomain = (url?: string) => {
  if (!url) return '';
  return '/' + removeProtocol(url)?.split('/').slice(1).join('/');
};

export const getCookieValue = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ?? null;

export const handleLogout = () => {
  localStorage.removeItem(SEO_AI_AUTH);
  tracking.reset();
  window.location.reload();
};

// We check for app-initialize in the URL to avoid spam redirecting to the login page
export const handleAuthRedirect = (response: Response) => {
  if (response.status !== 401 || response.url.includes('/app-initialize')) {
    return;
  }
  localStorage.setItem(SEO_AI_AUTH_REQUIRED, 'true');
  handleLogout();
};

export const handleSplitOnPaste = (
  e: React.ClipboardEvent,
  currentValue: string,
  separator = ',',
): Promise<string> =>
  new Promise((resolve) => {
    const clipBoardData = e.clipboardData?.getData('text');
    if (clipBoardData?.includes(separator)) {
      setTimeout(() => {
        const value = clipBoardData
          ?.split(',')
          .map((v) => v.trim())
          .join('\n');
        resolve((currentValue + '\n' + value).trim());
      }, 50);
    }
  });

export const getSearchFilters = <F>(
  search: Record<string, unknown>,
  additionalFilters: F,
) => {
  let filters:
    | {
        keyword?: string;
        search?: string;
        tags?: number[];
        tag_mode?: 'all' | 'any';
      }
    | undefined = undefined;
  if (search) {
    if (
      search.keyword ||
      search.search ||
      search.tags ||
      search.tag_mode ||
      (additionalFilters && Object.keys(additionalFilters).length > 0)
    ) {
      filters = {};
    }
    if (search.keyword && filters !== undefined) {
      filters.keyword = search.keyword as string;
    }
    if (search.search && filters !== undefined) {
      filters.search = search.search as string;
    }
    if (search.tags && filters !== undefined) {
      filters.tags = search.tags as number[];
    }
    if (search.tag_mode && filters !== undefined) {
      filters.tag_mode = search.tag_mode as 'all' | 'any';
    }
    if (additionalFilters) {
      for (const key of Object.keys(additionalFilters)) {
        if (search[key] !== undefined) {
          filters[key] = search[key];
        }
      }
    }
  }

  return filters as typeof filters & F;
};

export function validateTanStackSearch<T, F, A>(
  search: Record<string, unknown>,
  additionalFilters?: F,
  additionalSearch?: A,
): TanStackSearchParams<T, F> & A {
  return {
    page: search.page as number,
    sortDirection: search.sortDirection as 'ASC' | 'DESC',
    filters: getSearchFilters<F>(
      search.filters as Record<string, unknown>,
      additionalFilters as F,
    ),
    limit: search.limit as number,
    sortBy: search.sortBy as T,
    ...(additionalSearch ?? {}),
  } as TanStackSearchParams<T, F> & A;
}

export const getHostName = () =>
  window.location.origin + window.location.pathname;

export const downloadCSV = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const ongoingDebounces: Record<string, NodeJS.Timeout> = {};

export const namedDebounce = (
  name: string,
  fn: (...args: any[]) => void,
  delay: number,
) => {
  if (ongoingDebounces[name]) {
    clearTimeout(ongoingDebounces[name]);
  }
  ongoingDebounces[name] = setTimeout(() => {
    fn();
    delete ongoingDebounces[name];
  }, delay);
};
