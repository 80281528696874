import { ListPageQueriesQueryParams } from '@/api/openapiComponents';
import { GraphState } from './hooks';
import dayjs from 'dayjs';

export const convertData = <T>(
  data?: { current: T[]; previous?: T[] },
  key = 'page',
) => {
  return data?.current.map((item) => {
    return {
      current: item,
      previous: data.previous?.find((prev) => prev[key] === item[key]),
    };
  });
};

export const calculateGrowth = <T>(
  data: {
    current: T;
    previous?: T;
  },
  dataKey: string,
) => {
  return {
    growth: !data.previous?.[dataKey]
      ? null
      : ((data?.current?.[dataKey] - data?.previous?.[dataKey] ?? 0) /
          data?.previous?.[dataKey]) *
        100,
    difference: data.previous
      ? data?.current?.[dataKey] - data?.previous?.[dataKey]
      : 0,
  };
};

export const sortByGrowth = <T>(
  data: {
    current: T;
    previous?: T;
  }[],
  direction: 'ASC' | 'DESC',
  dataKey: string,
) => {
  return data.toSorted((a, b) => {
    if (direction === 'ASC') {
      return (
        (a.current[dataKey] ?? 0) -
        (a.previous?.[dataKey] ?? 0) -
        ((b.current[dataKey] ?? 0) - (b.previous?.[dataKey] ?? 0))
      );
    } else {
      return (
        (b.current[dataKey] ?? 0) -
        (b.previous?.[dataKey] ?? 0) -
        ((a.current[dataKey] ?? 0) - (a.previous?.[dataKey] ?? 0))
      );
    }
  });
};

export const rangeDisplayNames = {
  P1W: '7 days',
  P2W: '14 days',
  P4W: '28 days',
  P3M: '3 months',
  P6M: '6 months',
  P1Y: '1 year',
  P16M: '16 months',
};

export const rangeDays = {
  P1W: 7,
  P2W: 14,
  P4W: 28,
};

export const rangeMonths = {
  P3M: 3,
  P6M: 6,
  P1Y: 12,
  P16M: 16,
};

export const convertKey = <T>(key: string, data: T[]) => {
  return data.map((d) => ({
    ...d,
    [key]: d.keys[0],
  }));
};

export const getSubtractParams = (range?: keyof typeof rangeDisplayNames) => {
  const days = rangeDays[range];
  const months = rangeMonths[range];

  if (days) {
    return {
      unit: 'days',
      value: days,
    };
  }
  if (months) {
    return {
      unit: 'months',
      value: months,
    };
  }
};

export const getDateParams = (state: GraphState, previous = false) => {
  let today = dayjs().subtract(1, 'day');

  const substractParams = getSubtractParams(state.range);

  if (previous) {
    today = today.subtract(substractParams?.value, substractParams?.unit);
  }

  return {
    end_date: today.format('YYYY-MM-DD'),
    start_date: today
      .subtract(substractParams?.value, substractParams?.unit)
      .format('YYYY-MM-DD'),
  };
};
