import { PropsWithChildren } from 'react';

type Props = {
  reverse?: boolean;
};

export const ScrollContainer = ({
  children,
  reverse,
}: PropsWithChildren<Props>) => {
  return (
    <div
      style={{ scrollbarGutter: 'stable both-edges' }}
      className={`editor-scroll-bar mx-auto flex h-full w-full ${reverse ? 'flex-col-reverse' : 'flex-col'} flex-grow items-center gap-4 overflow-y-scroll`}
    >
      {children}
    </div>
  );
};
