import ContentKeywordButtons from '@/Components/ContentKeywordButtons';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import {
  Copy,
  LineChart,
  ListMinus,
  ListPlus,
  Plus,
  Trash2,
} from 'lucide-react';
import * as Table from '@/Components/Table';
import { useCopyTable, useTable } from '@/Components/Table/hooks';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { RangeSelector } from '@/Components/Table/components/RangeSelector';
import { useAppStore } from '../AppLoader/stores';
import { ContentTitle } from './components/ContentTitle';
import { downloadCSV, formatThousandSeperator } from '@/utils';
import { Button } from '@/Components/v2/Button';
import {
  useDelete,
  useList,
  usePlannedContentListDestroy,
  usePlannedContentListIndex,
  usePlannedContentListItemDestroy,
  usePlannedContentListItemStore,
  usePlannedContentListStore,
  usePlannedContentListUpdate,
  useUpdate,
  useProjectProjectPlannedContentExport,
} from '@/api/openapiComponents';
import { AddKeywordsToListDialog, ListTabs } from '../components';
import {
  ContentListResource,
  OrganisationTagResource,
} from '@/api/openapiSchemas';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { RemoveKeywordsFromListDialog } from '../components/RemoveKeywordsFromListDialog';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { TagEditor } from '../Document/components/TagEditor/TagEditor';
import { queryKeyFn } from '@/api/openapiContext';
import { AddPlannedKeywordsDialog } from './components/AddPlannedKeywordsDialog';
import { useState } from 'react';

export default function ContentPlanned() {
  const appState = useAppStore();
  const client = useQueryClient();
  const projectId = appState.currentProject?.id;

  const [hoveredKeywordContentId, setHoveredKeywordContentId] =
    useState<number>();

  const {
    tableState,
    setFilters,
    setPage,
    setSorting,
    resetSelection,
    resetSorting,
    selection,
    setSelection,
  } = useTable<
    'created_at' | 'keyword' | 'search_volume' | 'difficulty',
    {
      search: string;
      search_volume_min: number;
      search_volume_max: number;
      difficulty_min: number;
      difficulty_max: number;
    }
  >();

  const [addListDialog, setAddListDialog] = useState(false);
  const [itemsToBeAddedToList, setItemsToBeAddedToList] = useState<
    { id: number; keyword: string }[]
  >([]);
  const [itemsToBeRemovedFromList, setItemsToBeRemovedFromList] = useState<
    { id: number; keyword: string }[]
  >([]);
  const [showAddKeywordsDialog, setShowAddKeywordsDialog] = useState(false);
  const [keywordsToBeDeleted, setKeywordsToBeDeleted] = useState<
    { id: number; keyword: string }[]
  >([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [editedList, setEditedList] = useState<
    ContentListResource | undefined
  >();
  const [deleteList, setDeleteList] = useState<
    ContentListResource | undefined
  >();
  const plannedContentListQuery = usePlannedContentListIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const getListId = () => {
    if (selectedTabIndex === 0) return undefined;
    if (!plannedContentListQuery.data?.data) return undefined;
    return parseInt(
      plannedContentListQuery.data?.data[selectedTabIndex - 1].id,
    );
  };

  const plannedContentVariables = {
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 25,
      ...tableState,
      filters: {
        ...tableState.filters,
        list_id: getListId(),
      },
    },
  };
  const plannedContentItems = useList(plannedContentVariables);
  const updatePlannedContentItemMutation = useUpdate();
  const {
    mutateAsync: exportPlannedContentToCSV,
    isPending: isExportingPlannedContentToCSV,
  } = useProjectProjectPlannedContentExport();
  const deleteKeywordsMutation = useDelete({
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => query.queryKey.includes('list'),
      });
      setKeywordsToBeDeleted([]);
      resetSelection();
    },
  });

  const plannedContentAddMutation = usePlannedContentListStore({
    onSuccess: (newList) => {
      plannedContentListQuery.refetch().then((plannedContentListResponse) => {
        const plannedContentList = plannedContentListResponse.data?.data ?? [];
        setSelectedTabIndex(
          plannedContentList.findIndex((l) => l.id === newList.data.id) + 1,
        );
      });
      handleClose();
    },
  });

  const plannedContentEditMutation = usePlannedContentListUpdate({
    onSuccess: (newList) => {
      plannedContentListQuery.refetch().then((plannedContentListResponse) => {
        const plannedContentList = plannedContentListResponse.data?.data ?? [];
        setSelectedTabIndex(
          plannedContentList.findIndex((l) => l.id === newList.data.id) + 1,
        );
      });
      handleClose();
    },
  });

  const removeItemsFromListMutation = usePlannedContentListItemDestroy({
    onSuccess: () => {
      handleResetTable();
      plannedContentItems.refetch();
      setItemsToBeRemovedFromList([]);
    },
  });

  const plannedContentDeleteMutation = usePlannedContentListDestroy({
    onSuccess: () => {
      plannedContentListQuery.refetch();
      handleChange(0);
      handleClose();
    },
  });

  const handleCopyToClipboard = useCopyTable(
    selection
      .map((item) => (item.keyword ? item.keyword : 'Untitled'))
      .join('\n'),
  );

  const handleResetTable = () => {
    setFilters(undefined);
    resetSorting();
    resetSelection();
  };

  const handleDeleteKeywords = (keywords: number[]) => {
    deleteKeywordsMutation.mutate({
      pathParams: {
        project: projectId!,
      },
      queryParams: {
        ids: keywords,
      },
    });
  };

  const handleUpdateTags = (
    contentKeyword: string,
    tags: OrganisationTagResource[],
  ) => {
    tags.sort((a, b) => a.name.localeCompare(b.name));

    const newTagIds = tags.map((tag) => tag.id).toString();
    const oldTagIds = (
      plannedContentItems?.data?.data
        ?.find((ck) => ck.id === contentKeyword)
        ?.tags?.map((tag) => tag.id) ?? []
    ).toString();

    if (oldTagIds !== newTagIds) {
      updatePlannedContentItemMutation.mutate({
        pathParams: {
          plannedContent: contentKeyword,
          project: appState.currentProject!.id,
        },
        body: {
          tags: tags.map((tag) => tag.id),
        },
      });
    }

    const updatedPlannedContent = plannedContentItems.data?.data?.map((ck) => {
      if (ck.id === contentKeyword) {
        return {
          ...ck,
          tags: tags,
        };
      }
      return ck;
    });
    client.setQueryData(
      queryKeyFn({
        path: '/projects/{project}/planned-content',
        operationId: 'list',
        variables: plannedContentVariables,
      }),
      {
        data: updatedPlannedContent,
        meta: plannedContentItems.data?.meta,
      },
    );
    plannedContentItems.refetch();
  };

  const handleChange = (tabIndex: number) => {
    handleResetTable();
    setSelectedTabIndex(tabIndex);
  };

  const handleCloseDialog = () => setAddListDialog(false);

  const handleClose = () => {
    setEditedList(undefined);
    setDeleteList(undefined);
    handleCloseDialog();
  };

  const handleOpenDialog = () => setAddListDialog(true);

  const selectedList = plannedContentListQuery.data?.data[selectedTabIndex - 1];

  const handleDeleteItemFromListSubmit = () => {
    removeItemsFromListMutation.mutate({
      body: {
        items: itemsToBeRemovedFromList.map((keyword) => keyword.id),
      },
      pathParams: {
        project: appState.currentProject!.id,
        plannedContentList: parseInt(selectedList!.id),
      },
    });
  };

  appState.pageTitle('Saved keywords');

  return (
    <>
      <ConfirmDialog
        isOpen={keywordsToBeDeleted.length > 0}
        isLoading={deleteKeywordsMutation.isPending}
        title="Delete keywords"
        confirmText="Delete"
        confirmColor="red"
        content={[
          `Are you sure you want to delete ${
            keywordsToBeDeleted.length < 5
              ? keywordsToBeDeleted
                  .map((item) => `"${item.keyword}"`)
                  .toString()
                  .replaceAll(',', ', ')
              : `${keywordsToBeDeleted.length} items`
          } as ${keywordsToBeDeleted.length === 1 ? 'a' : ''} saved keyword${
            keywordsToBeDeleted.length > 1 ? 's' : ''
          }?`,
        ]}
        onClose={() => setKeywordsToBeDeleted([])}
        onConfirm={() =>
          handleDeleteKeywords(keywordsToBeDeleted.map((k) => k.id))
        }
      />

      <AddKeywordsToListDialog
        key={itemsToBeAddedToList.length + 'add'}
        keywords={itemsToBeAddedToList.map((item) => ({
          id: item.id,
          name: item.keyword ?? '',
        }))}
        onSuccess={(listId) => {
          handleResetTable();
          plannedContentListQuery.refetch().then((response) => {
            if (!response.data?.data) return;
            setSelectedTabIndex(
              response.data?.data?.findIndex(
                (list) => parseInt(list.id) === listId,
              ) + 1,
            );
          });
          setItemsToBeAddedToList([]);
        }}
        onClose={() => setItemsToBeAddedToList([])}
      />
      <RemoveKeywordsFromListDialog
        key={itemsToBeRemovedFromList.length + 'remove'}
        list={selectedList}
        keywordsToBeDeleted={itemsToBeRemovedFromList.map((item) => ({
          id: item.id,
          name: item.keyword ?? '',
        }))}
        onClose={() => {
          setItemsToBeRemovedFromList([]);
        }}
        isLoading={removeItemsFromListMutation.isPending}
        error={removeItemsFromListMutation.error}
        onSubmit={handleDeleteItemFromListSubmit}
      />
      <AddPlannedKeywordsDialog
        isOpen={showAddKeywordsDialog}
        onClose={() => setShowAddKeywordsDialog(false)}
        onSuccess={(listId) => {
          if (!listId) {
            setSelectedTabIndex(0);
            plannedContentItems.refetch();
          } else {
            plannedContentListQuery.refetch().then((response) => {
              setSelectedTabIndex(
                response.data?.data?.findIndex((list) => list.id === listId) +
                  1,
              );
            });
          }
        }}
      />
      <Menus>
        <PageContainer title="Saved keywords">
          <div className="flex items-center justify-end py-6">
            <Button
              color="secondary"
              text="Add keywords"
              prependIcon={Plus}
              onClick={() => setShowAddKeywordsDialog(true)}
            />
          </div>
          <ListTabs
            data={plannedContentListQuery.data?.data}
            isLoading={plannedContentListQuery.isLoading}
            selectedTabIndex={selectedTabIndex}
            selectedList={selectedList}
            onChange={handleChange}
            addController={{
              isOpen: addListDialog,
              open: handleOpenDialog,
              handleSubmit: (name) => {
                if (name) {
                  plannedContentAddMutation.mutate({
                    body: {
                      name: name,
                    },
                    pathParams: {
                      project: appState.currentProject!.id,
                    },
                  });
                }
              },
            }}
            editController={{
              open: (list) => setEditedList(list),
              list: editedList,
              handleSubmit: (name) => {
                if (name) {
                  plannedContentEditMutation.mutate({
                    body: {
                      name,
                    },
                    pathParams: {
                      project: appState.currentProject!.id,
                      plannedContentList: parseInt(editedList!.id),
                    },
                  });
                }
              },
              isLoading: plannedContentEditMutation.isPending,
            }}
            deleteController={{
              list: deleteList,
              handleSubmit: () => {
                plannedContentDeleteMutation.mutate({
                  pathParams: {
                    plannedContentList: parseInt(deleteList!.id),
                    project: appState.currentProject!.id,
                  },
                });
              },
              isLoading: plannedContentDeleteMutation.isPending,
              open: (list) => setDeleteList(list),
            }}
            onCloseDialog={handleClose}
          />
          <Table.Root
            selection={selection}
            onSelectionChange={setSelection}
            isLoading={plannedContentItems.isPending}
            skeletonLoaders={10}
            sorting={tableState}
            onSortChange={setSorting}
            meta={plannedContentItems.data?.meta}
            items={plannedContentItems.data?.data?.map((ck) => {
              const renderedValues = {
                id: ck.id,
                keyword: ck.keyword?.name,
                volume:
                  ck.keyword?.search_volume === 0
                    ? '< 10'
                    : formatThousandSeperator(ck.keyword?.search_volume || 0),
                difficulty: ck.keyword?.difficulty,
                content: {
                  id: ck.id,
                  count: ck.documents_count,
                  isOpen: (ck as any).isOpen,
                },
                tags: ck.tags,
                buttons: (
                  <ContentKeywordButtons
                    onDeleteKeywords={handleDeleteKeywords}
                    onAdd={() => {
                      setItemsToBeAddedToList([
                        {
                          id: parseInt(ck.id),
                          keyword: ck.keyword?.name ?? '',
                        },
                      ]);
                    }}
                    onDelete={() => {
                      setItemsToBeRemovedFromList([
                        {
                          id: parseInt(ck.id),
                          keyword: ck.keyword?.name ?? '',
                        },
                      ]);
                    }}
                    key={ck.keyword?.id}
                    item={{
                      isOpen: false,
                      keyword: ck.keyword!.name,
                      id: ck.id.toString(),
                    }}
                    selectedList={selectedList}
                  />
                ),
              };
              return renderedValues;
            })}
            columns={[
              {
                property: 'keyword',
                sortableHeader: 'keyword',
                heading: 'Target keyword',
              },
              {
                property: 'volume',
                sortableHeader: 'search_volume',
                heading: 'Search volume',
                rightAlign: true,
                render: (item) => <>{item.volume}</>,
              },
              {
                property: 'difficulty',
                sortableHeader: true,
                heading: 'Rank difficulty',
                rightAlign: true,
                render: (item) => (
                  <DifficultyLabel difficulty={item.difficulty} />
                ),
              },
              {
                property: 'content',
                rightAlign: true,
                render: (item) => (
                  <div className="flex justify-center">
                    {item.content.count && item.content.count > 0 ? (
                      <ContentTitle
                        isOpen={hoveredKeywordContentId === parseInt(item.id)}
                        projectId={appState.currentProject!.id}
                        keyword={item.keyword}
                        onMouseEnter={() => {
                          setHoveredKeywordContentId(parseInt(item.id));
                        }}
                        onMouseLeave={() => {
                          setHoveredKeywordContentId(undefined);
                        }}
                      />
                    ) : null}
                  </div>
                ),
              },
              {
                heading: <span className="ml-8 w-[15vw]">TAGS</span>,
                render: (item) => (
                  <div className="ml-8 w-[15vw] min-w-[8rem]">
                    <TagEditor
                      key={item.id}
                      onTagUpdated={(tags) => handleUpdateTags(item.id, tags)}
                      organisationId={appState.organisation!.id}
                      tags={item.tags ?? []}
                    />
                  </div>
                ),
              },
              {
                property: 'buttons',
                heading: <h4 className="invisible mt-4 uppercase" />,
              },
            ]}
          >
            <Table.Header
              csvExportOptions={{
                isLoading: isExportingPlannedContentToCSV,
                onClick: () => {
                  exportPlannedContentToCSV({
                    body: {
                      filters: {
                        ...tableState.filters,
                        ids: plannedContentItems.data?.data.map((item) =>
                          parseInt(item.id),
                        ),
                      },
                    },
                    pathParams: { project: appState.currentProject!.id },
                  }).then((response) => {
                    downloadCSV(
                      response as unknown as Blob,
                      `planned_content_${
                        selectedTabIndex === 0
                          ? 'All'
                          : plannedContentListQuery.data?.data[
                              selectedTabIndex - 1
                            ].name
                      }.csv`,
                    );
                  });
                },
              }}
              search={tableState.filters?.search}
              onSearchChange={(value) => setFilters({ search: value })}
            >
              {selection.length > 0 ? (
                <SelectionActions
                  selection={selection}
                  actions={[
                    {
                      size: 'sm',
                      dense: true,
                      prependIcon: Trash2,
                      text: 'Delete',
                      variant: 'ghost',
                      onClick: () => {
                        setKeywordsToBeDeleted(
                          selection.map((value) => ({
                            keyword: value.keyword,
                            id: value.id,
                          })),
                        );
                      },
                    },
                    {
                      size: 'sm',
                      dense: true,
                      prependIcon: Copy,
                      text: 'Copy',
                      variant: 'ghost',
                      onClick: handleCopyToClipboard,
                    },
                    {
                      prependIcon: ListPlus,
                      size: 'sm',
                      dense: true,
                      text: 'Add to list',
                      variant: 'ghost',
                      onClick: () => {
                        setItemsToBeAddedToList(
                          selection.map((value) => ({
                            id: value.id,
                            keyword: value.keyword,
                          })),
                        );
                      },
                    },
                    ...(selectedList
                      ? [
                          {
                            prependIcon: ListMinus,
                            size: 'sm' as 'sm',
                            dense: true,
                            text: 'Remove from list',
                            variant: 'ghost' as 'ghost',
                            onClick: () => {
                              setItemsToBeRemovedFromList(
                                selection.map((value) => ({
                                  id: value.id,
                                  keyword: value.keyword,
                                })),
                              );
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              ) : (
                <div className="flex gap-2">
                  <Table.ResultsTotal
                    total={plannedContentItems.data?.meta.total}
                    title="keywords"
                    isLoading={plannedContentItems.isPending}
                  />
                  {/* Difficulty */}
                  <Table.FilterPopover
                    name="Difficulty"
                    onRemoveFilter={() =>
                      setFilters({
                        difficulty_max: undefined,
                        difficulty_min: undefined,
                      })
                    }
                    filterName={
                      tableState.filters?.difficulty_max
                        ? `Difficulty: ${tableState.filters.difficulty_min} - ${tableState.filters.difficulty_max}`
                        : undefined
                    }
                  >
                    <RangeSelector
                      onAccept={(values) =>
                        setFilters({
                          difficulty_min: values.min,
                          difficulty_max: values.max,
                        })
                      }
                      values={{
                        max: tableState.filters?.difficulty_max,
                        min: tableState.filters?.difficulty_min,
                      }}
                    />
                  </Table.FilterPopover>
                  {/* Search volume */}
                  <Table.FilterPopover
                    name="Search volume"
                    onRemoveFilter={() =>
                      setFilters({
                        search_volume_min: undefined,
                        search_volume_max: undefined,
                      })
                    }
                    filterName={
                      tableState.filters?.search_volume_min
                        ? `Search volume: ${tableState.filters.search_volume_min} - ${tableState.filters.search_volume_max}`
                        : undefined
                    }
                  >
                    <RangeSelector
                      onAccept={(values) =>
                        setFilters({
                          search_volume_min: values.min,
                          search_volume_max: values.max,
                        })
                      }
                      values={{
                        max: tableState.filters?.search_volume_max,
                        min: tableState.filters?.search_volume_min,
                      }}
                    />
                  </Table.FilterPopover>
                </div>
              )}
            </Table.Header>
            <Table.NoContent>
              <div className="flex flex-col items-center gap-4">
                <LineChart size={128} className="text-primary-200" />
                <p className="text-lg font-bold text-primary">No results</p>
                <Button
                  onClick={() => setShowAddKeywordsDialog(true)}
                  text="Add keywords"
                  prependIcon={Plus}
                  color="secondary"
                  variant="outline"
                />
              </div>
            </Table.NoContent>
            <Table.Footer>
              <Pagination
                currentPage={tableState.page}
                setCurrentPage={setPage}
                lastPage={plannedContentItems.data?.meta.last_page}
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
}
