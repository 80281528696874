import { useState } from 'react';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { KeywordResearchHeader } from './components/KeywordKesearchHeader';
import { useTable } from '@/Components/Table/hooks';
import { Stars } from 'lucide-react';
import { ItemSelector } from '@/Components/Table/components/ItemSelector';
import { CreateKeywordButton } from './components/CreateKeywordButton';
import { SearchIntent } from '@/api/openapiSchemas';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { formatThousandSeperator } from '@/utils';
import { StringSelector } from '@/Components/Table/components/StringSelector';
import { Button } from '@/Components/v2/Button';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { CopySelectedAction } from './components/CopySelectedAction';
import { useKeywordExport } from './hooks';
import { useProjectKeywordResearchAiExplorer } from '@/api/openapiComponents';
import { ErrorAlert } from '@/Components/v2/Alert';
import { ErrorHelper } from '@/Services/ErrorHandling';
import PageContainer from '@/Components/PageContainer';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Input } from '@/Components/v2/Input/Input';
import { KeywordResearchActions } from './components/KeywordResearchActions';

export default function AIKeywords() {
  const placeholder = useGetRandomPlaceHolder();
  const appState = useAppStore();

  const [showTable, setShowTable] = useState(false);

  const {
    tableState,
    setFilters,
    setSorting,
    selection,
    setSelection,
    resetSelection,
  } = useTable<
    'id' | 'name' | 'search_volume' | 'difficulty' | undefined,
    {
      search: string;
      words_per_keyword: number;
      search_intent?: SearchIntent;
      exclude_phrase: string;
    },
    SlimKeywordResource
  >({
    sortBy: 'search_volume',
    sortDirection: 'DESC',
  });

  const keywordMutation = useProjectKeywordResearchAiExplorer();

  const onExportCSV = useKeywordExport(
    {
      sort_by: tableState.sortBy,
      sort_direction: tableState.sortDirection,
      ids:
        keywordMutation.data?.data.map((keyword) => keyword.id.toString()) ??
        [],
    },
    'ai_keywords',
  );

  const handleSubmit = () => {
    if (!tableState.filters) return;
    resetSelection();
    keywordMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        filters: tableState.filters,
      },
    });
    setShowTable(true);
  };

  const isLoading = keywordMutation.isPending;

  const errorHelper = new ErrorHelper(keywordMutation.error as any);

  appState.pageTitle('Ai keywords');
  return (
    <Menus>
      <PageContainer title="Keyword research">
        <KeywordResearchHeader type="ai-keywords">
          <form
            className="w-full max-w-xl"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <InputDecoration label="Seed keyword or description" required>
              <Input
                value={tableState.filters?.search ?? ''}
                onChange={(value) => setFilters({ search: value })}
                placeholder={placeholder.keyword}
                hint="Provide a keyword and get a list of results where it's included"
              />
            </InputDecoration>
          </form>

          {/* Filters */}
          <div className="mb-4 flex select-none gap-4 text-base">
            {/* Search intent */}
            <Table.FilterPopover
              name="Search intent"
              filterName={
                tableState.filters?.search_intent
                  ? `Search intent: ${tableState.filters.search_intent}`
                  : undefined
              }
              onRemoveFilter={() =>
                setFilters({
                  search_intent: undefined,
                })
              }
            >
              <ItemSelector
                items={[
                  {
                    name: 'Commercial Investigation (Investigational)',
                    value: 'Commercial Investigation (Investigational)',
                  },
                  {
                    name: 'Informational',
                    value: 'Informational',
                  },
                  {
                    name: 'Local',
                    value: 'Local',
                  },
                  {
                    name: 'Navigational',
                    value: 'Navigational',
                  },
                  {
                    name: 'Niche (Specialized)',
                    value: 'Niche (Specialized)',
                  },
                  {
                    name: 'Transactional',
                    value: 'Transactional',
                  },
                ]}
                value={tableState.filters?.search_intent}
                onAccept={(value) =>
                  setFilters({ search_intent: value.value as SearchIntent })
                }
              />
            </Table.FilterPopover>
            {/* Words per keyword */}
            <Table.FilterPopover
              name="Words per keyword"
              filterName={
                tableState.filters?.words_per_keyword
                  ? `Words per keyword: ${tableState.filters.words_per_keyword}`
                  : undefined
              }
              onRemoveFilter={() =>
                setFilters({
                  words_per_keyword: undefined,
                })
              }
            >
              <ItemSelector
                items={[
                  {
                    name: '1',
                    value: 1,
                  },
                  {
                    name: '2',
                    value: 2,
                  },
                  {
                    name: '3',
                    value: 3,
                  },
                ]}
                onAccept={(value) =>
                  setFilters({ words_per_keyword: value.value })
                }
                value={tableState.filters?.words_per_keyword}
              />
            </Table.FilterPopover>

            {/* Exclude phrase */}
            <Table.FilterPopover
              name="Exclude phrase"
              filterName={
                tableState.filters?.exclude_phrase
                  ? `Exclude phrase: ${tableState.filters.exclude_phrase}`
                  : undefined
              }
              onRemoveFilter={() =>
                setFilters({
                  exclude_phrase: undefined,
                })
              }
            >
              <StringSelector
                name="Exclude phrase"
                value={tableState.filters?.exclude_phrase}
                onAccept={(value) => setFilters({ exclude_phrase: value })}
              />
            </Table.FilterPopover>
          </div>

          {/* Submit */}
          <Button
            text="Find keywords"
            prependIcon={Stars}
            isLoading={isLoading}
            color="secondary"
            onClick={handleSubmit}
          />
        </KeywordResearchHeader>

        {errorHelper.isError() && (
          <div className="mx-auto mt-2 w-full max-w-xl">
            <ErrorAlert title={errorHelper.message()} />
          </div>
        )}

        {/* Table */}
        {showTable && (
          <div className="mt-12 px-6">
            <Table.Root
              selection={selection}
              onSelectionChange={setSelection}
              onSortChange={setSorting}
              sorting={tableState}
              isLoading={isLoading}
              skeletonLoaders={7}
              items={keywordMutation.data?.data}
              columns={[
                {
                  property: 'name',
                  sortableHeader: true,
                  heading: 'TARGET KEYWORD',
                },
                {
                  property: 'search_volume',
                  sortableHeader: true,
                  rightAlign: true,
                  heading: 'SEARCH VOLUME',
                  render: (item) => (
                    <>
                      {item.search_volume === 0
                        ? '< 10'
                        : formatThousandSeperator(item.search_volume)}
                    </>
                  ),
                },
                {
                  property: 'difficulty',
                  sortableHeader: true,
                  rightAlign: true,
                  heading: 'RANK DIFFICULTY',
                  render: (item) => (
                    <DifficultyLabel difficulty={item.difficulty} />
                  ),
                },
                {
                  render: (item) => (
                    <div className="flex justify-end">
                      <div
                        key={item.id}
                        className="flex w-36 flex-row items-center justify-end gap-2"
                      >
                        <div>
                          <CreateKeywordButton
                            current_project={appState.currentProject!}
                            keyword={item}
                          />
                        </div>
                        <KeywordResearchActions keyword={item.name} />
                      </div>
                    </div>
                  ),
                },
              ]}
            >
              <Table.Header csvExportOptions={onExportCSV}>
                <CopySelectedAction selection={selection} />
              </Table.Header>
            </Table.Root>
          </div>
        )}
      </PageContainer>
    </Menus>
  );
}
