import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { DiffRow } from './DiffRow';
import { EditableContainer } from './EditableContainer';
import { MarkdownDiff } from './MarkdownDiff';
import { PlaintextDiff } from './PlaintextDiff';
import Toggle from '@/Components/Toggle';

type Props = {
  current: {
    heading: string;
    metaTitle: string;
    metaDescription: string;
    body: string;
  };
  actual: {
    heading: string;
    metaTitle: string;
    metaDescription: string;
    body: string;
    setTitle: (title: string) => void;
    setMetaDescription: (metaDescription: string) => void;
    setBody: (body: string) => void;
    setHeading: (heading: string) => void;
  };
  newVersion: {
    heading: string;
    metaTitle: string;
    metaDescription: string;
    body: string;
    setTitle: (title: string) => void;
    setMetaDescription: (metaDescription: string) => void;
    setBody: (body: string) => void;
    setHeading: (heading: string) => void;
  };
  isLoading?: boolean;
};

export const DiffEditor = ({
  current,
  actual,
  newVersion,
  isLoading,
}: Props) => {
  const [isEditingBody, setIsEditingBody] = useState(false);

  const [showChanges, setShowChanges] = useState(true);

  return (
    <div className="flex flex-col">
      <div className="flex justify-end gap-2">
        <div className="flex items-center gap-2">
          <Toggle value={showChanges} onChange={setShowChanges} />
          <span>Show changes</span>
        </div>
      </div>
      <DiffRow hideBefore={isEditingBody} before={'Before'} after={'After'} />
      <DiffRow
        hideBefore={isEditingBody}
        before={<div className="text-lg font-bold">{current.heading}</div>}
        after={
          <div className="text-2xl font-bold">
            <PlaintextDiff
              newText={newVersion.heading}
              oldText={showChanges ? actual.heading : current.heading}
              onChange={({ actualValue, newValue }) => {
                if (actualValue) actual.setHeading(actualValue);
                if (newValue) newVersion.setHeading(newValue);
              }}
            />
          </div>
        }
        header={'Heading'}
      />
      <DiffRow
        hideBefore={isEditingBody}
        before={<div className="text-lg font-bold">{current.metaTitle}</div>}
        after={
          <PlaintextDiff
            newText={newVersion.metaTitle}
            oldText={showChanges ? actual.metaTitle : current.metaTitle}
            onChange={({ actualValue, newValue }) => {
              if (actualValue) actual.setTitle(actualValue);
              if (newValue) newVersion.setTitle(newValue);
            }}
          />
        }
        header={'Metatitle'}
      />
      <DiffRow
        hideBefore={isEditingBody}
        before={
          <div className="whitespace-pre-wrap">{current.metaDescription}</div>
        }
        after={
          <div className="whitespace-pre-wrap">
            <PlaintextDiff
              newText={newVersion.metaDescription}
              oldText={
                showChanges ? actual.metaDescription : current.metaDescription
              }
              onChange={({ actualValue, newValue }) => {
                if (actualValue) actual.setMetaDescription(actualValue);
                if (newValue) newVersion.setMetaDescription(newValue);
              }}
            />
          </div>
        }
        header={'Meta description'}
      />
      <DiffRow
        header="Body"
        hideBefore={isEditingBody}
        before={
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="prose text-base leading-tight"
          >
            {current.body}
          </ReactMarkdown>
        }
        after={
          <EditableContainer
            disabled
            onStartEditing={() => setIsEditingBody(true)}
            onFinishedEditing={() => setIsEditingBody(false)}
            preview={
              <MarkdownDiff
                oldMarkdown={showChanges ? actual.body : current.body}
                newMarkdown={newVersion.body}
                onChange={({ actualValue, newValue }) => {
                  if (actualValue) actual.setBody(actualValue);
                  if (newValue) newVersion.setBody(newValue);
                }}
              />
            }
            editor={<div />}
          />
        }
      />
    </div>
  );
};
