import { DocumentResource } from '@/api/openapiSchemas';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PlateEditor as PlateEditorRef } from '@udecode/plate-common';
import { useAppStore } from '../AppLoader/stores';
import { useShallow } from 'zustand/react/shallow';
import PlateEditorErrorBoundaryHOC from './components/PlateEditorErrorBoundary';
import { ActiveDraggableState, isEditorUniqueID, NodeTypesEnum } from '@/types';
import { PlateEditorV2 } from './components/PlateEditor/PlateEditorV2';
import { Chat } from './components/Chat/Chat';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ArrowLeft, Settings } from 'lucide-react';
import { useRouter } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import { Movable, MoveEvent } from '@/Components/Movable';
import { DocumentInfoContext } from './components/PlateEditor/contexts/DocumentInfoContext';
import {
  ActiveDraggableContext,
  EditorContext,
  EditorRefContext,
} from './context';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  pointerWithin,
} from '@dnd-kit/core';
import deserialize, {
  deserializeSnippet,
} from './components/PlateEditor/lib/deserialize';
import { tracking } from '@/Services/tracking/Tracking';
import { SettingsDialog } from './components/SettingDialogs/SettingsDialog';
import { useGetSettingsState, useSLideoverState } from './hooks';
import { DocumentSlideOvers } from './components/DocumentSlideOvers';
import { ReactEditor } from 'slate-react';
import { OutlineItem } from '@/Components/DocumentForm/hooks';
import { Dialog } from '@/Components/Dialog';
import { CompetitorTable } from './components/ReportSection/components/CompetitorTable';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { NoKeywordPrompt } from './components/NoKeywordPropmpt';
import { useQuery } from '@tanstack/react-query';
import { Tools } from './components/Tools/Tools';
import { DocumentExports } from './components/DocumentExports';
import { ReportV2 } from './components/ReportSection/ReportV2';
import { Checkmark } from './components/ReportSection/components/Checkmark';
import { Workflows } from './components/Workflows/Workflows';
import { WorkflowOverlay } from './components/Workflows/components/WorkflowOverlay';
import { useCurrentEditorState } from './components/EditorStateProvider';

type Props = {
  refetchDocument: () => Promise<{ data: { data: DocumentResource } }>;
  document: DocumentResource;
};

export type WorkflowRunning = {
  isRunning: boolean;
  id: number;
};

export const DocumentLayout = ({ document, refetchDocument }: Props) => {
  const router = useRouter();

  const { setSelection, selection } = useCurrentEditorState();
  const [editor, setEditor] = useState<PlateEditorRef | null>(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showKeywordDistribution, setShowKeywordDistribution] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [RunningWorkflow, setRunningWorkflow] = useState<WorkflowRunning>({
    isRunning: false,
    id: -1,
  });

  const onWorkflowSelect = (workflowId: number) => {
    setRunningWorkflow({
      isRunning: true,
      id: workflowId,
    });
  };

  const { currentSlideOver, setCurrentSlideOver } = useSLideoverState(
    !!document.keyword,
  );

  const chatPanelRef = useRef<HTMLDivElement>(null);
  const editorFocusRef = useRef<HTMLDivElement>(null);

  const isComplete = () => {
    if (
      (document.document_report?.total_score ?? 0) >=
      (document.document_report?.total_target_score ?? 0)
    )
      return true;

    const metaTitle =
      document.document_report?.meta_title_competitiveness_score;
    if (metaTitle?.is_completed) return false;

    const metaDescription = document.document_report?.meta_description;
    if (metaDescription?.is_completed) return false;

    const keyword = document.document_report?.internal_links;
    if (keyword?.is_completed) return false;

    const internalLinks = document.document_report?.internal_links;
    if (internalLinks?.is_completed) return false;

    const missingKeywords = document.document_report?.missing_keywords;
    if (missingKeywords?.some((keyword) => !keyword.is_completed)) return false;

    const wordCount = document.document_report?.word_count;
    if (wordCount?.is_completed) return false;

    const h1 = document.document_report?.title_competitiveness_score;
    if (h1?.is_completed) return false;
    return true;
  };

  const [draggableState, setDraggableState] =
    useState<ActiveDraggableState | null>(null);

  const { settings, handleDialog, handleSettings } =
    useGetSettingsState(document);

  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const { currentProject, auth, pageTitle } = useAppStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      auth: state.auth,
      pageTitle: state.pageTitle,
    })),
  );

  useEffect(() => {
    if (chatPanelRef.current) {
      const width = localStorage.getItem('SEO_AI_chatWidth');
      if (width) {
        setChatWidth(parseInt(width));
      } else {
        setChatWidth(600);
      }
    }
  }, [chatPanelRef.current]);

  useEffect(() => {
    if (selection && tabIndex !== 1) {
      setTabIndex(1);
    }
  }, [selection]);

  const setChatWidth = (width: number) => {
    if (chatPanelRef.current === null) return;
    const limitedWidth = Math.min(
      Math.max(width, 400),
      window.innerWidth - 400,
    );
    chatPanelRef.current.style.width = `${limitedWidth}px`;
    localStorage.setItem('SEO_AI_chatWidth', limitedWidth.toString());
  };

  const handleDragChatBorder = (e: MoveEvent) => {
    if (chatPanelRef.current === null) return;

    setChatWidth(
      chatPanelRef.current.getBoundingClientRect().width - e.offset.x,
    );
  };

  const handleDragStart = (e: DragStartEvent) => {
    if (e.active.data.current?.text) {
      setDraggableState((prev) =>
        prev === null
          ? {
              activeElement: {
                id: e.active.id,
                text: e.active.data.current?.text,
              },
              hoveredElementID: undefined,
            }
          : { ...prev },
      );
    }
  };

  const handleDrag = (e: DragEndEvent) => {
    const activeId = e.active.id;
    const overId = e.over?.id;
    if (editor) {
      const overIndex = editor.children.findIndex((x) => x.id === overId);
      const activeIndex = editor.children.findIndex((x) => x.id === activeId);
      if (overId && isEditorUniqueID(activeId) && isEditorUniqueID(overId)) {
        if (activeIndex >= 0 && overIndex >= 0) {
          editor.moveNodes({
            at: [activeIndex],
            to: [overIndex],
          });
        }
      }
      if (
        overId &&
        !isEditorUniqueID(activeId) &&
        isEditorUniqueID(overId) &&
        draggableState
      ) {
        editor.insertNodes(
          deserializeSnippet(draggableState.activeElement.text),
          {
            at: [overIndex + 1],
          },
        );
      }
    }
    tracking.event('document_chat_dragged_content');
    setDraggableState(null);
  };

  const handleInsertHeading = (value: string, type?: string, focus = true) => {
    if (!editor) return;

    tracking.event('subheading_suggestions_inserted', {
      type: type,
      value: value,
    });

    const wasEmptyDocument = editor.children.length === 1;

    const fragment = editor.children[editor.selection?.anchor.path[0] ?? 0];
    if (fragment.children[0]?.text === '') {
      if (editor.children.length - 1 === editor.selection?.anchor.path[0]) {
        editor.deleteBackward('block');
      } else {
        editor.deleteForward('block');
      }
    }

    editor?.insertNode({
      type: (() => {
        if (type === 'H1') return NodeTypesEnum.H2;
        if (type === 'H6') return NodeTypesEnum.H5;
        return type
          ? (NodeTypesEnum[type as keyof typeof NodeTypesEnum] ??
              NodeTypesEnum.H2)
          : NodeTypesEnum.H2;
      })(),
      children: [{ text: value }],
    });

    if (wasEmptyDocument) {
      editor.removeNodes({ at: { path: [0], offset: 0 } });
    }

    if (focus) {
      // To supress the warning in Sentry we have to catch and ignore the error
      try {
        ReactEditor.focus(editor as ReactEditor);
      } catch (e) {}
    }
  };

  const handleInsertOutline = (outline: OutlineItem[]) => {
    for (const h2 of outline) {
      handleInsertHeading(h2.title, 'H2', false);
      for (const h3 of h2.items ?? []) {
        handleInsertHeading(h3.title, 'H3', false);
      }
    }

    ReactEditor.focus(editor as ReactEditor);
  };

  const handleDeselect = useCallback(() => {
    if (editor) {
      setSelection((prev) => {
        if (prev) {
          return { ...prev, selected: { ...prev.selected, showInChat: false } };
        }
        return prev;
      });
    }
  }, [editor]);

  const handleEditorSwitch = () => {
    localStorage.setItem('v2_editor', 'false');
    window.location.reload();
  };

  pageTitle(document.title || 'Untitled');

  return (
    <>
      {document.keyword && (
        <DocumentSlideOvers
          onInsertHeading={handleInsertHeading}
          onInsertOutline={handleInsertOutline}
          editor={editor}
          document={document}
          project={currentProject!}
          onClose={() => setCurrentSlideOver(undefined)}
          currentlyOpenSlideover={currentSlideOver}
        />
      )}
      <WorkflowOverlay
        document={document}
        workflow={RunningWorkflow}
        onClose={() => setRunningWorkflow({ isRunning: false, id: -1 })}
        onAcceptSuccess={(markdown) => {
          setRunningWorkflow({ isRunning: false, id: -1 });
          editor?.select({
            anchor: {
              offset: 0,
              path: [0, 0],
            },
            focus: {
              offset: 0,
              path: [editor.children.length - 1, 0],
            },
          });
          editor?.delete();
          editor?.insertFragment(deserialize(markdown));
        }}
      />
      <SettingsDialog
        document={document}
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
        settings={settings}
        handleSettings={handleSettings}
      />
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showKeywordDistribution}
        handleClose={() => setShowKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword?.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="keywords"
          keywords={document.document_report?.missing_keywords ?? []}
        />
      </Dialog>
      <DocumentInfoContext.Provider
        value={{
          documentId: document.id,
          projectId: currentProject!.id,
        }}
      >
        <EditorRefContext.Provider value={editorFocusRef}>
          <DndContext
            onDragOver={(e) => {
              if (draggableState) {
                setDraggableState((prev) => {
                  if (prev) {
                    return { ...prev, hoveredElementID: e.over?.id };
                  }
                  return prev;
                });
              }
            }}
            onDragStart={handleDragStart}
            onDragEnd={handleDrag}
            autoScroll={{ layoutShiftCompensation: false }}
            collisionDetection={pointerWithin}
          >
            <ActiveDraggableContext.Provider value={draggableState}>
              <EditorContext.Provider value={editor}>
                <div className="flex h-screen w-screen flex-col">
                  <div className="flex w-screen flex-grow overflow-hidden bg-primary-50">
                    <div className="flex min-w-0 flex-grow flex-col items-center bg-white shadow-lg">
                      <div className="flex w-full items-center justify-between pb-2 pl-2 pr-6 pt-3">
                        <div className="flex items-center gap-2 text-xl font-bold">
                          <IconButton
                            icon={ArrowLeft}
                            onClick={() =>
                              router.navigate({ to: '/created-content' })
                            }
                          />
                        </div>
                        <div className="flex items-center gap-4 ">
                          <Button
                            text="Change to old editor"
                            color="yellow"
                            onClick={handleEditorSwitch}
                            variant="ghost"
                            dense
                            size="sm"
                          />
                          <DocumentExports
                            document={document}
                            refetchDocument={refetchDocument}
                          />
                          <IconButton
                            icon={Settings}
                            onClick={() => setShowSettings(true)}
                          />
                        </div>
                      </div>
                      <div className="w-full flex-grow overflow-hidden">
                        <PlateEditorErrorBoundaryHOC
                          document={document.id}
                          projectId={currentProject!.id}
                          fallback={<div>Loading</div>}
                          text={document.text}
                          refetchDocument={refetchDocument}
                        >
                          <PlateEditorV2
                            user={auth!.user}
                            setCurrentSlideOver={setCurrentSlideOver}
                            currentSlideOver={currentSlideOver}
                            projectId={currentProject!.id}
                            selection={selection}
                            setSelection={setSelection}
                            setEditor={setEditor}
                            document={document}
                            editor={editor}
                          />
                        </PlateEditorErrorBoundaryHOC>
                      </div>
                    </div>
                    <div
                      ref={chatPanelRef}
                      className="relative flex flex-shrink-0 flex-col"
                    >
                      <div className="absolute bottom-0 left-0 top-0 z-50 flex">
                        <Movable onMove={handleDragChatBorder}>
                          <div className="h-full w-2 cursor-ew-resize hover:bg-secondary" />
                        </Movable>
                      </div>
                      <div className="mx-auto mt-2 w-full max-w-4xl px-6 pt-1">
                        <Tabs
                          onChange={setTabIndex}
                          tabs={[
                            {
                              text: 'SEO',
                              appendIcon: isComplete() ? undefined : (
                                <Checkmark checked={false} />
                              ),
                            },
                            {
                              text: 'CHAT',
                            },
                            {
                              text: 'WORKFLOWS',
                            },
                            {
                              text: 'TOOLS',
                            },
                          ]}
                          value={tabIndex}
                        />
                      </div>
                      <div className="mx-auto w-full flex-grow overflow-hidden">
                        {tabIndex === 0 &&
                          (document.keyword ? (
                            <ReportV2
                              currentProject={currentProject!}
                              setCurrentSlideOver={setCurrentSlideOver}
                              document={document}
                              editor={editor}
                              targetKeyword={document.keyword.name}
                              isGenerating={isGenerating}
                              isLoadingReport={Boolean(
                                document.document_report?.is_generating ?? true,
                              )}
                            />
                          ) : (
                            <div className="mx-auto flex h-full w-full max-w-2xl flex-col items-center overflow-y-auto pt-10">
                              <NoKeywordPrompt
                                document={document}
                                onSetKeyword={refetchDocument}
                              />
                            </div>
                          ))}
                        {tabIndex === 1 && (
                          <Chat
                            deselectSelection={handleDeselect}
                            document={document}
                            selection={selection}
                          />
                        )}
                        {tabIndex === 2 && (
                          <Workflows
                            document={document}
                            onSelectWorkflow={onWorkflowSelect}
                          />
                        )}
                        {tabIndex === 3 && (
                          <Tools
                            document={document}
                            onOpenCompetitorBenchmarkTable={() =>
                              setShowKeywordDistribution(true)
                            }
                            setCurrentSlideOver={setCurrentSlideOver}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </EditorContext.Provider>
            </ActiveDraggableContext.Provider>
          </DndContext>
        </EditorRefContext.Provider>
      </DocumentInfoContext.Provider>
    </>
  );
};
