import { HelpCircle } from 'lucide-react';
import { Tooltip } from '../Tooltip';

type Props = {
  label: string;
  required?: boolean;
  hint?: string;
  tooltip?: string;
  variant?: 'default' | 'overlay';
};

export const InputLabel = ({
  label,
  hint,
  required,
  tooltip,
  variant,
}: Props) => {
  const getVariantStyling = () => {
    switch (variant) {
      case 'overlay':
        return 'absolute -top-2 left-2 z-50 bg-white px-2 text-sm font-normal';
      default:
        return '';
    }
  };

  return (
    <p
      className={`mb-1 flex items-end gap-1 font-bold text-primary-800 ${getVariantStyling()}`}
    >
      {label} {required ? <span className="text-red-400">*</span> : undefined}{' '}
      {hint && <span className="text-sm text-primary-400">{hint}</span>}
      {tooltip && (
        <div className="-mb-0.5">
          <Tooltip description={tooltip}>
            <HelpCircle size={14} className="stroke-black" />
          </Tooltip>
        </div>
      )}
    </p>
  );
};
