import { useDebounce } from '@/Hooks/useDebounce';
import { FileDown } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { Search } from 'lucide-react';
import { Input } from '@/Components/v2/Input/Input';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Tooltip } from '@/Components/v2/Tooltip';
import { Dialog } from '@/Components/Dialog';
import { ConfirmDialog } from '@/Components/ConfirmDialog';

type Props = {
  children?: ReactNode;
  search?: string;
  onSearchChange?: (value?: string) => void;
  debounceDelay?: number;
  csvExportOptions?: {
    onClick: () => void;
    onCloseDialog?: () => void;
    isLoading?: boolean;
    isSuccess?: boolean;
  };
  disabled?: boolean;
};

export const Header = ({
  children,
  search,
  onSearchChange,
  debounceDelay = 500,
  csvExportOptions,
  disabled,
}: Props) => {
  const [searchValue, setSearchValue] = useState(search ?? '');

  useEffect(() => {
    setSearchValue(search ?? '');
  }, [search]);

  useDebounce(
    () => {
      if (onSearchChange)
        onSearchChange(searchValue.length > 0 ? searchValue : undefined);
    },
    [searchValue],
    debounceDelay,
  );

  return (
    <>
      <ConfirmDialog
        isOpen={!!csvExportOptions?.isSuccess}
        title="Export successful"
        description={[
          'You will receive an email with the exported content shortly',
        ]}
        noCancel
        confirmText="Ok"
        onConfirm={csvExportOptions?.onCloseDialog}
      />
      <div className="mt-2 flex min-h-[49px] items-start">
        <div className="flex flex-grow flex-wrap gap-2">{children}</div>
        <div className="flex items-center gap-2">
          {csvExportOptions && (
            <div>
              <Tooltip
                disabled={disabled}
                description="Export to CSV"
                side="top"
              >
                <IconButton
                  icon={FileDown}
                  variant="ghost"
                  size="base"
                  isLoading={csvExportOptions.isLoading}
                  disabled={csvExportOptions.isLoading || disabled}
                  onClick={csvExportOptions.onClick}
                />
              </Tooltip>
            </div>
          )}

          {onSearchChange && (
            <div className="w-72">
              <Input
                dense
                fullWidth
                clearable
                value={searchValue}
                onChange={(value) => setSearchValue(value)}
                appendIcon={(<Search size={14} />) as any}
                placeholder="Search..."
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
