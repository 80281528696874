import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { BlockSelection } from '@/types';
import { useTextAreaResize } from '../../ChatTab/hooks/useTextAreaResize';
import { PredefinedMessages } from './PredefinedMessages';
import { useEffect } from 'react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Trash2 } from 'lucide-react';
import { Tooltip } from '@/Components/v2/Tooltip';

type Props = {
  onClearChat: () => void;
  onSubmit: (predefinedMessage?: string) => void;
  value: string;
  onChange: (e: string) => void;
  isSendingMessage: boolean;
  isClearingChat: boolean;
  selection?: BlockSelection | null;
};

export const MessageInput = ({
  onChange,
  onClearChat,
  onSubmit,
  value,
  isSendingMessage,
  isClearingChat,
  selection,
}: Props) => {
  const { textAreaRef, bottomChatRef } = useTextAreaResize(value);

  useEffect(() => {
    if (textAreaRef.current && !isSendingMessage) {
      textAreaRef.current.focus();
    }
  }, [isSendingMessage, textAreaRef.current]);

  return (
    <div
      className={`flex w-full flex-col overflow-hidden rounded-3xl bg-primary-150 p-1 ${
        isSendingMessage || isClearingChat ? 'opacity-50' : ''
      }`}
    >
      {selection && selection.selected.showInChat && !isSendingMessage && (
        <div className="m-1 mb-2 rounded-2xl bg-primary-50 p-2">
          <p className="font-bold">Seleced text</p>
          <div className="max-h-32 overflow-y-auto">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              className="prose text-base"
            >
              {selection.selected.text}
            </ReactMarkdown>
          </div>
        </div>
      )}
      <div className="flex flex-col items-start">
        <textarea
          ref={textAreaRef}
          disabled={isSendingMessage || isClearingChat}
          value={isSendingMessage ? '' : value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSubmit();
            }
          }}
          rows={2}
          placeholder="Message AI assistant..."
          className="w-full resize-none border-0 bg-transparent pl-4 pr-4 pt-4 text-lg text-primary"
        />
        <div className="ml-1 mr-1 mt-1 flex w-full flex-shrink-0 items-center justify-between gap-1">
          <div className="mb-1 flex pl-1">
            <Tooltip title="Clear chat" side="top">
              <IconButton icon={Trash2} onClick={() => onClearChat()} />
            </Tooltip>
            <Tooltip title="Prompt library" side="top">
              <PredefinedMessages
                disabled={isSendingMessage}
                onSendMessage={onSubmit}
              />
            </Tooltip>
          </div>
          <div
            onClick={() => onSubmit()}
            className={`mb-2 mr-3 aspect-square flex-shrink-0 rounded-full ${value ? 'cursor-pointer bg-primary hover:opacity-60 ' : 'bg-primary-300'} p-2`}
          >
            <img src="/svg/paperplane.svg" className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
