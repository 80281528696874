import {
  CircleHelp,
  Pencil,
  Plus,
  RefreshCcw,
  Table as TableIcon,
} from 'lucide-react';
import { CurrentSlideOver } from '@/types';
import { PlateEditor } from '@udecode/plate-core';
import { serializeToMarkdown } from '../PlateEditor/lib/serialize';
import { ScoreBar } from '@/Components/ScoreBar';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { CompetitorTable } from './components/CompetitorTable';
import * as Table from '@/Components/Table';
import Lottie from 'react-lottie';
import lottieReportLoader from '../../../../../../public/lottie/seo.ai_lottie.json';
import { TextLoader } from './components/TextLoader';
import { useDocumentStore, useInternalLinkingStore } from '../../stores';
import { AddKeywordsDialog } from './components/AddKeywordsDialog';
import { KeywordLabel } from './components';
import { tracking } from '@/Services/tracking/Tracking';
import { useGetLinkSuggestions } from '../../hooks';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Tooltip } from '@/Components/v2/Tooltip';
import { RefreshReportDialog } from './components/RefreshReportDialog';
import dayjs from 'dayjs';
import { ChangeKeywordDialog } from './components/ChangeKeywordDialog';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { CustomFieldsDialog } from './components/CustomFieldsDialog/CustomFieldsDialog';
import { ReportSection } from './components/ReportSection';
import { RecommendationCard } from './components/RecommendationCard';
import { Button } from '@/Components/v2/Button';
import { ScrollContainer } from '../Chat/components/ScrollContainer';

type Props = {
  currentProject: ProjectResource;
  targetKeyword: string;
  document?: DocumentResource;
  isLoadingReport?: boolean;
  isGenerating?: boolean;
  editor: PlateEditor | null;
  setCurrentSlideOver: React.Dispatch<
    React.SetStateAction<CurrentSlideOver | undefined>
  >;
};

export function ReportV2({
  currentProject,
  isLoadingReport,
  targetKeyword,
  document,
  editor,
  setCurrentSlideOver,
}: Props) {
  const heading = useDocumentStore((state) => state.heading);
  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const [showCustomFieldsDialog, setShowCustomFieldsDialog] = useState(false);
  const [showChangeKeywordDialog, setShowChangeKeywordDialog] = useState(false);
  const [showRefreshReportDialog, setShowRefreshReportDialog] = useState(false);
  const [showAddKeywordDialog, setShowAddKeywordDialog] = useState(false);
  const [showKeywordDistribution, setShowKeywordDistribution] = useState(false);
  const [showSemanticKeywordDistribution, setShowSemanticKeywordDistribution] =
    useState(false);
  const [showSerpTable, setShowSerpTable] = useState(false);

  const getLinkSuggestions = useGetLinkSuggestions();

  const getErrorCount = (elements: Record<string, boolean>) => {
    return Object.keys(elements).filter((key) => !elements[key]).length;
  };

  const isCached = useInternalLinkingStore((state) => state.isCached);

  const handleLinkSuggestion = () => {
    if (editor) {
      if (!isCached) {
        getLinkSuggestions({
          pathParams: {
            document: document!.id,
            project: currentProject.id,
          },
          body: {
            document: serializeToMarkdown(editor.children),
          },
        });
      }
      tracking.event('link_suggestions_opened');
      setCurrentSlideOver('internal-linking');
    }
  };

  if (isLoadingReport || !document?.document_report) {
    return (
      <div className="flex h-full w-full justify-center gap-4 pt-4">
        <div className="flex w-full max-w-md flex-grow flex-col items-center justify-center pr-6">
          <Lottie
            width={'80%'}
            height={'auto'}
            isClickToPauseDisabled
            style={{ cursor: 'default' }}
            options={{
              animationData: lottieReportLoader,
              loop: true,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />
          <TextLoader
            texts={[
              'Fetching Google Results',
              'Identifying competing pages',
              'Analysing competitors content',
              'Generating semantic keywords',
              'Finding link recommendations',
              'Finalising audit report',
            ]}
            durations={[7000, 7000, 11500, 7000, 6500, 16700]}
          />
        </div>
      </div>
    );
  }

  const isReportNewerThan7Days =
    !document.document_report ||
    dayjs(document.document_report.generated_at).diff(dayjs(), 'day') > -7;

  return (
    <>
      <CustomFieldsDialog
        document={document}
        isOpen={showCustomFieldsDialog}
        onClose={() => setShowCustomFieldsDialog(false)}
      />
      <RefreshReportDialog
        isOpen={showRefreshReportDialog}
        onClose={() => setShowRefreshReportDialog(false)}
        documentId={document.id}
      />
      <ChangeKeywordDialog
        isOpen={showChangeKeywordDialog}
        onClose={() => setShowChangeKeywordDialog(false)}
        documentId={document.id}
        projectId={currentProject.id}
        keyword={document.keyword.name}
      />
      <AddKeywordsDialog
        isOpen={showAddKeywordDialog}
        onClose={() => setShowAddKeywordDialog(false)}
        documentId={document.id}
        initialKeywords={document.document_report.secondary_keywords}
        projectId={currentProject.id}
      />
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showKeywordDistribution}
        handleClose={() => setShowKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="keywords"
          keywords={document.document_report.missing_keywords}
        />
      </Dialog>
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showSemanticKeywordDistribution}
        handleClose={() => setShowSemanticKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="semantic_keywords"
          hideTarget
          keywords={document.document_report.semantic_keywords}
        />
      </Dialog>
      <ScrollContainer>
        <div className={`flex w-full max-w-2xl flex-col gap-4 px-6 pt-6`}>
          <ReportSection
            title="SEO SCORE"
            actions={
              <div className="flex flex-shrink-0 gap-2 text-lg font-bold text-primary">
                {targetKeyword}
                <IconButton
                  size="sm"
                  icon={Pencil}
                  disabled={isGenerating}
                  onClick={() => setShowChangeKeywordDialog(true)}
                />
                <Tooltip
                  title="Report is too recent. It must be 7 days old to run it again."
                  disabled={!isReportNewerThan7Days}
                  side="bottom"
                >
                  <IconButton
                    size="sm"
                    icon={RefreshCcw}
                    disabled={isReportNewerThan7Days || isGenerating}
                    onClick={() => setShowRefreshReportDialog(true)}
                  />
                </Tooltip>
              </div>
            }
          >
            <ScoreBar
              text="Reach {{targetPercentage}}% to average competitors"
              percentage={document.document_report.total_score}
              targetPercentage={document.document_report.total_target_score}
            />
          </ReportSection>

          <ReportSection title="SEO RECOMMENDATIONS">
            <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-4">
              <RecommendationCard
                title="SEO TITLE"
                errorCount={getErrorCount(
                  document.document_report.meta_title_competitiveness_score
                    .elements,
                )}
                onClick={() => setCurrentSlideOver('meta-title')}
              />
              <RecommendationCard
                title="H1"
                errorCount={getErrorCount(
                  document.document_report.title_competitiveness_score.elements,
                )}
                onClick={() => setCurrentSlideOver('title-score')}
              />
              <RecommendationCard
                title="SUBHEADINGS"
                errorCount={
                  document.document_report.subheadings?.is_completed ? 0 : 1
                }
                onClick={() => setCurrentSlideOver('outline-builder')}
              />
              <RecommendationCard
                title="CONTENT LENGTH"
                errorCount={
                  document.document_report.word_count.is_completed ? 0 : 1
                }
                onClick={() => setCurrentSlideOver('document-length')}
              />
              <RecommendationCard
                title="INTERNAL LINKS"
                errorCount={
                  document.document_report.internal_links.is_completed ? 0 : 1
                }
                onClick={handleLinkSuggestion}
              />
              <RecommendationCard
                title="META DESCRIPTION"
                errorCount={getErrorCount(
                  document.document_report.meta_description.elements,
                )}
                onClick={() => setCurrentSlideOver('meta-description')}
              />
            </div>
          </ReportSection>

          <ReportSection
            title="USE THESE KEYWORDS MORE"
            actions={
              <Tooltip title="Click the keywords to get AI suggestions">
                <CircleHelp className="h-4 w-4 text-primary" />
              </Tooltip>
            }
          >
            <div className="mb-4 flex flex-wrap gap-2">
              {document.document_report.missing_keywords
                .toSorted((a, b) =>
                  !document.ignored_keywords?.includes(a.keyword) ? -1 : 1,
                )
                .map((keyword) => (
                  <KeywordLabel
                    key={keyword.keyword}
                    disabled={!!isGenerating}
                    currentProject={currentProject}
                    document={document}
                    keywordType="missing"
                    keyword={keyword}
                    ignored={document.ignored_keywords?.includes(
                      keyword.keyword,
                    )}
                    setCurrentSlideOver={setCurrentSlideOver}
                  />
                ))}
            </div>
            <div>
              <Button
                dense="also-horizontally"
                prependIcon={TableIcon}
                text="Competitor usage"
                variant="ghost"
                onClick={() => {
                  tracking.event('competitor_keyword_overview_opened', {
                    keyword_type: 'missing',
                  });
                  setShowKeywordDistribution(true);
                }}
              />
            </div>
          </ReportSection>

          <ReportSection
            title="CONSIDER SEMANTIC RICH KEYWORDS"
            actions={
              <Tooltip title="Click the keywords to get AI suggestions">
                <CircleHelp className="h-4 w-4 text-primary" />
              </Tooltip>
            }
          >
            <div className="mb-4 flex flex-wrap gap-2">
              {document?.document_report?.semantic_keywords
                .toSorted((a, b) =>
                  !document.ignored_keywords?.includes(a.keyword) ? -1 : 1,
                )
                .map((keyword) => (
                  <KeywordLabel
                    key={keyword.keyword}
                    disabled={!!isGenerating}
                    currentProject={currentProject}
                    document={document}
                    keywordType="semantic"
                    keyword={keyword}
                    ignored={document.ignored_keywords?.includes(
                      keyword.keyword,
                    )}
                    setCurrentSlideOver={setCurrentSlideOver}
                  />
                ))}
            </div>
            <div>
              <Button
                prependIcon={TableIcon}
                dense="also-horizontally"
                text="Competitor usage"
                variant="ghost"
                onClick={() => {
                  tracking.event('competitor_keyword_overview_opened', {
                    keyword_type: 'semantic',
                  });
                  setShowSemanticKeywordDistribution(true);
                }}
              />
            </div>
          </ReportSection>

          <ReportSection
            title="CUSTOM KEYWORDS"
            actions={
              <Tooltip title="Click the keywords to get AI suggestions">
                <CircleHelp className="h-4 w-4 text-primary" />
              </Tooltip>
            }
          >
            {document.document_report.secondary_keywords.length === 0 ? (
              <div>
                <Button
                  variant="ghost"
                  dense="also-horizontally"
                  text="ADD KEYWORDS"
                  prependIcon={Plus}
                  onClick={
                    !isGenerating
                      ? () => setShowAddKeywordDialog(true)
                      : undefined
                  }
                />
              </div>
            ) : (
              <>
                <div className="mb-4 flex flex-wrap gap-2">
                  {document.document_report.secondary_keywords.map(
                    (keyword) => (
                      <KeywordLabel
                        key={keyword.keyword}
                        disabled={!!isGenerating}
                        canIgnore={false}
                        currentProject={currentProject}
                        document={document}
                        keywordType="secondary"
                        keyword={keyword}
                        setCurrentSlideOver={setCurrentSlideOver}
                      />
                    ),
                  )}
                </div>
                <div>
                  <Button
                    prependIcon={Pencil}
                    text="Manage keywords"
                    dense="also-horizontally"
                    disabled={isGenerating}
                    variant="ghost"
                    onClick={() => setShowAddKeywordDialog(true)}
                  />
                </div>
              </>
            )}
          </ReportSection>
          <div className={`mr-6 w-full p-1`}>
            <Dialog
              isOpen={showSerpTable}
              handleClose={() => {
                setShowSerpTable(false);
              }}
              size="xl"
            >
              <div className="h-[80vh]">
                <h1 className="mb-4 text-xl font-bold">
                  SERP benchmark for "{document.keyword.name ?? ''}"
                </h1>
                <Table.Root
                  items={[
                    ...document.document_report.competitors,
                    {
                      rank: 'You',
                      title: heading,
                      word_count: document.document_report.word_count.actual,
                      score: document.document_report.total_score,
                      title_competitiveness_score:
                        document.document_report.title_competitiveness_score
                          .score,
                      external_link_count:
                        document.document_report.external_link_count,
                      url: currentProject.domain,
                      tableRowClassNames: 'bg-gray-100',
                    } as any,
                  ].map((doc, i) => ({
                    rank: doc.rank ?? i + 1,
                    wordCount:
                      doc.word_count?.toLocaleString().replaceAll(',', '.') ||
                      0,
                    externalLinks: doc.external_link_count,
                    titleScore: doc.title_competitiveness_score,
                    seoScore: doc.score || 0,
                    title: doc.title,
                    url: doc.url,
                    tableRowClassNames: doc.tableRowClassNames,
                  }))}
                  columns={[
                    {
                      property: 'rank',
                      heading: 'Rank',
                      render: (item) => (
                        <p className="text-center">{item.rank}</p>
                      ),
                    },
                    {
                      property: 'title',
                      heading: ' ',
                      render: (item) => (
                        <div className="ml-6 flex flex-col items-start overflow-ellipsis pr-6">
                          <a
                            target="_blank"
                            href={item.url}
                            className="text-indigo-500"
                            rel="noreferrer"
                          >
                            {item.title}
                          </a>
                          <div className="relative w-full">
                            &nbsp;
                            <a
                              target="_blank"
                              href={item.url}
                              className="absolute inset-0 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap text-zinc-500"
                              rel="noreferrer"
                            >
                              {item.url}
                            </a>
                          </div>
                        </div>
                      ),
                    },
                    {
                      property: 'wordCount',
                      heading: 'Word count',
                    },
                    {
                      property: 'externalLinks',
                      heading: 'External links',
                    },
                    {
                      property: 'titleScore',
                      heading: 'Title score',
                    },
                    {
                      property: 'seoScore',
                      heading: 'SEO score',
                    },
                  ]}
                />
              </div>
            </Dialog>
          </div>
        </div>
      </ScrollContainer>
    </>
  );
}
