import { createRoute, redirect, useSearch } from '@tanstack/react-router';
import { rootRoute } from './Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  hasUnpaidSubscription,
  isAuthenticated,
} from './Router/guards';
import CreateProject from './CreateProject';
import Checkout from './Checkout';
import SubscriptionUnpaid from './SubscriptionUnpaid';
import Project from './Project';
import { Templates } from './Templates';
import { Template } from './Templates/Template';
import { useViewDocumentTemplate } from '@/api/openapiComponents';
import { validateTanStackSearch } from '@/utils';
import { AllPages } from './AllPages';

type AllPagesSortBy =
  | 'id'
  | 'title'
  | 'url'
  | 'clicks'
  | 'top_keyword'
  | 'content_length';
const checkoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (search: Record<string, string>) => ({
    product_id: Number(search.product_id),
  }),
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated]);
  },
  path: '/checkout',
  component: () => {
    const params = checkoutRoute.useSearch();
    return <Checkout productId={params.product_id} />;
  },
});

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: (search: Record<string, unknown>) =>
      validateTanStackSearch<AllPagesSortBy, any>(search, {
        clicks_min: search.clicks_min as number,
        clicks_max: search.clicks_max as number,
        content_length_min: search.content_length_min as number,
        content_length_max: search.content_length_max as number,
        position_min: search.position_min as number,
        position_max: search.position_max as number,
      }),
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/all-pages',
    component: () => <AllPages />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: (
      search: Record<string, string>,
    ): { templateId?: number } => ({
      templateId: search.templateId ? Number(search.templateId) : undefined,
    }),
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },

    path: '/templates/template',
    component: () => {
      const params = useSearch({ from: '/templates/template' });
      const variables = {
        pathParams: { documentTemplate: params.templateId! },
      };
      const templateQuery = useViewDocumentTemplate(variables, {
        enabled: params.templateId !== undefined,
      });

      return (
        <Template
          key={templateQuery.data?.data.id}
          template={templateQuery.data?.data}
          isLoading={templateQuery.isLoading}
        />
      );
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/create-project',
    component: () => <CreateProject />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasUnpaidSubscription]);
    },
    path: '/subscription-unpaid',
    component: () => <SubscriptionUnpaid />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [
        () => isAuthenticated(context, '/login', undefined),
        hasActiveSubscription,
        hasProject,
        () => redirect({ to: '/page-analytics', throw: true }),
      ]);
    },
    path: '/',
    component: () => <Project />,
  }),
  checkoutRoute,

  //redirects
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: () => {
      throw redirect({ to: '/' });
    },
    path: '/project/$projectId',
    component: () => <Project />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: () => {
      throw redirect({ to: '/' });
    },
    path: '/current-project',
    component: () => <Project />,
  }),
];
