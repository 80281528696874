import { CurrentSlideOver } from '@/types';
import { Link, MessageCircleQuestion, SearchX, Table2 } from 'lucide-react';
import { ReportSection } from '../ReportSection/components/ReportSection';
import { ToolCard } from './components/ToolCard';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ScrollContainer } from '../Chat/components/ScrollContainer';
import { DocumentResource } from '@/api/openapiSchemas';

type Props = {
  document: DocumentResource;
  onOpenCompetitorBenchmarkTable: () => void;
  setCurrentSlideOver: (value?: CurrentSlideOver) => void;
};

export const Tools = ({
  document,
  setCurrentSlideOver,
  onOpenCompetitorBenchmarkTable,
}: Props) => {
  const appState = useAppStore();

  return (
    <ScrollContainer>
      <div className="w-full max-w-2xl px-6 py-6">
        <ReportSection title="SEO TOOLS">
          <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] justify-items-center gap-4">
            <ToolCard
              title="Keyword Gaps"
              icon={SearchX}
              description="Uncover keyword gaps in your content based on Search Console data."
              onClick={() => setCurrentSlideOver('keyword-gap')}
              disabled={
                !appState.subscription?.permissions.keyword_gaps ||
                !document.keyword
              }
              tooltip={
                appState.subscription?.permissions.keyword_gaps
                  ? document.keyword
                    ? undefined
                    : {
                        side: 'bottom',
                        title:
                          'Add a keyword to your document to use this tool.',
                      }
                  : {
                      side: 'bottom',
                      title:
                        'Upgrade to a higher plan to get access to this feature.',
                    }
              }
            />
            <ToolCard
              title="Competitor Benchmark"
              icon={Table2}
              disabled={!document.keyword}
              description="Compare your performance against your competitors."
              tooltip={
                document.keyword
                  ? undefined
                  : {
                      title: 'Add a keyword to your document to use this tool.',
                      side: 'bottom',
                    }
              }
              onClick={onOpenCompetitorBenchmarkTable}
            />
            <ToolCard
              title="Questions"
              icon={MessageCircleQuestion}
              disabled={!document.keyword}
              tooltip={
                document.keyword
                  ? undefined
                  : {
                      title: 'Add a keyword to your document to use this tool.',
                      side: 'bottom',
                    }
              }
              description="Find questions that your audience is asking."
              onClick={() => setCurrentSlideOver('questions')}
            />
            <ToolCard
              title="Internal links"
              icon={Link}
              disabled={!document.keyword}
              tooltip={
                document.keyword
                  ? undefined
                  : {
                      title: 'Add a keyword to your document to use this tool.',
                      side: 'bottom',
                    }
              }
              description="Add internal links to your content"
              onClick={() => setCurrentSlideOver('internal-linking')}
            />
          </div>
        </ReportSection>
      </div>
    </ScrollContainer>
  );
};
