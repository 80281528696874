import * as Table from '@/Components/Table';
import { useListPageGroups } from '@/api/openapiComponents';
import { GraphState, useAnalyticsGroups, useGrowthSorting } from '../hooks';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { CellWithBar } from './CellWithBar';
import { GrowthValueHeader } from './GrowthValueHeader';
import { ValueWithGrowth } from './ValueWIthGrowth';
import ToggleButton from '@/Components/ToggleButton';
import { Button } from '@/Components/v2/Button';
import { Group, SquarePen } from 'lucide-react';
import { UseNavigateResult } from '@tanstack/react-router';
import { calculateGrowth, sortByGrowth } from '../util';
import { AnalyticsUrlActions } from './AnalyticsUrlActions';

type Props = {
  state: GraphState;
  navigate: UseNavigateResult<string>;
};

export const AnalyticsGroupsTable = ({ state, navigate }: Props) => {
  const appState = useAppStore();

  const pageGroupsQuery = useListPageGroups({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const groupDataQuery = useAnalyticsGroups(
    appState.currentProject!.id,
    state,
    pageGroupsQuery.data?.data ?? [],
    pageGroupsQuery.isSuccess,
  );

  const [groupGrowthSorting, setGroupGrowthSorting] =
    useGrowthSorting('dashboardgroup');

  const data = groupDataQuery.data?.toSorted(
    (a, b) => b.current.clicks - a.current.clicks,
  );

  const handleOpenPage = (slug: string) => {
    navigate({
      to: `/page-analytics/groups/${slug}`,
    });
  };

  const topValue = data?.[0]?.current.clicks ?? 0;

  return (
    <Table.Root
      isLoading={groupDataQuery.isLoading}
      skeletonLoaders={5}
      items={
        groupGrowthSorting === 'popular'
          ? data
          : sortByGrowth(
              data ?? [],
              groupGrowthSorting === 'rising' ? 'DESC' : 'ASC',
              'clicks',
            )
      }
      columns={[
        {
          heading: 'Group',
          expanded: true,
          render: (item) => (
            <CellWithBar
              name={item.name}
              value={item.current.clicks}
              topValue={topValue}
              onClick={() => handleOpenPage(item.slug)}
              actions={
                <AnalyticsUrlActions onOpen={() => handleOpenPage(item.slug)} />
              }
            />
          ),
        },
        {
          heading: <GrowthValueHeader heading="Clicks" />,
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              responsive={false}
              value={item.current.clicks}
              growth={calculateGrowth(item, 'clicks')}
            />
          ),
        },
        {
          heading: <GrowthValueHeader heading="Impressions" />,
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              value={item.current.impressions}
              growth={calculateGrowth(item, 'impressions')}
            />
          ),
        },
        {
          heading: <GrowthValueHeader heading="CTR" />,
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              value={(item.current.ctr * 100).toFixed(1)}
              growth={calculateGrowth(item, 'ctr')}
            />
          ),
        },
        {
          heading: <GrowthValueHeader heading="Position" />,
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              value={item.current.position.toFixed(1)}
              growth={calculateGrowth(item, 'position')}
            />
          ),
        },
      ]}
    >
      <Table.Header>
        <div className="mt-8 flex w-full justify-between">
          <div className="flex gap-2">
            <h1 className="mb-1 text-xl font-semibold">Page groups</h1>
            <ToggleButton
              value={groupGrowthSorting}
              onChange={setGroupGrowthSorting}
              options={[
                {
                  displayName: 'Popular',
                  value: 'popular',
                },
                {
                  displayName: 'Rising',
                  value: 'rising',
                },
                {
                  displayName: 'Falling',
                  value: 'falling',
                },
              ]}
            />
          </div>
          <Button
            prependIcon={SquarePen}
            onClick={() => navigate({ to: '/page-analytics/groups' })}
            text="manage groups"
            variant="ghost"
          />
        </div>
      </Table.Header>

      <Table.NoContent>
        <div className="flex flex-col items-center gap-4">
          <Group size={128} className="text-primary-200" />
          <p className="text-lg font-bold text-primary">No page groups</p>
        </div>
      </Table.NoContent>
    </Table.Root>
  );
};
