import {
  AnalyticsGranularity,
  AnalyticsGroupResource,
  AnalyticsRange,
  AnalyticsRawResultsResource,
  ProjectResource,
} from '@/api/openapiSchemas';
import { useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import { useQuery } from '@tanstack/react-query';
import { fetchAnalyticsQuery, useAnalyticsQuery } from '@/api/openapiComponents';
import { getDateParams } from './util';

export type GraphState = {
  range: AnalyticsRange;
  clicks: boolean;
  impressions: boolean;
  ctr: boolean;
  position: boolean;
  granularity: AnalyticsGranularity;
  country: {
    iso_3166_3: string;
    iso_3166_2: string;
  };
};

export const getInitialGraphState = (project: ProjectResource) => {
  const data = localStorage.getItem('analytics_graph_state');
  if (data) {
    return JSON.parse(data) as GraphState;
  } else {
    return {
      range: 'P3M' as const,
      clicks: true,
      impressions: true,
      ctr: false,
      position: false,
      granularity: 'week' as const,
      country: {
        iso_3166_2: project.language?.country?.iso_3166_2 ?? 'US',
        iso_3166_3: project.language?.country?.iso_3166_3 ?? 'USA',
      },
    };
  }
};

export const useGraphState = () => {
  const appState = useAppStore();

  const [graphState, setGraphState] = useState(
    getInitialGraphState(appState.currentProject!),
  );

  const handleSetState = (newState: GraphState) => {
    localStorage.setItem('analytics_graph_state', JSON.stringify(newState));

    setGraphState((prevState: GraphState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return [graphState, handleSetState] as const;
};

export const useAnalyticsGroups = (
  projectId: number,
  state: GraphState,
  groups: AnalyticsGroupResource[],
  isLoaded: boolean,
) => {
  return useQuery({
    queryKey: ['analytics_groups', projectId, state.country, state.range],
    enabled: isLoaded,
    queryFn: async () => {
      const returnData = [];

      const func = async (item) => {
        const current = await fetchAnalyticsQuery({
          pathParams: {
            project: projectId,
          },
          queryParams: {
            ...getDateParams(state),
            dimension_filter_groups: [
              item.dimension_filter_group,
              ...((state.country?.iso_3166_3 ?? 'xxx') === 'xxx'
                ? []
                : [
                  {
                    filters: [
                      {
                        dimension: 'country',

                        operator: 'equals',
                        expression: state.country?.iso_3166_3,
                      },
                    ],
                  },
                ],
              ),
            ],
          },
        });

        const previous = await fetchAnalyticsQuery({
          pathParams: {
            project: projectId,
          },
          queryParams: {
            ...getDateParams(state, true),
            dimension_filter_groups: [
              item.dimension_filter_group,
              ...((state.country?.iso_3166_3 ?? 'xxx') === 'xxx'
                ? []
                : [
                  {
                    filters: [
                      {
                        dimension: 'country',

                        operator: 'equals',
                        expression: state.country?.iso_3166_3,
                      },
                    ],
                  },
                ],
              ),
            ],
          },
        });

        return {
          name: item.name,
          slug: item.slug,
          current: current.data[0],
          previous: previous.data[0],
        };
      };

      await Promise.all(
        groups.map((group) =>
          func(group).then((data) => returnData.push(data)),
        ),
      );

      return returnData;
    },
  });
};

export const useGrowthSorting = (key: string) => {
  const [growthSorting, setGrowthSorting] = useState<
    'popular' | 'rising' | 'falling'
  >(localStorage.getItem(key + 'analytics_growth_sorting') ?? 'popular');

  const handleGrowthSorting = (newState: 'popular' | 'rising' | 'falling') => {
    localStorage.setItem(key + 'analytics_growth_sorting', newState);
    setGrowthSorting(newState);
  };

  return [growthSorting, handleGrowthSorting] as const;
};

export type AnalyticsTotalsData = {
  isLoading: boolean;
  data?: AnalyticsRawResultsResource;
  previousData?: AnalyticsRawResultsResource;
}

export const useAnalyticsTotals = (state: GraphState, options?:  {groupFilters?: {
  dimension: string;
  operator: string;
  expression: string;
}[], page?: string}) => {
  const appState = useAppStore();

  const currentQuery = useAnalyticsQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(state),
      dimension_filter_groups: [
        {
          filters: [
            ...(state.country?.iso_3166_3 === 'xxx'
              ? []
              : [
                  {
                    dimension: 'country',
                    operator: 'equals',
                    expression: state.country?.iso_3166_3,
                  },
                ]),
            ...(options?.page
              ? [
                  {
                    dimension: 'page',
                    operator: 'equals',
                    expression: options.page,
                  },
                ]
              : []),
            ...(options?.groupFilters ?? []),
          ],
        },
      ],
    },
  });

  const beforeQuery = useAnalyticsQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(state, true),
      dimension_filter_groups: [
        {
          filters: [
            ...(state.country?.iso_3166_3 === 'xxx'
              ? []
              : [
                  {
                    dimension: 'country',
                    operator: 'equals',
                    expression: state.country?.iso_3166_3,
                  },
                ]),
            ...(options?.page
              ? [
                  {
                    dimension: 'page',
                    operator: 'equals',
                    expression: options.page,
                  },
                ]
              : []),
            ...(options?.groupFilters ?? []),
          ],
        },
      ],
    },
  });

  return {
    isLoading: currentQuery.isLoading || beforeQuery.isLoading,
    data: currentQuery.data?.data[0],
    previousData: beforeQuery.data?.data[0],
  } as AnalyticsTotalsData;
}