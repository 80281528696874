import { CardSection } from '../components/CardSection';
import { useNavigate, useSearch } from '@tanstack/react-router';
import {
  useCreateDocumentTemplate,
  useDeleteDocumentTemplate,
  useDocumentTemplateDuplicate,
  useListDocumentTemplates,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useState } from 'react';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { MoreVertical } from 'lucide-react';

type Template = { id: number; description: string; name: string };
const initialDeleteState = { isOpen: false, template: null };
export const Templates = () => {
  const navigate = useNavigate();
  const appState = useAppStore();

  const [deleteDialog, setDeleteDialog] = useState<{
    isOpen: boolean;
    template: Template | null;
  }>(initialDeleteState);

  const search = useSearch({ from: '/settings/templates' });
  const customTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      limit: 9,
      page: search.page ?? 1,
      filters: {
        project_id: appState.currentProject!.id,
      },
    },
  });

  const preDefinedTemplatesQuery = useListDocumentTemplates({});

  const deleteTemplateMutation = useDeleteDocumentTemplate({
    onSuccess: () => {
      customTemplatesQuery.refetch();
    },
  });

  const duplicateTemplatesMutation = useDocumentTemplateDuplicate({
    onSuccess: () => {
      customTemplatesQuery.refetch();
      preDefinedTemplatesQuery.refetch();
    },
  });

  const createTemplateMutation = useCreateDocumentTemplate();

  const handleEditTemplate = (template: Template) => {
    navigate({
      to: '/templates/template',
      search: { templateId: template.id },
    });
  };

  const customMenuItems = (template: Template) => [
    {
      title: 'Edit',
      onClick: () => handleEditTemplate(template),
    },
    {
      title: 'Duplicate',
      onClick: () => {
        duplicateTemplatesMutation.mutate({
          pathParams: {
            documentTemplate: template.id,
          },
          body: {
            project_id: appState.currentProject!.id,
          },
        });
      },
    },
    {
      title: 'Delete',
      onClick: () => {
        setDeleteDialog((prev) => ({ ...prev, isOpen: true, template }));
      },
    },
  ];

  const preDefinedMenuItems = (template: Template) => [
    {
      title: 'Duplicate',
      onClick: () => {
        duplicateTemplatesMutation.mutate({
          pathParams: {
            documentTemplate: template.id,
          },
          body: {
            project_id: appState.currentProject!.id,
          },
        });
      },
    },
  ];

  const handleAddNewTemplate = () => {
    createTemplateMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) => {
          navigate({
            to: '/templates/template',
            search: { templateId: data.data.id },
          });
        },
      },
    );
  };

  appState.pageTitle('Templates');

  return (
    <>
      <ConfirmDialog
        isOpen={deleteDialog.isOpen}
        title={'Delete template'}
        onClose={() => setDeleteDialog(initialDeleteState)}
        onCancel={() => setDeleteDialog(initialDeleteState)}
        content={`Are you sure you want to delete ${deleteDialog.template?.name}?`}
        onConfirm={() => {
          deleteTemplateMutation
            .mutateAsync({
              pathParams: {
                documentTemplate: deleteDialog.template!.id,
              },
            })
            .then(() => {
              setDeleteDialog(initialDeleteState);
            });
        }}
        isLoading={deleteTemplateMutation.isPending}
      />
      <CardSection
        gap={4}
        title="Custom templates"
        buttonProps={{
          toolTip: {
            description: 'Delete a template to create a new one',
            title: 'Maximum reached',
          },
          text: 'CREATE TEMPLATE',
          isLoading: createTemplateMutation.isPending,
          disabled: (customTemplatesQuery.data?.data.length ?? 0) >= 100,
          onClick: handleAddNewTemplate,
        }}
        isLoading={customTemplatesQuery.isLoading}
        isEmptyProps={{
          buttonText: 'CREATE TEMPLATE',
          isEmpty: customTemplatesQuery.data?.data.length === 0,
          text: 'No custom templates created yet',
          onClick: handleAddNewTemplate,
        }}
        paginationProps={
          (customTemplatesQuery.data?.meta?.last_page ?? -1) > 1
            ? {
                currentPage: search.page ?? 1,
                setCurrentPage: (currentPage) =>
                  navigate({
                    search: (prev) => ({ ...prev, page: currentPage }),
                  }),
                lastPage: customTemplatesQuery.data?.meta.last_page,
              }
            : undefined
        }
      >
        {customTemplatesQuery.data?.data.map((template) => (
          <div className="flex flex-col rounded-lg border p-4 px-6 shadow-lg">
            <div className="flex w-full justify-end">
              <PopoverMenu
                items={customMenuItems({
                  id: template.id,
                  description: template.description,
                  name: template.name,
                })}
                trigger={<IconButton icon={MoreVertical} />}
              />
            </div>
            <div className="flex h-full flex-col overflow-hidden p-6 pt-0">
              <h1
                className={`mb-2 cursor-pointer text-lg font-extrabold  ${
                  template.name
                    ? 'hover:opacity-50'
                    : 'opacity-50 hover:opacity-30'
                }`}
                onClick={() => handleEditTemplate(template)}
              >
                {template.name ? template.name : 'Untitled'}
              </h1>
              <p className="pb-12">{template.description}</p>
            </div>
          </div>
        ))}
      </CardSection>
      <CardSection
        gap={4}
        title="Pre-defined templates"
        isLoading={preDefinedTemplatesQuery.isLoading}
      >
        {preDefinedTemplatesQuery.data?.data.map((template) => (
          <div className="flex flex-col rounded-lg border p-4 px-6 shadow-lg">
            <div className="flex w-full justify-end">
              <PopoverMenu
                items={preDefinedMenuItems({
                  id: template.id,
                  description: template.description,
                  name: template.name,
                })}
                trigger={<IconButton icon={MoreVertical} />}
              />
            </div>
            <div className="flex h-full flex-col overflow-hidden p-6 pt-0">
              <h1 className={`mb-2 text-lg font-extrabold`}>
                {template.name ? template.name : 'Untitled'}
              </h1>
              <p className="pb-12">{template.description}</p>
            </div>
          </div>
        ))}
      </CardSection>
    </>
  );
};
