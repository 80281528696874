import ToggleButton from '@/Components/ToggleButton';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { AiWriterFormState } from '../AiDraft';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { handleSplitOnPaste } from '@/utils';
import { CsvImport } from './CsvImport';
import { Button } from '@/Components/v2/Button';
import { Stars } from 'lucide-react';
import { TagEditor } from '@/Pages/Document/components/TagEditor/TagEditor';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { CsvPreview } from './CsvPreview';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useState } from 'react';

type Props = {
  errorHelper: ErrorHelper;
  isLoading?: boolean;
  formState: AiWriterFormState;
  setFormState: (state: AiWriterFormState) => void;
  onSubmit: () => void;
};

export const BulkForm = ({
  formState,
  setFormState,
  isLoading,
  errorHelper,
  onSubmit,
}: Props) => {
  const appState = useAppStore();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleValueChange = (values: Partial<AiWriterFormState['bulk']>) => {
    setFormState({
      ...formState,
      bulk: {
        ...formState.bulk,
        ...values,
      },
    });
  };

  const getDocumentCount = () => {
    if (formState.bulk.type === 'keyword-list') {
      if (formState.bulk.keywords.length === 0) return '';
      return (
        formState.bulk.keywords.split('\n').filter(Boolean).length +
        ' documents'
      );
    }

    return formState.bulk.csvData
      ? formState.bulk.csvData.length - 1 + ' documents'
      : '';
  };

  return (
    <>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        title="Are you sure?"
        description={[
          `Are you sure you want to generate ${getDocumentCount()}?`,
        ]}
        isLoading={isLoading}
        onConfirm={onSubmit}
      />
      <InputDecoration
        label="Keyword and context"
        required
        description="Upload a CSV file with keywords and context."
        readMoreLink="https://docs.seo.ai/context"
      >
        <ToggleButton
          disabled={isLoading}
          options={[
            { value: 'keyword-list', displayName: 'Keyword list' },
            { value: 'csv-import', displayName: 'Csv with context' },
          ]}
          onChange={(value) =>
            handleValueChange({
              type: value as 'keyword-list' | 'csv-import',
            })
          }
          value={formState.bulk.type}
        />
      </InputDecoration>
      {formState.bulk.type === 'keyword-list' && (
        <TextArea
          disabled={isLoading}
          resize
          rows={8}
          value={formState.bulk.keywords}
          onChange={(value) => {
            handleValueChange({ keywords: value });
          }}
          counterMax={100}
          counter={formState.bulk.keywords.split('\n').filter(Boolean).length}
          onPaste={async (e) => {
            const value = await handleSplitOnPaste(e, formState.bulk.keywords);
            handleValueChange({ keywords: value });
          }}
        />
      )}
      {formState.bulk.type === 'csv-import' && (
        <>
          <CsvImport
            isLoading={isLoading}
            formState={formState}
            setFormState={setFormState}
          />
          <CsvPreview formState={formState} />
        </>
      )}

      <InputDecoration label="Add tag to content">
        <div>
          <TagEditor
            onTagUpdated={(tags) => {
              handleValueChange({ tags });
            }}
            organisationId={appState.organisation!.id}
            tags={formState.bulk.tags}
          />
        </div>
      </InputDecoration>

      {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}

      <Button
        text={`Generate ${getDocumentCount()}`}
        prependIcon={Stars}
        color="secondary"
        onClick={() => setShowConfirmDialog(true)}
        isLoading={isLoading}
      />
    </>
  );
};
