import * as React from 'react';
import { useHeadingState, useMetaTitleState } from '@/Pages/Document/hooks';
import { Generating } from '@/types';
import { useEditorState } from '@udecode/plate-common';
import { ReactEditor } from 'slate-react';
import { SectionHeader } from './section-header';

type Props = {
  isLoading: Generating;
  handleTitleFocus: () => void;
};

const EditorTitleV2 = ({ isLoading, handleTitleFocus }: Props) => {
  const editor = useEditorState();
  const [heading, setHeading] = useHeadingState();
  const [metaTitle, setMetaTitle] = useMetaTitleState();
  const titleRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = '1px';
      titleRef.current.style.height =
        (titleRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  return (
    <div className="h=[25vh] p-5 pt-2">
      <div className="relative flex flex-col bg-white">
        <SectionHeader>H1</SectionHeader>
        <textarea
          disabled={!!isLoading}
          ref={titleRef}
          placeholder="Click here to get title ideas"
          className="intro-step-3 mt-2 w-full cursor-pointer resize-none overflow-y-hidden border-0 p-0 text-4xl font-black leading-[3rem]"
          value={heading}
          onFocus={handleTitleFocus}
          onChange={(e) => {
            if (heading === metaTitle) {
              setMetaTitle(e.target.value);
            }
            setHeading(e.target.value);
          }}
          onKeyDown={(e) => {
            const shiftPressed = e.shiftKey;
            const key = e.key;
            if (key === 'Enter' && !shiftPressed) {
              const initialPath = { offset: 0, path: [0, 0] };
              editor.select({
                anchor: initialPath,
                focus: initialPath,
              });
              ReactEditor.focus(editor as ReactEditor);
              e.preventDefault();
            }
          }}
        />
        <div className="absolute -right-4 top-[1.25rem]">
          <div className="relative flex justify-center"></div>
        </div>
      </div>
    </div>
  );
};

EditorTitleV2.displayName = 'EditorTitle';

export { EditorTitleV2 };
