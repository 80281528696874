import { useAppStore } from '@/Pages/AppLoader/stores';
import { CustomField } from './custom-field';
import { useDocumentStore } from '@/Pages/Document/stores';
import { useListCustomFieldDefinitions } from '@/api/openapiComponents';
import { useEffect, useState } from 'react';
import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';

export const CustomFields = () => {
  const appState = useAppStore();

  const [isLoaded, setIsLoaded] = useState(false);
  const customFieldValues = useDocumentStore(
    (state) => state.customFieldValues,
  );
  const setCustomFieldValues = useDocumentStore(
    (state) => state.setCustomFieldValues,
  );

  const customFieldsQuery = useListCustomFieldDefinitions({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  useEffect(() => {
    if (customFieldsQuery.isSuccess) {
      const data = customFieldsQuery.data?.data.reduce(
        (acc, item) => {
          acc[item.id] =
            document.custom_fields?.find((field) => field.id === item.id)
              ?.value ?? item.value;
          return acc;
        },
        {} as Record<string, string>,
      );
      setCustomFieldValues(data);
      setIsLoaded(true);
    }
  }, [customFieldsQuery.isSuccess]);

  const handleChange = (value: string, item: CustomFieldDefinitionResource) => {
    setCustomFieldValues({
      ...customFieldValues,
      [item.id]: value,
    });
  };

  if (!isLoaded) {
    return null;
  }

  return customFieldsQuery.data?.data.map((field) => (
    <CustomField
      key={field.id}
      title={field.name}
      initialValue={customFieldValues[field.id]}
      onChange={(value) => handleChange(value, field)}
    />
  ));
};
