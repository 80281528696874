import { useState } from 'react';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { KeywordResearchHeader } from './components/KeywordKesearchHeader';
import { useTable } from '@/Components/Table/hooks';
import { ScanSearch } from 'lucide-react';
import { CreateKeywordButton } from './components/CreateKeywordButton';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { formatThousandSeperator, handleSplitOnPaste } from '@/utils';
import { Button } from '@/Components/v2/Button';
import { useProjectKeywordResearchSearchVolume } from '@/api/openapiComponents';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { CopySelectedAction } from './components/CopySelectedAction';
import { useKeywordExport } from './hooks';
import { ErrorAlert } from '@/Components/v2/Alert';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import PageContainer from '@/Components/PageContainer';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { KeywordResearchActions } from './components/KeywordResearchActions';

export default function SearchVolume() {
  const placeholder = useGetRandomPlaceHolder();
  const appState = useAppStore();

  const [showTable, setShowTable] = useState(false);
  const [keywordsInput, setKeywordsInput] = useState('');

  const {
    tableState,
    setFilters,
    setSorting,
    selection,
    setSelection,
    resetSelection,
  } = useTable<
    'id' | 'name' | 'search_volume' | 'difficulty' | undefined,
    {
      keywords: string[];
      exclude_phrase: string;
    },
    SlimKeywordResource
  >({
    sortBy: 'search_volume',
    sortDirection: 'DESC',
  });

  const keywordMutation = useProjectKeywordResearchSearchVolume();

  const onExportCSV = useKeywordExport(
    {
      sort_by: tableState.sortBy,
      sort_direction: tableState.sortDirection,
      ids:
        keywordMutation.data?.data.map((keyword) => keyword.id.toString()) ??
        [],
    },
    'search_volume_keywords',
  );

  const handleSubmit = () => {
    if (!tableState.filters) return;
    resetSelection();
    setShowTable(true);
    keywordMutation.mutate({
      pathParams: { project: appState.currentProject!.id },
      body: {
        ...tableState,
        limit: 100,
        filters: {
          keywords:
            tableState?.filters?.keywords?.filter(
              (keyword) => keyword.trim() !== '',
            ) ?? [],
          exclude_phrase: tableState?.filters?.exclude_phrase,
        },
      },
    });
  };

  const isLoading = keywordMutation.isPending;

  const errorHelper = new ErrorHelper(keywordMutation.error as any);

  appState.pageTitle('Ai keywords');

  return (
    <Menus>
      <PageContainer title="Keyword research">
        <KeywordResearchHeader type="search-volume">
          <form
            className="mb-4 w-full max-w-xl"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <InputDecoration label="Keyword(s)" required>
              <TextArea
                fullWidth
                counterMax={500}
                counter={
                  keywordsInput
                    .split('\n')
                    .filter((keyword) => keyword.trim() !== '').length
                }
                value={keywordsInput}
                onChange={(value) => {
                  setFilters({ keywords: value.split('\n') });
                  setKeywordsInput(value);
                }}
                onPaste={async (e) => {
                  const value = await handleSplitOnPaste(e, keywordsInput);
                  setKeywordsInput(value);
                }}
                placeholder={placeholder.keyword}
                hint="The keywords you want search volume for. One per line."
              />
            </InputDecoration>
          </form>

          {/* Submit */}
          <Button
            text="Find search volume"
            prependIcon={ScanSearch}
            isLoading={isLoading}
            color="secondary"
            onClick={handleSubmit}
          />
        </KeywordResearchHeader>

        {errorHelper.isError() && (
          <div className="mx-auto mt-2 w-full max-w-xl">
            <ErrorAlert title={errorHelper.message()} />
          </div>
        )}
        {/* Table */}
        {showTable && (
          <div className="mt-12 px-6">
            <Table.Root
              selection={selection}
              onSelectionChange={setSelection}
              onSortChange={setSorting}
              sorting={tableState}
              isLoading={isLoading}
              skeletonLoaders={7}
              items={keywordMutation.data?.data}
              columns={[
                {
                  property: 'name',
                  sortableHeader: true,
                  heading: 'TARGET KEYWORD',
                },
                {
                  property: 'search_volume',
                  sortableHeader: true,
                  rightAlign: true,
                  heading: 'SEARCH VOLUME',
                  render: (item) => (
                    <>
                      {item.search_volume === 0
                        ? '< 10'
                        : formatThousandSeperator(item.search_volume)}
                    </>
                  ),
                },
                {
                  property: 'difficulty',
                  sortableHeader: true,
                  rightAlign: true,
                  heading: 'RANK DIFFICULTY',
                  render: (item) => (
                    <DifficultyLabel difficulty={item.difficulty} />
                  ),
                },
                {
                  render: (item) => (
                    <div className="flex justify-end">
                      <div
                        key={item.id}
                        className="flex w-36 flex-row items-center justify-end gap-2"
                      >
                        <div>
                          <CreateKeywordButton
                            current_project={appState.currentProject!}
                            keyword={item}
                          />
                        </div>
                        <KeywordResearchActions keyword={item.name} />
                      </div>
                    </div>
                  ),
                },
              ]}
            >
              <Table.Header csvExportOptions={onExportCSV}>
                <CopySelectedAction selection={selection} />
              </Table.Header>
            </Table.Root>
          </div>
        )}
      </PageContainer>
    </Menus>
  );
}
