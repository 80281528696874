import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ChartLine, ExternalLink } from 'lucide-react';

type Props = {
  url?: string;
  onOpen?: () => void;
};

export const AnalyticsUrlActions = ({ url, onOpen }: Props) => {
  return (
    <div className="flex items-center gap-2">
      {onOpen && <IconButton icon={ChartLine} onClick={onOpen} />}
      {url && (
        <IconButton
          icon={ExternalLink}
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            window.open(url, '_blank');
          }}
        />
      )}
    </div>
  );
};
