import { useRef, useState } from 'react';
import { parse } from 'csv-parse/browser/esm/sync';
import { MoveRight, UploadCloud } from 'lucide-react';
import { Select } from '@/Components/v2/Select';
import { AiWriterFormState } from '../AiDraft';
import { SimpleCheckbox } from '@/Components/SimpleCheckbox';
import { ErrorAlert } from '@/Components/v2/Alert';

type Props = {
  isLoading?: boolean;
  formState: AiWriterFormState;
  setFormState: (state: AiWriterFormState) => void;
};

export const CsvImport = ({ formState, setFormState, isLoading }: Props) => {
  const [parserError, setParserError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const columns = formState.bulk.csvData?.[0] ? formState.bulk.csvData[0] : [];

  const handleValueChange = (values: Partial<AiWriterFormState['bulk']>) => {
    setFormState({
      ...formState,
      bulk: {
        ...formState.bulk,
        ...values,
      },
    });
  };

  const handleToggleContextColumn = (index: number) => {
    const contextColumns = formState.bulk.csvContextColumnIndexes ?? [];
    if (contextColumns.includes(index)) {
      handleValueChange({
        csvContextColumnIndexes: contextColumns.filter((i) => i !== index),
      });
    } else {
      handleValueChange({
        csvContextColumnIndexes: [...contextColumns, index],
      });
    }
  };

  const handleReadFile = (file: File) => {
    setIsHovered(false);
    setParserError(false);
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const csv = parse(event.target?.result as string, {
          skip_empty_lines: true,
        });
        handleValueChange({
          csvData: csv,
          csvMainKeywordColumnIndex: undefined,
          csvUrlColumnIndex: undefined,
          csvContextColumnIndexes: [],
        });
      } catch {
        setParserError(true);
      }
    };
    reader.readAsText(file);
  };

  return (
    <>
      <div
        className={`flex ${formState.bulk.csvData ? '' : 'h-96 cursor-pointer border-dashed bg-primary-25 text-primary-400'} relative flex-col items-center justify-center gap-4 rounded-md border border-primary-300 p-4 ${isLoading ? 'pointer-events-none opacity-50' : ''}`}
        onDragOver={(e) => {
          e.preventDefault();
          setIsHovered(true);
        }}
        onDragExit={() => setIsHovered(false)}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleReadFile(e.dataTransfer.files[0]);
        }}
        onClick={() => {
          if (formState.bulk.csvData) return;
          fileInputRef.current?.click();
        }}
      >
        {isHovered && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-70">
            Drop to upload
          </div>
        )}

        {formState.bulk.csvData ? (
          <>
            <div className="flex w-full items-center gap-2">
              <div className="flex flex-shrink-0 grow justify-between pr-6">
                <p>
                  Main keyword <span className="text-red">*</span>
                </p>
                <MoveRight />
              </div>
              <div className="w-full max-w-96">
                <Select
                  fullWidth
                  value={formState.bulk.csvMainKeywordColumnIndex}
                  onChange={(value) => {
                    handleValueChange({
                      csvMainKeywordColumnIndex: value as number,
                    });
                  }}
                  options={columns.map((column, index) => ({
                    value: index,
                    title: column,
                  }))}
                  placeholder="Select main keyword column"
                />
              </div>
            </div>
            <div className="flex w-full gap-2">
              <div className="flex flex-shrink-0 grow justify-between pr-6">
                Context columns <MoveRight />
              </div>
              <div className="w-full max-w-96">
                <div className="mb-2 flex items-center gap-2 rounded bg-primary-100">
                  <SimpleCheckbox
                    onChange={(value) => {
                      if (value) {
                        handleValueChange({
                          csvContextColumnIndexes: columns.map((_, i) => i),
                        });
                      } else {
                        handleValueChange({ csvContextColumnIndexes: [] });
                      }
                    }}
                    checked={
                      formState.bulk.csvContextColumnIndexes?.length ===
                      columns.length
                    }
                  />
                  Spreadsheet columns
                </div>
                {columns.map((column, index) => (
                  <div className="mb-2 flex w-full items-center gap-2 truncate">
                    <SimpleCheckbox
                      onChange={() => handleToggleContextColumn(index)}
                      checked={
                        formState.bulk.csvContextColumnIndexes?.includes(
                          index,
                        ) ?? false
                      }
                    />{' '}
                    {column}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full items-center gap-2">
              <div className="flex flex-shrink-0 grow justify-between pr-6">
                URL <MoveRight />
              </div>
              <div className="w-full max-w-96">
                <Select
                  clearable
                  fullWidth
                  value={formState.bulk.csvUrlColumnIndex}
                  onChange={(value) => {
                    handleValueChange({ csvUrlColumnIndex: value as number });
                  }}
                  options={columns.map((column, index) => ({
                    value: index,
                    title: column,
                  }))}
                  placeholder="Select URL column"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <UploadCloud size={128} className="stroke-primary-300" />
            <p className="font-bold">
              Drag and drop or <u>choose</u> your CSV file to upload
            </p>
          </>
        )}
        <input
          ref={fileInputRef}
          type="file"
          name="name"
          accept=".csv"
          className="hidden"
          onChange={(e) => {
            if (e.target.files) {
              handleReadFile(e.target.files[0]);
            }
          }}
        />
      </div>
      {parserError && (
        <ErrorAlert
          title="Error parsing CSV file"
          body="Please upload a valid CSV file"
        />
      )}
    </>
  );
};
